export const CLEAR_ALL = "CLEAR_ALL";
export const CLEAR_LOADING = "CLEAR_LOADING";
export const CLEAR_CURRENT_DASHBOARD = "CLEAR_CURRENT_DASHBOARD";
export const DASHBOARDS_ERROR = "DASHBOARDS_ERROR";
export const GET_LOCATION_BLOCK_GROUPS = "GET_LOCATION_BLOCK_GROUPS";
export const GET_DASHBOARD_BLOCK_FEATURES = "GET_DASHBOARD_BLOCK_FEATURES";
export const GET_DASHBOARDS = "GET_DASHBOARDS";
export const SET_CURRENT_DASHBOARD = "SET_CURRENT_DASHBOARD";
export const SET_CURRENT_DASHBOARD_LOADING_STATE = "SET_CURRENT_DASHBOARD_LOADING_STATE";
export const SET_DASHBOARD_DATA = "SET_DASHBOARD_DATA";
export const SET_DASHBOARD_DATA_LOADING_STATE = "SET_DASHBOARD_LOADING_STATE";
export const SET_FORM_DATA = "SET_FORM_DATA";
export const SET_LOADING = "SET_LOADING";
export const SORT_DASHBOARDS = "SORT_DASHBOARDS";
export const SET_RESPONSE_FORM_DATA = "SET_RESPONSE_FORM_DATA";
