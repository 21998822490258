import React from "react";

function BuildingLine() {
	return (
		<g>
			<path
				d='M2.33398 21V4.75L7.33398 1L12.334 4.75V7H22.334V21H2.33398ZM4.33398 19H6.33398V17H4.33398V19ZM4.33398 15H6.33398V13H4.33398V15ZM4.33398 11H6.33398V9H4.33398V11ZM4.33398 7H6.33398V5H4.33398V7ZM8.33398 7H10.334V5H8.33398V7ZM8.33398 19H20.334V9H8.33398V19ZM14.334 13V11H18.334V13H14.334ZM14.334 17V15H18.334V17H14.334ZM10.334 13V11H12.334V13H10.334ZM10.334 17V15H12.334V17H10.334Z'
				fill='#93A3BE'
			/>
		</g>
	);
}

export default BuildingLine;
