import { requestParamsToFormData } from "../../pages/score-insights/data-transform/formData";
import {
	getScoreInsightLocationBlockGroups,
	getScoreInsightsDashboardBlockFeatures,
	getScoreInsightsDashboardData,
	getScoreInsightsDashboardList,
	getSingleScoreInsightDashboard,
	saveScoreInsightsDashboardRequestParams,
} from "../../services/dashboards/score-insights.services/dashboards-crud.services";
import {
	CLEAR_CURRENT_DASHBOARD,
	CLEAR_LOADING,
	DASHBOARDS_ERROR,
	GET_DASHBOARD_BLOCK_FEATURES,
	GET_DASHBOARDS,
	GET_LOCATION_BLOCK_GROUPS,
	SET_CURRENT_DASHBOARD,
	SET_CURRENT_DASHBOARD_LOADING_STATE,
	SET_DASHBOARD_DATA,
	SET_DASHBOARD_DATA_LOADING_STATE,
	SET_FORM_DATA,
	SET_LOADING,
	SET_RESPONSE_FORM_DATA,
	SORT_DASHBOARDS,
} from "./scoreInsightsTypes";

// May not need any more
export const getDashboards = (userId) => async (dispatch) => {
	try {
		setLoading();
		const res = await getScoreInsightsDashboardList(userId);

		const data = await res.data;

		dispatch({
			type: GET_DASHBOARDS,
			payload: data,
		});
	} catch (err) {
		dispatch({
			type: DASHBOARDS_ERROR,
			payload: err,
		});
	}
};

export const sortDashboards =
	({ orderBy, sortOrder }) =>
	(dispatch) => {
		dispatch({
			type: SORT_DASHBOARDS,
			payload: { orderBy, sortOrder },
		});
	};

export const setCurrentDashboard = (dashboardId) => async (dispatch) => {
	try {
		const res = await getSingleScoreInsightDashboard(dashboardId);

		if (res.code >= 200 && res.code < 300) {
			const data = res.data ? res.data.dashboard : null;
			if (data) {
				dispatch({
					type: SET_CURRENT_DASHBOARD,
					payload: data,
				});
			}
		} else {
			throw res;
		}
	} catch (err) {
		dispatch({
			type: DASHBOARDS_ERROR,
			payload: err,
		}).then(() => {
			dispatch({
				type: CLEAR_LOADING,
			});
		});
	}
};

export const getScoreInsightsLocationBlockGroups = (locationId) => async (dispatch, getState) => {
	try {
		const laborSupply = getState().scoreInsightsDashboard.currentDashboard?.labor_supply ?? [];
		const firstLocationId = laborSupply[0]?._id ?? null;

		if (!locationId && !firstLocationId) {
			console.warn("getScoreInsightsLocationBlockGroups(): No locationId or currentDashboard found in state.");
			return;
		}

		dispatch(setLoading());
		const res = await getScoreInsightLocationBlockGroups(locationId ? locationId : firstLocationId);

		if (res.code >= 200 && res.code < 300) {
			const blockGroups = res.data ? res.data : null;

			dispatch({
				type: GET_LOCATION_BLOCK_GROUPS,
				payload: blockGroups,
			});
		} else {
			throw res;
		}
	} catch (err) {
		console.error(err);
		dispatch({
			type: DASHBOARDS_ERROR,
			payload: { message: err.message, name: err.name },
		});
	}
};

export const getScoreInsightsBlockFeatures = (dashboardId) => async (dispatch) => {
	try {
		const res = await getScoreInsightsDashboardBlockFeatures(dashboardId);
		if (res.code >= 200 && res.code < 300) {
			dispatch({
				type: GET_DASHBOARD_BLOCK_FEATURES,
				payload: {
					dashboardId,
					features: res.data?.features ?? [],
				},
			});
		} else {
			throw res;
		}
	} catch (err) {
		console.error(err);
		dispatch({
			type: DASHBOARDS_ERROR,
			payload: { message: err.message, name: err.name },
		});
	}
};

export const fetchCurrentDashboard =
	(dashboardId, controller = null) =>
	async (dispatch) => {
		dispatch({ type: SET_CURRENT_DASHBOARD_LOADING_STATE, payload: "loading" });

		// An axios interceptor will show the toast message if there is an error
		getSingleScoreInsightDashboard(dashboardId, { signal: controller?.signal })
			.then((res) => {
				// if server-side validation failed, res.data will be an empty string
				const dashboard = typeof (res.data?.dashboard ?? null) === "object" ? res.data.dashboard : null;
				if (dashboard) {
					dispatch({ type: SET_CURRENT_DASHBOARD, payload: dashboard });
					dispatch({ type: SET_CURRENT_DASHBOARD_LOADING_STATE, payload: null });
				} else {
					dispatch({ type: SET_CURRENT_DASHBOARD, payload: null });
					dispatch({ type: SET_CURRENT_DASHBOARD_LOADING_STATE, payload: "error" });
				}
			})
			.catch((err) => {
				if (err.name === "AbortError" || err.message === "CanceledError") {
					console.info("Score Insights dashboard request aborted");
					return;
				}

				dispatch({ type: SET_CURRENT_DASHBOARD, payload: null });
				dispatch({ type: SET_CURRENT_DASHBOARD_LOADING_STATE, payload: "error" });
			});
	};

export const fetchDashboardData =
	(requestParams, controller = null) =>
	async (dispatch) => {
		dispatch({ type: SET_DASHBOARD_DATA_LOADING_STATE, payload: "loading" });

		// An axios interceptor will show the toast message if there is an error
		getScoreInsightsDashboardData(requestParams, { signal: controller?.signal })
			.then((res) => {
				// if server-side validation failed, res.data will be an empty string
				const dashboardData = typeof (res.data ?? null) === "object" ? res.data : null;
				if (dashboardData) {
					dispatch({ type: SET_DASHBOARD_DATA, payload: dashboardData });
					dispatch({ type: SET_DASHBOARD_DATA_LOADING_STATE, payload: null });
				} else {
					dispatch({ type: SET_DASHBOARD_DATA, payload: null });
					dispatch({ type: SET_DASHBOARD_DATA_LOADING_STATE, payload: "error" });
				}
			})
			.catch((err) => {
				if (err.name === "AbortError" || err.message === "CanceledError") {
					return;
				}

				dispatch({ type: SET_DASHBOARD_DATA, payload: null });
				dispatch({ type: SET_DASHBOARD_DATA_LOADING_STATE, payload: "error" });
			})
			.finally(() => {});
	};

export const saveDashboardRequestParams = (requestParams) => async (dispatch) => {
	// An axios interceptor will show the toast message if there is an error
	saveScoreInsightsDashboardRequestParams(requestParams).then((res) => {
		// if server-side validation failed, res.data will be an empty string
		const data = typeof (res.data ?? null) === "object" ? res.data : null;
		if (typeof data.requestParams === "object") {
			dispatch({ type: SET_FORM_DATA, payload: requestParamsToFormData(data.requestParams) });
			dispatch({ type: SET_RESPONSE_FORM_DATA, payload: requestParamsToFormData(data.requestParams) });
		}
	});
};

export const setLoading = (loading = true) => {
	return {
		type: SET_LOADING,
		payload: loading,
	};
};

export const clearCurrentDashboard = () => {
	return {
		type: CLEAR_CURRENT_DASHBOARD,
	};
};
