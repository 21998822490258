import "react-toastify/dist/ReactToastify.css";

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";

import { toastError, toastSelector, ToastShow } from "../../redux/toast.duck";

function ToastNotification() {
	const dispatch = useDispatch();
	const toastMessage = useSelector(toastSelector);
	const toastErrors = useSelector(toastError);

	useEffect(() => {
		if (toastMessage.message) {
			if (toastMessage.type === "error") {
				if (
					toastErrors &&
					toastErrors.data &&
					toastErrors.data.errors &&
					Object.keys(toastErrors.data.errors).length > 0
				) {
					const errors = toastErrors.data.errors;
					let firstValue;

					for (const key in errors) {
						if (Object.prototype.hasOwnProperty.call(errors, key)) {
							const value = errors[key];
							if (value !== "") {
								firstValue = value;

								break;
							}
						}
					}
					toast.error(firstValue, {
						toastId: "error1",
					});
				} else {
					toast.error(toastMessage.message, {
						toastId: "error1",
					});
				}
			} else {
				toast.success(toastMessage.message, {
					toastId: "success1",
				});
			}

			setTimeout(() => {
				dispatch(ToastShow({ message: null, type: null }));
			}, 500);
		}
	}, [toastMessage]);

	return (
		<div className=''>
			<ToastContainer position='top-right' autoClose={2500} hideProgressBar={false} newestOnTop closeOnClick />
		</div>
	);
}
export default ToastNotification;
