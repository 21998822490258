/**
 * This is a custom React hook called useUrlParams that is used to persist URL query parameters based on the current route.
 *
 * The hook takes a single argument rules, which is an object that defines the routes for which the URL parameters should be persisted. The object's keys are the routes and the values are arrays of query parameter names to be persisted.
 *
 * The hook uses the useLocation and useNavigate hooks from react-router-dom to get the current location and navigate to a new URL, respectively.
 *
 * The hook uses three useEffect hooks, which run when the URL parameters, the current route, or the rules object change.
 *
 * The first useEffect hook is responsible for setting the query parameters in localStorage for the current route. It checks if the current route has any query parameters to persist, and if so, it stores them in an object and sets them in localStorage.
 *
 * The second useEffect hook is responsible for checking if there are persisted parameters for the previous route, and if so, adds them to the URL search params for the current route. If the previous route has no persisted parameters or the current route has no rules defined, this effect does nothing.
 *
 * The third useEffect hook is responsible for clearing persisted parameters from localStorage if the current route has no rules defined.
 */

import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export function useUrlParams(rules) {
	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		if (rules[location.pathname]) {
			const searchParams = new URLSearchParams(location.search);
			const queryParams = {};

			// Iterate over all query parameters and store them in an object
			for (let [key, value] of searchParams.entries()) {
				queryParams[key] = value;
			}

			const persistedParams = {};

			// Iterate over the parameters to persist and add them to the object
			for (const key of rules[location.pathname]) {
				if (queryParams[key]) {
					persistedParams[key] = queryParams[key];
				}
			}

			if (Object.keys(persistedParams).length > 0) {
				localStorage.setItem("query_params", JSON.stringify(persistedParams));
			}
		}
	}, [location.search, location.pathname, rules]);

	useEffect(() => {
		const previousLocation = JSON.parse(localStorage.getItem("previousLocation"));

		if (rules[location.pathname] && previousLocation && rules[previousLocation.pathname]) {
			const queryParamsStr = localStorage.getItem("query_params");

			if (queryParamsStr) {
				const queryParamsObj = JSON.parse(queryParamsStr);
				const params = new URLSearchParams(location.search);

				// Iterate over all query parameters stored in local storage and add them to the URL search params
				for (const [key, value] of Object.entries(queryParamsObj)) {
					params.set(key, value);
				}

				navigate({
					pathname: location.pathname,
					search: `?${params.toString()}`,
				});
			}
		}

		localStorage.setItem("previousLocation", JSON.stringify(location));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location.pathname]);

	useEffect(() => {
		if (!rules[location.pathname]) {
			localStorage.removeItem("query_params");
		}
	}, [location.pathname, rules]);
}
