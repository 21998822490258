import "./InputSelect.style.scss";
import React, { useEffect, useState } from "react";
import { randomString } from "../../helper/string.helpers";
import InputWrapper from "../InputWrapper/InputWrapper";

/**
 * @typedef {Object} Option
 * @property {string | number} value
 * @property {string} label
 * @property {boolean} [disabled]
 */

/**
 * @typedef {Object} InputSelectProps
 * @property {string | null} [id] - The ID for the input
 * @property {string | null} [className] - Additional Class(s) to add to the Component.
 * @property {string} [type] - The input `type`.
 * @property {string} [label] - The label to appear above the input
 * @property {boolean} [srOnlyLabel] -
 * @property {boolean} [inline] -
 * @property {string} [moreInfo] -
 * @property {string} [footnote] -
 * @property {"default" | "gray-outline" | null} [theme] -
 * @property {"md" | "sm"} [size] -
 * @property {"default" | "gray-outline"} [theme] -
 * @property {boolean} [required] -
 * @property {boolean} [invalid] -
 * @property {string} [invalidMessage] -
 * @property {boolean} [readonly] -
 * @property {boolean} [disabled] -
 * @property {Array<Option>} options -
 * @property {boolean} [multiple] -
 * @property {boolean} [allowNull] -
 * @property {string | number} [value] -
 * @property {function} onValueChange -
 */

/** @type InputSelectProps */
const defaultProps = {
	theme: "light",
	size: "md",
	allowNull: true,
	nullOptionText: "Choose One",
	options: [],
	value: null,
	labelClass: "",
	onKeyDown: () => false,
};

/**
 * An InputSelect Component.
 * @param {InputSelectProps} props
 * @return {JSX.Element}
 * @constructor
 */
const InputSelect = (props) => {
	props = { ...defaultProps, ...props };

	const [id] = useState(props.id || randomString());
	const [selectedValue, setSelectedValue] = useState(props.value);

	const getClasses = () => {
		const classes = ["input-select"];
		props.theme && classes.push(`theme-${props.theme}`);
		props.size && classes.push(`size-${props.size}`);
		props.multiple && classes.push("multi-select");
		return classes.toString().replace(/,/g, " ");
	};

	const handleChange = (event) => {
		const newValue = event.target.value;

		if (props.multiple) {
			const selected = selectedValue.includes(newValue)
				? selectedValue.filter((value) => value !== newValue)
				: [...selectedValue, newValue];
			setSelectedValue(selected);
		} else {
			setSelectedValue(newValue);
		}
	};

	useEffect(() => {
		if (props.onValueChange && selectedValue !== props.value) {
			props.onValueChange(selectedValue);
		}
	}, [selectedValue]);

	useEffect(() => {
		if (props.value !== selectedValue) {
			setSelectedValue(props.value);
		}
	}, [props.value]);

	return (
		<InputWrapper
			includesIcon={props.includesIcon}
			id={id}
			theme={props.theme}
			label={props.label}
			srOnlyLabel={props.srOnlyLabel}
			required={props.required}
			moreInfo={props.moreInfo}
			footnote={props.footnote}
			inline={props.inline}
			invalid={props.invalid}
			invalidMessage={props.invalidMessage}
			labelClass={props.labelClass}
		>
			<div className={getClasses()} data-testid='InputSelect'>
				<select
					id={id}
					onKeyDown={props.onKeyDown}
					disabled={props.disabled}
					className={`${props.invalid ? "is-invalid" : ""}`}
					value={selectedValue}
					multiple={props.multiple}
					onChange={handleChange}
				>
					{props.allowNull && (
						<option value='' disabled={props.required}>
							{props.nullOptionText}
						</option>
					)}
					{props.options.map((option, index) => (
						<option key={index} value={option.value} disabled={option.disabled === true}>
							{option.label}
						</option>
					))}
				</select>
			</div>
		</InputWrapper>
	);
};

export default InputSelect;
