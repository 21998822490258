import "./Ai.style.scss";

import React from "react";

function Ai(props) {
	const createMarkup = () => {
		return { __html: props.children };
	};

	return (
		<div className='ai-container'>
			{props.loading ? (
				<h1 className='loading'>Loading...</h1>
			) : (
				<div className='ai-response'>
					<div dangerouslySetInnerHTML={createMarkup()} />
				</div>
			)}
		</div>
	);
}

export default Ai;
