import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	data: false,
};

export const siSlice = createSlice({
	name: "si",
	initialState,
	reducers: {
		updateSiData: (state, action) => {
			state.data = action.payload;
		},
	},
});

export const { updateSiData } = siSlice.actions;

export default siSlice.reducer;
