/**
 * Converts a hex color string to an RGBA color object.
 *
 * @param {string} hexColor - The hex color string to convert.
 * @returns {object} The RGBA color object.
 */
export function hexToRgba(hexColor) {
	if (!hexColor) {
		// console.warn("helpers > hexToRgba() -> 'missing required input'");
		return hexColor;
	}

	const red = parseInt(hexColor.substring(1, 3), 16);
	const green = parseInt(hexColor.substring(3, 5), 16);
	const blue = parseInt(hexColor.substring(5, 7), 16);
	return { r: red, g: green, b: blue, a: 255 };
}

/**
 * Converts an RGBA color object to a hex color string.
 *
 * @param {object} rgbaColor - The RGBA color object to convert.
 * @returns {string} The hex color string.
 */
export function rgbaToHex(rgbaColor) {
	if (!rgbaColor) {
		// console.warn("helpers > rgbaToHex() -> 'missing required input'");
		return rgbaColor;
	}

	const red = rgbaColor.r.toString(16).padStart(2, "0");
	const green = rgbaColor.g.toString(16).padStart(2, "0");
	const blue = rgbaColor.b.toString(16).padStart(2, "0");
	return "#" + red + green + blue;
}

/**
 * Interpolates a color between two RGBA colors based on a percentage value.
 *
 * @param {object} color1 - The first RGBA color.
 * @param {object} color2 - The second RGBA color.
 * @param {number} percentage - The percentage value between 0 and 1.
 * @returns {object} The interpolated RGBA color.
 */
export function interpolateColor(color1, color2, percentage = 0) {
	if (!color1 || !color2) {
		// console.warn("helpers > interpolateColor() -> 'missing required input'");
		return color1;
	}

	const red = Math.round((1 - percentage) * color1.r + percentage * color2.r);
	const green = Math.round((1 - percentage) * color1.g + percentage * color2.g);
	const blue = Math.round((1 - percentage) * color1.b + percentage * color2.b);
	return { r: red, g: green, b: blue, a: 255 };
}

/**
 * Returns a color value along a color gradient based on a percentage value.
 *
 * @param {number} percent - The percentage value between 0 and 100.
 * @param {string[]} gradient - The color gradient represented as an array of hex color strings.
 * @returns {string} The hex color string.
 */
export function getGradientColor(percent, gradient) {
	// Get the number of colors in the gradient array
	const colorCount = gradient.length;

	// Calculate the index of the first color in the gradient array
	const index = (percent / 100) * (colorCount - 1);

	// Calculate the percentage between the two nearest colors
	const percentage = index % 1;

	// Determine the indices of the two nearest colors
	const colorIndex1 = Math.floor(index);
	const colorIndex2 = Math.ceil(index);

	// Get the color values of the two nearest colors
	const color1 = gradient[colorIndex1];
	const color2 = gradient[colorIndex2];

	// Convert the color values to RGBA format
	const rgbaColor1 = hexToRgba(color1);
	const rgbaColor2 = hexToRgba(color2);

	// Calculate the interpolated color value
	const interpolatedColor = interpolateColor(rgbaColor1, rgbaColor2, percentage);

	// Convert the interpolated color value back to hex format
	const hexColor = rgbaToHex(interpolatedColor);

	// Return the interpolated color value
	return hexColor;
}
