// components/ButtonRow/ButtonRow.jsx

import "./ButtonRow.style.scss";

import React from "react";

/**
 * Props for a ButtonRow component.
 * @typedef  {Object} ButtonRowProps
 * @property {string | null} [id]  - The ID of the component.
 * @property {string | null} [className] - The class name of the component.
 * @property {"left" | "center" | "right"} [align]; - The alignment of the component.
 * @property {boolean} noWrap
 * @property {React.ReactNode} [children]
 */

/** @type {ButtonRowProps} */
const defaultProps = {
	id: null,
	className: null,
	align: "left",
};

/**
 * A Block Container that manages layout & spacing for a set of Buttons
 * @param {ButtonRowProps} props
 * @return {JSX.Element}
 * @constructor
 */
const ButtonRow = (props) => {
	props = { ...defaultProps, ...props };

	const getClasses = () => {
		const classes = ["button-row"];

		if (props.className) classes.push(props.className);

		if (props.noWrap) classes.push("no-wrap");

		return classes.toString().replace(/,/g, " ");
	};

	return (
		<div id={props.id} className={getClasses()} data-testid={"ButtonRow"}>
			<div className={`btn-row align-${props.align}`}>{props.children}</div>
		</div>
	);
};

export default ButtonRow;
