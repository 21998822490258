import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchDashboardData } from "../../../redux/score-insights/scoreInsightsActions";
import useFormData from "./useFormData";

/**
 * This hook should handle the initial load only. Subsequent loads are triggered by the form.
 */
const useFetchInitialDashboardData = (dashboardId) => {
	const { initialRequestParams } = useFormData();
	const dispatch = useDispatch();
	const { dashboardData } = useSelector((state) => state.scoreInsightsDashboard);
	const currentDashboardDataId = dashboardData?.dashboardId;

	useEffect(() => {
		// initial fetch only
		if (currentDashboardDataId !== dashboardId) {
			dispatch(fetchDashboardData(initialRequestParams));
		}
	}, [currentDashboardDataId, dashboardId, dispatch, initialRequestParams]);
};

export default useFetchInitialDashboardData;
