import { axiosGet, axiosPost } from "../axios/config";

const prefix = "/api/v2/administration/settings";

export const getSystemSettings = () => {
	return axiosGet(`${prefix}`);
};
export const updateSystemSettings = (data) => {
	return axiosPost(`${prefix}`, data);
};

export const getPublicSystemSettings = () => {
	return axiosGet(`${prefix}/public`);
};
