import React from "react";

function SortDesc() {
	return (
		<g>
			<path d='M20 4V16H23L19 21L15 16H18V4H20ZM12 18V20H3V18H12ZM14 11V13H3V11H14ZM14 4V6H3V4H14Z'></path>
		</g>
	);
}

export default SortDesc;
