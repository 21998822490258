import { axiosPost } from "../axios/config";

const prefix = "/api/v2/";

export const getRoles = async (data) => {
	const response = await axiosPost(`${prefix}roles`, data);
	return response;
};

export const deactivateRoles = async (data) => {
	const response = await axiosPost(`${prefix}deactivate-roles`, data);
	return response;
};
export const activateRoles = async (data) => {
	const response = await axiosPost(`${prefix}activate-roles`, data);
	return response;
};

export const manageRoles = async (data) => {
	const response = await axiosPost(`${prefix}manage-roles`, data);
	return response;
};
