import React from "react";

function DashboardTitle({ error, errorMessage, handleOnDashboardTitleChange, dashboardTitle }) {
	return (
		<div style={{ width: "100%", marginBottom: "28px" }} className='input-item'>
			<div style={{ marginBottom: 0, position: "relative" }} className='card-title'>
				<label
					style={{
						left: "0",
						marginRight: 0,
						top: "0",
						marginBottom: "8px",
						position: "relative",
						fontSize: "24px",
					}}
					htmlFor='dashboardTitle'
				>
					Dashboard Title
				</label>
				{error && (
					<span className='sub-text' style={{ color: "red" }}>
						*{errorMessage}
					</span>
				)}
			</div>
			<input
				id='dashboardTitle'
				value={dashboardTitle}
				type='text'
				name='dashboard_title'
				required={true}
				onChange={handleOnDashboardTitleChange}
			/>
		</div>
	);
}

export default DashboardTitle;
