import React from "react";

import AddIcon from "./Icons/Add";
import ArrowDownSFillIcon from "./Icons/ArrowDownSFill";
import ArrowUpSFillIcon from "./Icons/ArrowUpSFill";
import ArrowBackSFillIcon from "./Icons/ArrowBackSFill";
import ArrowRightBracket from "./Icons/ArrowRightBracket";
import ArrowGoBack from "./Icons/ArrowGoBack";
import BankCard2LineIcon from "./Icons/BankCard2Line";
import BarChartHorizontalFillIcon from "./Icons/BarChartHorizontalFill";
import BusIcon from "./Icons/Bus";
import CalendarLineIcon from "./Icons/CalendarLine";
import CarFillIcon from "./Icons/CarFill";
import ChevronContract from "./Icons/ChevronContract";
import ChevronDown from "./Icons/ChevronDown";
import ChevronExpand from "./Icons/ChevronExpand";
import ChevronUp from "./Icons/ChevronUp";
import CloseIcon from "./Icons/Close";
import CloseCircleFillIcon from "./Icons/CloseCircle";
import DashboardFillIcon from "./Icons/DashboardFill";
import DashboardLineIcon from "./Icons/DashboardLine";
import DeleteBinLineIcon from "./Icons/DeleteBinLine";
import Download2LineIcon from "./Icons/Download2Line";
import EarthFillIcon from "./Icons/EarthFill";
import EarthLineIcon from "./Icons/EarthLine";
import EarthquakeFillIcon from "./Icons/EarthquakeFill";
import EditLineBoxIcon from "./Icons/EditBoxLine";
import EqualizerLineIcon from "./Icons/EqualizerLine";
import EnvelopeLineIcon from "./Icons/EnvelopeLine";
import EyeLineIcon from "./Icons/EyeLine";
import EyeOffLineIcon from "./Icons/EyeOffLine";
import FileCopyLineIcon from "./Icons/FileCopyLine";
import FileUploadLineIcon from "./Icons/FileUploadLine";
import Filter2FillIcon from "./Icons/Filter2Fill";
import Filter2LineIcon from "./Icons/Filter2Line";
import Filter3LineIcon from "./Icons/Filter3Line";
import FunctionAddLine from "./Icons/FunctionAddLine copy";
import GridIcon from "./Icons/Grid";
import HandCoinLineIcon from "./Icons/HandCoinLine";
import InformationFillIcon from "./Icons/InformationFill";
import InformationLineIcon from "./Icons/InformationLine";
import LayoutMasonryLineIcon from "./Icons/LayoutMasonry";
import LayoutTopLineIcon from "./Icons/LayoutTopLine";
import ListSettingsLineIcon from "./Icons/ListSettingsLine";
import ListUnorderedIcon from "./Icons/ListUnordered";
import LockLineIcon from "./Icons/LockLine";
import LockUnlockLineIcon from "./Icons/LockUnlockLine";
import Map2LineIcon from "./Icons/MapPin2Line";
import MapPin2LineIcon from "./Icons/MapPin2Line";
import MenuFoldFillIcon from "./Icons/MenuFoldFill";
import MenuUnfoldFillIcon from "./Icons/MenuUnfoldFill";
import MoneyDollarBoxLineIcon from "./Icons/MoneyDollarBoxLine";
import More2FillIcon from "./Icons/More2Fill";
import More2LineIcon from "./Icons/More2Line";
import PencilFillIcon from "./Icons/PencilFill";
import PencilLineIcon from "./Icons/PencilLine";
import PhoneLineIcon from "./Icons/PhoneLine";
import SearchLineIcon from "./Icons/SearchLine";
import ShareBoxLineIcon from "./Icons/ShareBoxLine";
import SkipDownIcon from "./Icons/SkipDown";
import SkipUpIcon from "./Icons/SkipUp";
import SortAsc from "./Icons/SortAsc";
import SortDesc from "./Icons/SortDesc";
import StickyNoteAddLine from "./Icons/StickyNoteAddLine";
import Upload2LineIcon from "./Icons/Upload2Line";
import UserFillIcon from "./Icons/UserFill";
import UserLineIcon from "./Icons/UserLine";
import WalkIcon from "./Icons/Walk";
import KebabIcon from "./Icons/Kebab";
import InboxArchiveLine from "./Icons/InboxArchiveLine";
import ChainLine from "./Icons/ChainLine";
import PaperWritingLine from "./Icons/PaperWritingLine";
import BuildingLine from "./Icons/BuildingLine";
import TvTrayLine from "./Icons/TvTrayLine";
import CloseCircleLineIcon from "./Icons/CloseCircleLineIcon";
import GearLine from "./Icons/GearLine";
import CheckMark from "./Icons/CheckMark";
import RestartLine from "./Icons/RestartLine";
import Hamburger from "./Icons/Hamburger";
import Group from "./Icons/Group";
import UserStarLine from "./Icons/UserStarLine";
import OrganizationChart from "./Icons/OrganizationChart";

export const Icons = {
	"add": <AddIcon />,
	"arrow-down-s-fill": <ArrowDownSFillIcon />,
	"arrow-right-bracket": <ArrowRightBracket />,
	"arrow-up-s-fill": <ArrowUpSFillIcon />,
	"arrow-go-back": <ArrowGoBack />,
	"arrow-back-s-fill": <ArrowBackSFillIcon />,
	"building-line": <BuildingLine />,
	"bank-card-2-line": <BankCard2LineIcon />,
	"bar-chart-horizontal-fill": <BarChartHorizontalFillIcon />,
	"bus": <BusIcon />,
	"calendar-line": <CalendarLineIcon />,
	"car-fill": <CarFillIcon />,
	"chain-line": <ChainLine />,
	"checkmark": <CheckMark />,
	"chevron-contract": <ChevronContract />,
	"chevron-down": <ChevronDown />,
	"chevron-expand": <ChevronExpand />,
	"chevron-up": <ChevronUp />,
	"close": <CloseIcon />,
	"close-circle-fill": <CloseCircleFillIcon />,
	"close-circle-line": <CloseCircleLineIcon />,
	"dashboard-fill": <DashboardFillIcon />,
	"dashboard-line": <DashboardLineIcon />,
	"delete-bin-line": <DeleteBinLineIcon />,
	"download-2-line": <Download2LineIcon />,
	"earth-fill": <EarthFillIcon />,
	"earth-line": <EarthLineIcon />,
	"earthquake-fill": <EarthquakeFillIcon />,
	"edit-box-line": <EditLineBoxIcon />,
	"equalizer-line": <EqualizerLineIcon />,
	"envelope-line": <EnvelopeLineIcon />,
	"eye-line": <EyeLineIcon />,
	"eye-off-line": <EyeOffLineIcon />,
	"file-upload-line": <FileUploadLineIcon />,
	"filter-2-fill": <Filter2FillIcon />,
	"filter-2-line": <Filter2LineIcon />,
	"filter-3-line": <Filter3LineIcon />,
	"function-add-line": <FunctionAddLine />,
	"gear-line": <GearLine />,
	"grid": <GridIcon />,
	"group": <Group />,
	"hand-coin-line": <HandCoinLineIcon />,
	"hamburger": <Hamburger />,
	"information-fill": <InformationFillIcon />,
	"information-line": <InformationLineIcon />,
	"inbox-archive-line": <InboxArchiveLine />,
	"kebab-solid": <KebabIcon />,
	"layout-masonry-line": <LayoutMasonryLineIcon />,
	"layout-top-line": <LayoutTopLineIcon />,
	"list-settings-line": <ListSettingsLineIcon />,
	"list-unordered": <ListUnorderedIcon />,
	"lock-line": <LockLineIcon />,
	"lock-unlock-line": <LockUnlockLineIcon />,
	"map-2-line": <Map2LineIcon />,
	"map-pin-2-line": <MapPin2LineIcon />,
	"menu-fold-fill": <MenuFoldFillIcon />,
	"menu-unfold-fill": <MenuUnfoldFillIcon />,
	"money-dollar-box-line": <MoneyDollarBoxLineIcon />,
	"more-2-fill": <More2FillIcon />,
	"more-2-line": <More2LineIcon />,
	"paper-writing-line": <PaperWritingLine />,
	"pencil-line": <PencilLineIcon />,
	"pencil-fill": <PencilFillIcon />,
	"phone-line": <PhoneLineIcon />,
	"organization-chart": <OrganizationChart />,
	"restart-line": <RestartLine />,
	"sort-asc": <SortAsc />,
	"sort-desc": <SortDesc />,
	"search-line": <SearchLineIcon />,
	"share-box-line": <ShareBoxLineIcon />,
	"skip-down": <SkipDownIcon />,
	"skip-up": <SkipUpIcon />,
	"sticky-note-add-line": <StickyNoteAddLine />,
	"upload-2-line": <Upload2LineIcon />,
	"user-fill": <UserFillIcon />,
	"user-line": <UserLineIcon />,
	"walk": <WalkIcon />,
	"file-copy-line": <FileCopyLineIcon />,
	"tv-tray-line": <TvTrayLine />,
	"user-star-line": <UserStarLine />,
};
