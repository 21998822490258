// constants/Colors.const.js

export const BLUE_CYAN_GREEN_GRAD = ["#5C86C1", "#3FB7F3", "#81CAB2"];

export const YELLOW_ORANGE_RED_GRAD = ["#FFFFCC", "#FD8D3C", "#800026"];
export const RED_ORANGE_YELLOW_GRAD = ["#800026", "#FD8D3C", "#FFFFCC"];

export const GREEN_YELLOW_RED_GRAD = ["#81CAB2", "#FEB24CFF", "#800026"];
export const RED_YELLOW_GREEN_GRAD = ["#800026", "#FEB24CFF", "#81CAB2"];

export const SOC_CODE_COLORS_COOL = ["#d7f9fa", "#b6e7fa", "#6ebbff", "#4f8df7"];

export const SOC_CODE_COLORS_WARM = [
	"rgb(255,255,204)",
	"rgb(255,237,160)",
	"rgb(254,217,118)",
	"rgb(254,178,76)",
	"rgb(253,141,60)",
	"rgb(252,78,42)",
	"rgb(227,26,28)",
	"rgb(189,0,38)",
	"rgb(128,0,38)",
];
