// components/InputInvalidMessage/InputInvalidMessage.jsx

import "./InputInvalidMessage.style.scss";

import React from "react";

import Icon from "../Icon/Icon";

/**
 * Props for a InputInvalidMessage component.
 * @typedef  {Object} InvalidMessageProps
 * @property {JSX.Element | JSX.Element[] | string | string[]} [children] -
 */

/**
 * A InputInvalidMessage Component
 * @param {InvalidMessageProps} props
 * @return {JSX.Element}
 * @constructor
 */
const InputInvalidMessage = (props) => {
	return (
		<div className='input-invalid-message' data-testid='InputInvalidMessage'>
			{/* <Icon icon='alert-triangle' size='sm' />  */}
			{props.children}
		</div>
	);
};

export default InputInvalidMessage;
