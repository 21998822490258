import React from "react";

function InboxArchiveLine() {
	return (
		<g>
			<path d='M20 3L22 7V20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20V7.00353L4 3H20ZM20 9H4V19H20V9ZM13 10V14H16L12 18L8 14H11V10H13ZM18.7639 5H5.23656L4.23744 7H19.7639L18.7639 5Z'></path>
		</g>
	);
}

export default InboxArchiveLine;
