// constants/google-places.const.js

/** {{ value: string<GOOGLE_PLACES_TYPES>, label: string }[]} - Options for a select/dropdown */
export const GOOGLE_PLACES_TYPES_OPTIONS = [
	// { value: "accounting", label: "Accountants" },
	{ value: "airport", label: "Airports" },
	// { value: "amusement_park", label: "Amusement Parks" },
	// { value: "aquarium", label: "Aquariums" },
	// { value: "art_gallery", label: "Art Galleries" },
	{ value: "atm", label: "ATMs" },
	{ value: "bakery", label: "Bakeries" },
	{ value: "bank", label: "Banks" },
	{ value: "bar", label: "Bars" },
	{ value: "beauty_salon", label: "Beauty Salons" },
	// { value: "bicycle_store", label: "Bicycle Stores" },
	// { value: "book_store", label: "Bookstores" },
	// { value: "bowling_alley", label: "Bowling Alleys" },
	{ value: "bus_station", label: "Bus Stations" },
	{ value: "cafe", label: "Cafes" },
	// { value: "campground", label: "Campgrounds" },
	// { value: "car_dealer", label: "Car Dealerships" },
	{ value: "car_rental", label: "Car Rentals" },
	// { value: "car_repair", label: "Auto Repair" },
	// { value: "car_wash", label: "Car Washes" },
	// { value: "casino", label: "Casinos" },
	// { value: "cemetery", label: "Cemeteries" },
	// { value: "church", label: "Churches" },
	// { value: "city_hall", label: "City Halls" },
	// { value: "clothing_store", label: "Clothing Stores" },
	{ value: "convenience_store", label: "Convenience Stores" },
	// { value: "courthouse", label: "Courthouses" },
	// { value: "dentist", label: "Dentists" },
	// { value: "department_store", label: "Department Stores" },
	// { value: "doctor", label: "Doctors" },
	// { value: "drugstore", label: "Drugstores" },
	// { value: "electrician", label: "Electricians" },
	// { value: "electronics_store", label: "Electronics Stores" },
	// { value: "embassy", label: "Embassies" },
	{ value: "fire_station", label: "Fire Stations" },
	// { value: "florist", label: "Florists" },
	// { value: "funeral_home", label: "Funeral Homes" },
	// { value: "furniture_store", label: "Furniture Stores" },
	// { value: "gas_station", label: "Gas Stations" },
	{ value: "gym", label: "Gyms" },
	// { value: "hair_care", label: "Hair Salons" },
	// { value: "hardware_store", label: "Hardware Stores" },
	// { value: "hindu_temple", label: "Hindu Temples" },
	// { value: "home_goods_store", label: "Home Goods Stores" },
	{ value: "hospital", label: "Hospitals" },
	// { value: "insurance_agency", label: "Insurance Agencies" },
	// { value: "jewelry_store", label: "Jewelry Stores" },
	// { value: "laundry", label: "Laundries" },
	// { value: "lawyer", label: "Lawyers" },
	// { value: "library", label: "Libraries" },
	{ value: "light_rail_station", label: "Light Rail Stations" },
	// { value: "liquor_store", label: "Liquor Stores" },
	// { value: "local_government_office", label: "Local Government Offices" },
	// { value: "locksmith", label: "Locksmiths" },
	{ value: "lodging", label: "Lodgings" },
	// { value: "meal_delivery", label: "Food Deliveries" },
	// { value: "meal_takeaway", label: "Food Takeaways" },
	// { value: "mosque", label: "Mosques" },
	// { value: "movie_rental", label: "Movie Rentals" },
	// { value: "movie_theater", label: "Movie Theaters" },
	// { value: "moving_company", label: "Moving Companies" },
	// { value: "museum", label: "Museums" },
	{ value: "night_club", label: "Nightclubs" },
	// { value: "painter", label: "Painters" },
	{ value: "park", label: "Parks" },
	{ value: "parking", label: "Parking" },
	// { value: "pet_store", label: "Pet Stores" },
	{ value: "pharmacy", label: "Pharmacies" },
	// { value: "physiotherapist", label: "Physiotherapists" },
	// { value: "plumber", label: "Plumbers" },
	{ value: "police", label: "Police Stations" },
	{ value: "post_office", label: "Post Offices" },
	// { value: "primary_school", label: "Primary Schools" },
	// { value: "real_estate_agency", label: "Real Estate Agencies" },
	{ value: "restaurant", label: "Restaurants" },
	// { value: "roofing_contractor", label: "Roofing Contractors" },
	// { value: "rv_park", label: "RV Parks" },
	// { value: "school", label: "Schools" },
	// { value: "secondary_school", label: "Secondary Schools" },
	// { value: "shoe_store", label: "Shoe Stores" },
	{ value: "shopping_mall", label: "Shopping Malls" },
	{ value: "spa", label: "Spas" },
	// { value: "stadium", label: "Stadiums" },
	{ value: "storage", label: "Storage Facilities" },
	// { value: "store", label: "Stores" },
	{ value: "subway_station", label: "Subway Stations" },
	{ value: "supermarket", label: "Supermarkets" },
	// { value: "synagogue", label: "Synagogues" },
	{ value: "taxi_stand", label: "Taxi Stands" },
	// { value: "tourist_attraction", label: "Tourist Attractions" },
	{ value: "train_station", label: "Train Stations" },
	{ value: "transit_station", label: "Transit Stations" },
	// { value: "travel_agency", label: "Travel Agencies" },
	{ value: "university", label: "Universities" },
	// { value: "veterinary_care", label: "Veterinary Care" },
	// { value: "zoo", label: "Zoos" },
];

/** {string[]} - all of the types that support a minprice and maxprice query param  */
export const GOOGLE_PLACES_TYPES_WITH_PRICE = [
	"bakery",
	"bar",
	"beauty_salon",
	"bowling_alley",
	"cafe",
	"clothing_store",
	"convenience_store",
	"department_store",
	"drugstore",
	"electronics_store",
	"florist",
	"furniture_store",
	"gas_station",
	"gym",
	"hair_care",
	"home_goods_store",
	"jewelry_store",
	"liquor_store",
	"pharmacy",
	"restaurant",
	"shoe_store",
	"spa",
	"store",
	"supermarket",
];

/** {string[]} - all of the types supported by Google Places  */
export const GOOGLE_PLACES_TYPES = [
	"accounting",
	"airport",
	"amusement_park",
	"aquarium",
	"art_gallery",
	"atm",
	"bakery",
	"bank",
	"bar",
	"beauty_salon",
	"bicycle_store",
	"book_store",
	"bowling_alley",
	"bus_station",
	"cafe",
	"campground",
	"car_dealer",
	"car_rental",
	"car_repair",
	"car_wash",
	"casino",
	"cemetery",
	"church",
	"city_hall",
	"clothing_store",
	"convenience_store",
	"courthouse",
	"dentist",
	"department_store",
	"doctor",
	"drugstore",
	"electrician",
	"electronics_store",
	"embassy",
	"fire_station",
	"florist",
	"funeral_home",
	"furniture_store",
	"gas_station",
	"gym",
	"hair_care",
	"hardware_store",
	"hindu_temple",
	"home_goods_store",
	"hospital",
	"insurance_agency",
	"jewelry_store",
	"laundry",
	"lawyer",
	"library",
	"light_rail_station",
	"liquor_store",
	"local_government_office",
	"locksmith",
	"lodging",
	"meal_delivery",
	"meal_takeaway",
	"mosque",
	"movie_rental",
	"movie_theater",
	"moving_company",
	"museum",
	"night_club",
	"painter",
	"park",
	"parking",
	"pet_store",
	"pharmacy",
	"physiotherapist",
	"plumber",
	"police",
	"post_office",
	"primary_school",
	"real_estate_agency",
	"restaurant",
	"roofing_contractor",
	"rv_park",
	"school",
	"secondary_school",
	"shoe_store",
	"shopping_mall",
	"spa",
	"stadium",
	"storage",
	"store",
	"subway_station",
	"supermarket",
	"synagogue",
	"taxi_stand",
	"tourist_attraction",
	"train_station",
	"transit_station",
	"travel_agency",
	"university",
	"veterinary_care",
	"zoo",
];
