import React from "react";

function Hamburger() {
	return (
		<g>
			<path d='M3 4H21V6H3V4ZM3 11H21V13H3V11ZM3 18H21V20H3V18Z'></path>
		</g>
	);
}

export default Hamburger;
