/** Checks to see if an array is valid and not empty */
export function arrayIsValid(array) {
	return Array.isArray(array) && array.length > 0;
}

/** Checks to see if a value or object is in an array */
export function objectInArray(array, key, value = null) {
	if (!arrayIsValid(array) || !key) {
		return false;
	}

	return array.some((e) => e[key] === value);
}

/** sorts an array by a key value */
export function sortByKey(array, key, order = "ASC") {
	// if the array is empty or not an array do nothing
	if (!arrayIsValid(array)) {
		return array;
	}

	const resArray = [...array];

	// if the key is undefined or a blank string set the order and return
	if (key === undefined || key === "") {
		return order === "DESC" ? resArray.reverse() : resArray;
	}

	// all good so far so lets sort the array by the key
	resArray.sort((a, b) => {
		if (a[key] < b[key]) {
			return -1;
		} else if (a[key] > b[key]) {
			return 1;
		} else {
			return 0;
		}
	});

	// return sorted array in ascending or descending order
	return order === "DESC" ? resArray.reverse() : resArray;
}

/** Sorts an array by multiple keys */
export function sortByKeys(array, keys, order = "ASC") {
	// if the array is empty or not an array do nothing
	if (!arrayIsValid(array)) {
		return array;
	}

	let resArray = [...array];

	keys.forEach((key) => (resArray = sortByKey(resArray, key, order)));

	return resArray;
}

/**
 * Checks to see if a string has a partial match to one of specified properties in an object
 * @param {Object} obj - The object to search
 * @param {string[]} keys - array of keys of the object
 * @param {string} str - the search string
 * @return {boolean}
 */
export function objectContainsPartialMatch(obj, keys, str) {
	// Normalize the search string to lowercase
	const normalizedStr = str.toLowerCase();

	// Loop through each key in the keys array
	for (let i = 0; i < keys.length; i++) {
		// Check if the current key exists in the object
		if (keys[i] in obj) {
			// Check if the value associated with the current key is not null
			if (obj[keys[i]] !== null) {
				// Normalize the value associated with the current key to lowercase
				const normalizedValue = obj[keys[i]].toString().toLowerCase();
				// Check if the normalized value associated with the current key partially matches the normalized search string
				if (normalizedValue.includes(normalizedStr)) {
					// If there's a match, return true
					return true;
				}
			}
		}
	}

	// If no matches were found, return false
	return false;
}

/**
 * Returns the lowest value of a specific property in an array of objects.
 * @param {Array} arr - The array of objects.
 * @param {string} key - The key for the property to find the lowest value.
 * @returns {*} - The lowest value of the specified property.
 */
export function findLowestValue(arr, key) {
	let lowestValue = null;

	// Iterate over each object in the array
	for (let i = 0; i < arr.length; i++) {
		const value = arr[i][key];

		// Update the lowestValue if the current value is lower
		if (value !== undefined && (lowestValue === null || value < lowestValue)) {
			lowestValue = value;
		}
	}

	return lowestValue;
}

/**
 * Returns the highest value of a specific property in an array of objects.
 * @param {Array} arr - The array of objects.
 * @param {string} key - The key for the property to find the highest value.
 * @returns {*} - The highest value of the specified property.
 */
export function findHighestValue(arr, key) {
	let highestValue = null;

	// Iterate over each object in the array
	for (let i = 0; i < arr.length; i++) {
		const value = arr[i][key];

		// Update the highestValue if the current value is higher
		if (value !== undefined && (highestValue === null || value > highestValue)) {
			highestValue = value;
		}
	}

	return highestValue;
}
