import { useEffect, useState } from "react";

const CacheBuster = (props) => {
	const [cacheloading, setLoading] = useState(true);
	const [isLatestVersion, setIsLatestVersion] = useState(false);

	const refreshCacheAndReload = () => {
		console.log("Clearing cache and hard reloading...");
		if (typeof caches !== "undefined") {
			caches
				.keys()
				.then(async function (names) {
					await Promise.all(names.map((name) => caches.delete(name)));
				})
				.then(() => {
					window.location.reload(true);
				});
		}
	};

	useEffect(() => {
		fetch("/meta.json")
			.then((response) => response.json())
			.then((data) => {
				const currentVersion = data.version;
				const storedVersion = Number(localStorage.getItem("BCV"));

				if (currentVersion !== storedVersion) {
					setLoading(false);
					console.log(
						`We have a new version (${currentVersion}, ${typeof currentVersion})  vs localStorage (${storedVersion}, ${typeof storedVersion}). Cache refresh needed.`,
					);
					setIsLatestVersion(false);
					localStorage.setItem("BCV", currentVersion);
				} else {
					console.log(
						`You already have the latest version from meta.json (${typeof currentVersion} ${currentVersion})  vs localStorage (${typeof storedVersion} ${storedVersion}). No cache refresh needed.`,
					);
					setLoading(false);

					setIsLatestVersion(true);
				}
			})
			.catch((error) => console.error(error));
	}, []);

	return props.children({ cacheloading, isLatestVersion, refreshCacheAndReload });
};

export default CacheBuster;
