import StreetInsightsType from "./street-insights.type";

export const setSelectedLocation = (data) => ({
	type: StreetInsightsType.SET_SELECTED_LOCATION,
	payload: data,
});

export const setLocationHistory = (data) => ({
	type: StreetInsightsType.SET_LOCATION_HISTORY,
	payload: data,
});
