// InputRadioPillGroup.tsx

import "./InputRadioPillGroup.style.scss";

import React, { useState } from "react";

import { randomString } from "../../helper/string.helpers";
import InputWrapper from "../InputWrapper/InputWrapper";
import InputRadioPill from "./InputRadioPill";

/**
 * @typedef RadioOption
 * @property id {string}
 * @property value {string | number}
 * @property label {string}
 * @property disabled {boolean}
 */

/**
 * @typedef InputRadioGroupProps {
 * @property id {string}
 * @property groupName {string}
 * @property label {string}
 * @property moreInfo {string}
 * @property srOnlyLabel {boolean}
 * @property footnote {string}
 * @property options {RadioOption[]}
 * @property required {boolean}
 * @property invalid {boolean}
 * @property invalidMessage {string}
 * @property inline {boolean}
 * @property value {string | number}
 * @property onValueChange {React.Dispatch<React.SetStateAction<string>>}
 */

/** @type InputRadioGroupProps */
const defaultProps = {
	inline: false,
	options: [],
	required: false,
	disabled: false,
	labelClass: "",
};

const InputRadioPillGroup = (props) => {
	props = { ...defaultProps, ...props };

	const [id] = useState(props.id || randomString());
	const [groupName] = useState(props.groupName || randomString());

	const handleChange = (e) => {
		const { value } = e.target;
		props.onValueChange && props.onValueChange(value);
	};

	const radioList = props.options?.map((option, index) => {
		return (
			<InputRadioPill
				includeActiveCheck={props.includeActiveCheck}
				key={index}
				id={option.id || randomString()}
				label={option.label}
				groupName={groupName}
				disabled={props.disabled || option.disabled}
				value={option.value}
				selected={props.value}
				onValueChange={handleChange}
			/>
		);
	});

	return (
		<InputWrapper
			id={id}
			labelClass={props.labelClass}
			includesIcon={props.includesIcon}
			label={props.label}
			srOnlyLabel={props.srOnlyLabel}
			required={props.required}
			moreInfo={props.moreInfo}
			footnote={props.footnote}
			inline={props.inline}
			invalid={props.invalid}
			invalidMessage={props.invalidMessage}
		>
			<div
				className={`input-radio-pill-group ${props.invalid ? "is-invalid" : ""}`}
				data-testid='InputRadioGroup'
			>
				{radioList}
			</div>
		</InputWrapper>
	);
};

export default InputRadioPillGroup;
