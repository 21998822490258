import { axiosGet, axiosPost } from "../../axios/config";

const prefix = "/api/v1/";
const organizationsPrefix = "/api/v2/administration/organizations/";

export const GetUsers = async (data) => {
	const response = await axiosGet(`${prefix}user`, data);
	return response;
};

export const addMember = async (data) => {
	const response = await axiosPost(`${prefix}add-member`, data);
	return response;
};

export const searchCompany = async (data) => {
	const response = await axiosPost(`${prefix}search-company`, data);
	return response;
};

export const getCompanyById = async (data) => {
	const response = await axiosPost(`${prefix}get-company`, data);
	return response;
};

export const getOrgById = async (data) => {
	const response = await axiosPost(`${organizationsPrefix}get-organization`, data);
	return response;
};

export const removeMember = async (data) => {
	const response = await axiosPost(`${prefix}delete-member`, data);
	return response;
};

export const addCompany = async (data) => {
	const response = await axiosPost(`${prefix}add-company`, data);
	return response;
};

export const removeCompany = async (data) => {
	const response = await axiosPost(`${prefix}delete-company`, data);
	return response;
};
export const removeOrganization = async (data) => {
	const response = await axiosPost(`${organizationsPrefix}delete-organization`, data);
	return response;
};

export const cancelInvitation = async (data) => {
	const response = await axiosPost(`${prefix}delete-invitation`, data);
	return response;
};

export const removeProfilePicture = async () => {
	const response = await axiosPost(`${prefix}remove-profile-pic`);
	return response;
};
export const removeBrandLogo = async (data) => {
	const response = await axiosPost(`${prefix}remove-brand-logo`, data);
	return response;
};

export const changeBrandLogo = async (data) => {
	const response = await axiosPost(`${prefix}change-company-logo`, data);
	return response;
};

export const getAllMember = async (data) => {
	const response = await axiosPost(`${prefix}get-users`, data);
	return response;
};

export const getCompanyMembers = async (data) => {
	const response = await axiosPost(`${prefix}get-company-members`, data);
	return response;
};

export const getAllUsers = async (data) => {
	const response = await axiosPost(`${prefix}get-all-users`, data);
	return response;
};

export const getMyCompanyUsers = async (data) => {
	const response = await axiosPost(`${prefix}get-my-company-users`, data);
	return response;
};

export const getCompanyMembersAdmins = async (data) => {
	const response = await axiosPost(`${prefix}get-company-users`, data);
	return response;
};

export const getCollaborations = async (data) => {
	const response = await axiosPost(`/api/v2/get-collaborations`, data);
	return response;
};

export const activateCollaboration = async (data) => {
	const response = await axiosPost(`/api/v2/activate-collaboration`, data);
	return response;
};

export const manageCollaborations = async (data) => {
	const response = await axiosPost(`/api/v2/manage-collaborations`, data);
	return response;
};

export const makeCollaborations = async (data) => {
	const response = await axiosPost(`/api/v2/collaborations`, data);
	return response;
};

export const userDetails = async (data) => {
	const response = await axiosPost(`${prefix}user-details`, data);
	return response;
};

export const getCompanyAdmins = async (data) => {
	const response = await axiosPost(`${prefix}get-company-admins`, data);
	return response;
};

export const updateMember = async (data) => {
	const response = await axiosPost(`${prefix}update-member`, data);
	return response;
};

export const updateUserTos = async (data) => {
	const response = await axiosPost(`${prefix}user-tos`, data);
	return response;
};

export const getLatestAcceptedUserTos = async (data) => {
	const response = await axiosGet(`${prefix}latest-user-tos`, data);
	return response;
};

export const changeUserStatus = async (data) => {
	const response = await axiosPost(`${prefix}change-status`, data);
	return response;
};
export const GetClientAdmin = async (data) => {
	const response = await axiosPost(`${prefix}get-admin-details`, data);
	return response;
};

export const getUserDashboards = async (data) => {
	const response = await axiosPost(`${prefix}get-users-dashboards`, data);
	return response;
};
export const expireDashboards = async (data) => {
	const response = await axiosPost(`${prefix}revoke-shared-dashboards`, data);
	return response;
};
export const archiveUsers = async (data) => {
	const response = await axiosPost(`${prefix}archive-users`, data);
	return response;
};
export const restoreAchivedUsers = async (data) => {
	const response = await axiosPost(`${prefix}restore-archived-users`, data);
	return response;
};
export const makeUsersActive = async (data) => {
	const response = await axiosPost(`${prefix}make-users-active`, data);
	return response;
};

export const makeUsersInActive = async (data) => {
	const response = await axiosPost(`${prefix}make-users-inactive`, data);
	return response;
};
export const archiveCompanies = async (data) => {
	const response = await axiosPost(`${prefix}archive-companies`, data);
	return response;
};
export const deactivateCompanies = async (data) => {
	const response = await axiosPost(`${prefix}make-companies-inactive`, data);
	return response;
};
export const deactivateOrganizations = async (data) => {
	const response = await axiosPost(`${organizationsPrefix}make-organizations-inactive`, data);
	return response;
};
export const activateCompanies = async (data) => {
	const response = await axiosPost(`${prefix}make-companies-active`, data);
	return response;
};
export const activateOrganizations = async (data) => {
	const response = await axiosPost(`${organizationsPrefix}make-organizations-active`, data);
	return response;
};

export const restoreArchivedCompanies = async (data) => {
	const response = await axiosPost(`${prefix}restore-archived-companies`, data);
	return response;
};

export const restoreArchivedOrganizations = async (data) => {
	const response = await axiosPost(`${organizationsPrefix}restore`, data);
	return response;
};

export const addOrganization = async (data) => {
	const response = await axiosPost(`${organizationsPrefix}create`, data);
	return response;
};
