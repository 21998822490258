import { axiosGet } from "../../axios/config";

const prefix = "/api/v1/";

export const getDashboard = async (data) => {
	const response = await axiosGet(`${prefix}user-dashboard`, data);
	return response;
};

export const getDashboardList = async (data) => {
	const response = await axiosGet(`${prefix}get-dashboard`, data);
	return response;
};
