import React from "react";

function PaperWritingLine() {
	return (
		<g>
			<path
				d='M8.33398 18H16.334V16H8.33398V18ZM8.33398 14H16.334V12H8.33398V14ZM6.33398 22C5.78398 22 5.31315 21.8042 4.92148 21.4125C4.52982 21.0208 4.33398 20.55 4.33398 20V4C4.33398 3.45 4.52982 2.97917 4.92148 2.5875C5.31315 2.19583 5.78398 2 6.33398 2H14.334L20.334 8V20C20.334 20.55 20.1382 21.0208 19.7465 21.4125C19.3548 21.8042 18.884 22 18.334 22H6.33398ZM13.334 9V4H6.33398V20H18.334V9H13.334Z'
				fill='#93A3BE'
			/>
		</g>
	);
}

export default PaperWritingLine;
