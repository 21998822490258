import React from "react";

function ChainLine() {
	return (
		<g>
			<path
				d='M11.334 17H7.33398C5.95065 17 4.77148 16.5125 3.79648 15.5375C2.82148 14.5625 2.33398 13.3833 2.33398 12C2.33398 10.6167 2.82148 9.4375 3.79648 8.4625C4.77148 7.4875 5.95065 7 7.33398 7H11.334V9H7.33398C6.50065 9 5.79232 9.29167 5.20898 9.875C4.62565 10.4583 4.33398 11.1667 4.33398 12C4.33398 12.8333 4.62565 13.5417 5.20898 14.125C5.79232 14.7083 6.50065 15 7.33398 15H11.334V17ZM8.33398 13V11H16.334V13H8.33398ZM13.334 17V15H17.334C18.1673 15 18.8757 14.7083 19.459 14.125C20.0423 13.5417 20.334 12.8333 20.334 12C20.334 11.1667 20.0423 10.4583 19.459 9.875C18.8757 9.29167 18.1673 9 17.334 9H13.334V7H17.334C18.7173 7 19.8965 7.4875 20.8715 8.4625C21.8465 9.4375 22.334 10.6167 22.334 12C22.334 13.3833 21.8465 14.5625 20.8715 15.5375C19.8965 16.5125 18.7173 17 17.334 17H13.334Z'
				fill='#93A3BE'
			/>
		</g>
	);
}

export default ChainLine;
