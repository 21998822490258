import AmazonTypes from "./amazon.type";

const INITIAL_STATE = {
	// Labor-SCORE
	occupationForLaborScore: {
		name: "Laborers and Freight, Stock, and Material Movers",
	},
	occupationOptionForLaborScore: [],
	driveTimeForLaborScore: { name: "30" },
	driveTimeOptionForLaborScore: [],
	marketForLaborScore: { name: "All" },
	marketOptionForLaborScore: [],
	laborSupplyForLaborScore: 25,
	laborCostForLaborScore: 25,
	laborSustainabilityForLaborScore: 25,
	laborCompetitionForLaborScore: 25,

	/// labor-supply
	laborWeightForLaborSupply: 60,
	segmentationWeightForLaborSupply: 40,

	/// labor-cost

	optionPercentileWageForLaborCost: [],
	selectedPercentileWageForLaborCost: { name: "Avg. Hourly Earnings" },
	costWeightingForLaborCost: 100,

	// Labor Competition
	laborShiftWeightingForLaborCompetition: 100,

	// potential employee

	mosaicHouseholdForPotentialEmployee: [{ name: "I30" }],
	mosaicHouseholdOptionForPotentialEmployee: [],
};

const AmazonReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case AmazonTypes.SET_OCCUPATION_FOR_LABORSCORE:
			return { ...state, occupationForLaborScore: action.payload };

		case AmazonTypes.SET_OCCUPATION_OPTION_FOR_LABORSCORE:
			return { ...state, occupationOptionForLaborScore: action.payload };

		case AmazonTypes.SET_DRIVETIME_FOR_LABORSCORE:
			return { ...state, driveTimeForLaborScore: action.payload };

		case AmazonTypes.SET_DRIVETIME_OPTION_FOR_LABORSCORE:
			return { ...state, driveTimeOptionForLaborScore: action.payload };

		case AmazonTypes.SET_MARKET_FOR_LABORSCORE:
			return { ...state, marketForLaborScore: action.payload };

		case AmazonTypes.SET_MARKET_OPTION_FOR_LABORSCORE:
			return { ...state, marketOptionForLaborScore: action.payload };

		case AmazonTypes.SET_LABORSUPPLY_FOR_LABORSCORE:
			return { ...state, laborSupplyForLaborScore: action.payload };

		case AmazonTypes.SET_LABORCOST_FOR_LABORSCORE:
			return { ...state, laborCostForLaborScore: action.payload };

		case AmazonTypes.SET_LABORSUSTAINABILITY_FOR_LABORSCORE:
			return { ...state, laborSustainabilityForLaborScore: action.payload };

		case AmazonTypes.SET_LABORCOMPETITION_FOR_LABORSCORE:
			return { ...state, laborCompetitionForLaborScore: action.payload };

		case AmazonTypes.SET_LABORWEIGTH_FOR_LABORSUPPLY:
			return { ...state, laborWeightForLaborSupply: action.payload };

		case AmazonTypes.SET_SEGMENTATIONWEITH_FOR_LABORSUPPLY:
			return { ...state, segmentationWeightForLaborSupply: action.payload };

		case AmazonTypes.SET_OPTION_PERCENTILE_WAGE_FOR_LABORCOST:
			return { ...state, optionPercentileWageForLaborCost: action.payload };

		case AmazonTypes.SET_SELECTED_PERCENTILE_WAGE_FOR_LABORCOST:
			return { ...state, selectedPercentileWageForLaborCost: action.payload };

		case AmazonTypes.SET_COST_WEIGTHING_FOR_LABORCOST:
			return { ...state, costWeightingForLaborCost: action.payload };

		case AmazonTypes.SET_LABOR_SHIFTWEIGTHING_FOR_LABORCOMPETITION:
			return {
				...state,
				laborShiftWeightingForLaborCompetition: action.payload,
			};

		case AmazonTypes.SET_MOSAIC_HOUSEHOLD_FOR_POTENTIALEMPLOYEE:
			return { ...state, mosaicHouseholdForPotentialEmployee: action.payload };

		case AmazonTypes.SET_MOSAIC_HOUSEHOLD_OPTION_FOR_POTENTIALEMPLOYEE:
			return {
				...state,
				mosaicHouseholdOptionForPotentialEmployee: action.payload,
			};

		default:
			return state;
	}
};

export default AmazonReducer;
