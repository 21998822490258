import { axiosDelete, axiosGet, axiosPost } from "../../../axios/config";

const prefix = import.meta.env.VITE_REACT_APP_API_URL + "/api/v1/";

export const getScoreInsightsDashboardBlockFeatures = async (id) => {
	const response = await axiosGet(`${prefix}score-insights/dashboard/${id}/block-features`);
	return response;
};

export const getScoreInsightsDashboardList = async () => {
	const response = await axiosGet(`${prefix}score-insights/user-dashboards`);
	return response;
};

export const getScoreInsightsDashboardData = async (dashboardData, config = null) => {
	const response = await axiosPost(`${prefix}score-insights/dashboard-data`, dashboardData, config);
	return response;
};

export const saveScoreInsightsDashboardRequestParams = async (formData) => {
	const response = await axiosPost(`${prefix}score-insights/dashboard/save-request-params`, formData);
	return response;
};

export const getSingleScoreInsightDashboard = async (dashboardId, config = {}) => {
	const response = await axiosGet(`${prefix}score-insights/dashboard/${dashboardId}`, null, config);
	return response;
};

export const getSingleScoreInsightLaborSupply = async (dashboardData) => {
	const response = await axiosPost(`${prefix}score-insights/labor-supply`, dashboardData);
	return response;
};

export const getScoreInsightLocationBlockGeometry = async (locationId) => {
	const response = await axiosGet(`${prefix}score-insights/location/${locationId}/block-geometries`);
	return response;
};

export const getScoreInsightLocationBlockGroups = async (locationId) => {
	const response = await axiosGet(`${prefix}score-insights/location/${locationId}/block-groups`);
	return response;
};

export const getSingleScoreInsightLaborCost = async (dashboardData) => {
	const response = await axiosPost(`${prefix}score-insights/labor-cost`, dashboardData);
	return response;
};

export const getSingleScoreInsightLaborMigration = async (dashboardData) => {
	const response = await axiosPost(`${prefix}score-insights/labor-migration`, dashboardData);
	return response;
};

export const getSingleScoreInsightLaborSustainability = async (dashboardData) => {
	const response = await axiosPost(`${prefix}score-insights/labor-sustainability`, dashboardData);
	return response;
};

export const updateScoreInsightsDashboard = async (dashboardData) => {
	const response = await axiosPost(`${prefix}score-insights/dashboard`, dashboardData);
	return response;
};

export const deleteScoreInsightsDashboard = async (dashboardIdToDelete) => {
	const response = await axiosDelete(`${prefix}score-insights/dashboard/${dashboardIdToDelete}`);
	return response;
};
