/* eslint-disable no-unused-vars */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { getSystemSettings, updateSystemSettings } from "../../services/administration.service";
import { getCompanies, getOrganizations } from "../../services/authentication.service";
import { getDashboards } from "../../services/dashboards.service";
import {
	activateCompanies,
	activateOrganizations,
	archiveCompanies,
	archiveUsers,
	deactivateCompanies,
	deactivateOrganizations,
	expireDashboards,
	getAllUsers,
	getCompanyById,
	getOrgById,
	getUserDashboards,
	makeUsersActive,
	makeUsersInActive,
	removeOrganization,
	restoreAchivedUsers,
	restoreArchivedCompanies,
	restoreArchivedOrganizations,
	userDetails,
} from "../../services/dashboards/users.service";
import { activateRoles, deactivateRoles, getRoles, manageRoles } from "../../services/roles.service";

// Async thunk for getting Organizations and then fetching Companies
export const fetchOrgs = createAsyncThunk("admin/settings/getOrganizations", async (params, { rejectWithValue }) => {
	try {
		// First, fetch organizations
		const orgResponse = await getOrganizations(params);

		return {
			organizations: orgResponse.data,
		};
	} catch (error) {
		return rejectWithValue(error.response ? error.response.data : { error: "Network or server error" });
	}
});

// Async thunk for fetching all companies
export const fetchCompanies = createAsyncThunk(
	"admin/companies/fetchCompanies",
	async (params, { rejectWithValue }) => {
		try {
			// First fetch with provided params
			const response = await getCompanies(params);
			const companiesWithStrings = response.data.companies.map((company) => ({
				...company,
				company_wide_2fa_enabled_string: company.company_wide_2fa_enabled ? "On" : "Off",
				company_status_string: company.company_status === 1 ? "Active" : "Inactive",
			}));

			// Fetch all companies
			const allCompaniesResponse = await getCompanies({ limit: -1 });
			const allCompaniesWithStrings = allCompaniesResponse.data.companies.map((company) => ({
				...company,
			}));

			return {
				total_record: response.data.total_record || 0,
				companies: companiesWithStrings.length > 0 ? companiesWithStrings : [{ total_record: 0 }],
				allCompaniesList: allCompaniesWithStrings,
			};
		} catch (error) {
			return rejectWithValue(error.response ? error.response.data : { error: "Network or server error" });
		}
	},
);

// Async thunk for fetching all roles
export const fetchRoles = createAsyncThunk("admin/roles/fetchRoles", async (params, { rejectWithValue }) => {
	try {
		const response = await getRoles(params);
		return response.data;
	} catch (error) {
		return rejectWithValue(error.response.data);
	}
});

// Async thunk for deactivating roles
export const deactivateRole = createAsyncThunk(
	"admin/roles/deactivateRoles",
	async (params, { rejectWithValue, dispatch }) => {
		try {
			const response = await deactivateRoles(params);
			// Dispatch fetchRoles to refresh the role list
			await dispatch(fetchRoles());
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	},
);

// Async thunk for deactivating companies
export const deactivateCompany = createAsyncThunk(
	"admin/roles/deactivateCompanies",
	async (params, { rejectWithValue }) => {
		try {
			const response = await deactivateCompanies({ companies: [params] });
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	},
);
// Async thunk for deactivating orgs
export const deactivateOrganization = createAsyncThunk("admin/deactivateOrg", async (params, { rejectWithValue }) => {
	try {
		const response = await deactivateOrganizations({ organizations: [params] });
		return response.data;
	} catch (error) {
		return rejectWithValue(error.response.data);
	}
});
// Async thunk for activating orgs
export const activateOrganization = createAsyncThunk("admin/deactivateOrg", async (params, { rejectWithValue }) => {
	try {
		const response = await activateOrganizations({ organizations: [params] });
		return response.data;
	} catch (error) {
		return rejectWithValue(error.response.data);
	}
});
// Async thunk for deactivating companies
export const activateCompany = createAsyncThunk(
	"admin/roles/activateCompanies",
	async (params, { rejectWithValue }) => {
		try {
			const response = await activateCompanies({ companies: [params] });

			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	},
);
// Async thunk for archving users
export const archiveUser = createAsyncThunk("admin/roles/archiveUsers", async (params, { rejectWithValue }) => {
	try {
		const response = await archiveUsers({ users: params });

		return response.data;
	} catch (error) {
		return rejectWithValue(error.response.data);
	}
});
// Async thunk for archving companies
export const archiveCompany = createAsyncThunk("admin/roles/archiveCompanies", async (params, { rejectWithValue }) => {
	try {
		const response = await archiveCompanies({ companies: params });
		return response.data;
	} catch (error) {
		return rejectWithValue(error.response.data);
	}
});
// Async thunk for archving orgs
export const archiveOrganizations = createAsyncThunk("admin/roles/archiveOrgs", async (params, { rejectWithValue }) => {
	try {
		const response = await removeOrganization({ organizations: params });
		return response.data;
	} catch (error) {
		return rejectWithValue(error.response.data);
	}
});
// Async thunk for unarchving users
export const restoreArchivedUser = createAsyncThunk(
	"admin/roles/restoreArchivedUsers",
	async (params, { rejectWithValue }) => {
		try {
			const response = await restoreAchivedUsers({ users: params });
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	},
);
// Async thunk for unarchving companies
export const restoreArchivedCompany = createAsyncThunk(
	"admin/roles/restoreArchivedCompanies",
	async (params, { rejectWithValue }) => {
		try {
			const response = await restoreArchivedCompanies({ companies: params });
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	},
);
// Async thunk for unarchving orgs
export const restoreArchivedOrganization = createAsyncThunk(
	"admin/roles/restoreArchivedOrganizations",
	async (params, { rejectWithValue }) => {
		try {
			const response = await restoreArchivedOrganizations({ organizations: [params] });
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	},
);
// Async thunk for making users active
export const makeUserActive = createAsyncThunk("admin/roles/activateUsers", async (params, { rejectWithValue }) => {
	try {
		const response = await makeUsersActive({ users: [params] });
		return response.data;
	} catch (error) {
		return rejectWithValue(error.response.data);
	}
});
// Async thunk for making users inactiveactive
export const makeUserInActive = createAsyncThunk("admin/roles/deactivateUsers", async (params, { rejectWithValue }) => {
	try {
		const response = await makeUsersInActive({ users: [params] });
		return response.data;
	} catch (error) {
		return rejectWithValue(error.response.data);
	}
});

// Async thunk for activating roles
export const activateRole = createAsyncThunk(
	"admin/roles/activateRoles",
	async (params, { rejectWithValue, dispatch }) => {
		try {
			const response = await activateRoles(params);
			// Dispatch fetchRoles to refresh the role list
			await dispatch(fetchRoles());
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	},
);

// Async thunk for creating roles
export const manageRole = createAsyncThunk(
	"admin/roles/updateCreateRoles",
	async (params, { rejectWithValue, dispatch }) => {
		try {
			const response = await manageRoles(params);
			// Dispatch fetchRoles to refresh the role list
			await dispatch(fetchRoles());
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	},
);

// Async thunk for fetching details of a specific company
export const fetchSingleCompanyData = createAsyncThunk(
	"admin/singleCompany/fetchSingleCompanyData",
	async (companyId, { rejectWithValue }) => {
		try {
			const response = await getCompanyById({ company_id: companyId });
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	},
);

// Async thunk for fetching details of a specific company
export const fetchSingleOrganizationData = createAsyncThunk(
	"admin/singleCompany/fetchSingleOrganizationData",
	async (orgId, { rejectWithValue }) => {
		try {
			const response = await getOrgById({ organization_id: orgId });
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	},
);

// Async thunk for fetching all users
export const fetchAllUsersData = createAsyncThunk(
	"admin/company/fetchAllUsersData",
	async (params, { rejectWithValue, dispatch }) => {
		try {
			const response = await getAllUsers(params);
			dispatch(fetchCompanies({}));

			return {
				total_record: response.data.total_record || 0,
				users: response.data.total_record ? response.data.users : [{ total_record: 0 }],
			};
		} catch (error) {
			return rejectWithValue(error.response ? error.response.data : { error: "Network or server error" });
		}
	},
);
// Async thunk for fetching all dashboards
export const fetchAllDashboards = createAsyncThunk(
	"admin/company/fetchAllDashboards",
	async (params, { rejectWithValue }) => {
		try {
			const response = await getDashboards(params);

			return {
				total_record: response.data.total_record || 0,
				dashboards: response.data.total_record ? response.data.dashboards : { all: [{ total_record: 0 }] },
			};
		} catch (error) {
			return rejectWithValue(error.response ? error.response.data : { error: "Network or server error" });
		}
	},
);

// Async thunk for fetching single user
export const fetchSingleUsersData = createAsyncThunk(
	"admin/user/fetchSingleUserData",
	async (params, { rejectWithValue }) => {
		try {
			const response = await userDetails({ user_id: params });
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response ? error.response.data : { error: "Network or server error" });
		}
	},
);

// Async thunk for fetching single user dashboards
export const fetchSingleUsersDashboards = createAsyncThunk(
	"admin/user/fetchSingleUserDashboards",
	async (params, { rejectWithValue }) => {
		try {
			const response = await getUserDashboards(params);
			// return response.data;
			return {
				total_record: response.data.total_record || 0,
				dashboards: response.data.total_record ? response.data.dashboards : [{ total_record: 0 }],
			};
		} catch (error) {
			return rejectWithValue(error.response ? error.response.data : { error: "Network or server error" });
		}
	},
);
// Async thunk for expiring/revoking the share for
// single or multiple dashboards
export const expireSharedDashboards = createAsyncThunk(
	"admin/user/expireSharedDashboards",
	async (params, { rejectWithValue }) => {
		try {
			const response = await expireDashboards({ dashboards: params });
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response ? error.response.data : { error: "Network or server error" });
		}
	},
);

// Async thunk for getting System Variables/settings
export const getSystemVariables = createAsyncThunk(
	"admin/settings/getSystemVariables",
	async (params, { rejectWithValue }) => {
		try {
			const response = await getSystemSettings();
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response ? error.response.data : { error: "Network or server error" });
		}
	},
);

// Async thunk for updating System Variables/settings
export const updateSystemVariables = createAsyncThunk(
	"admin/settings/updateSystemVariables",
	async (params, { dispatch, rejectWithValue }) => {
		try {
			const response = await updateSystemSettings(params);
			dispatch(getSystemVariables());
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response ? error.response.data : { error: "Network or server error" });
		}
	},
);

const initialState = {
	allOrganizations: { organizations: [] },
	allCompanies: { companies: [{ total_record: 0 }], total_record: 0, allCompaniesList: [{}] },
	allRoles: [{ roles: [{ id: "0", name: "Loading" }], quick_filters: [{}], permissions: [{}], total_record: 0 }],
	currentOrganization: {},
	currentCompany: {},
	currentCompanyEmployees: "",
	currentCompanySingleEmployee: {},
	currentSingleUser: {},
	currentSingleUserCreatedDashboards: [{}],
	currentSingleUserSharedWithOthersDashboards: [{}],
	currentSingleUserSharedFromOthersDashboards: [{}],
	allUsers: [{}],
	systemVariables: {
		default: {},
	},
	loading: false,
	allDashboards: [{}],
};

export const adminSlice = createSlice({
	name: "admin",
	initialState,
	reducers: {
		updateSingleCompanyData: (state, action) => {
			state.currentCompany = action.payload;
		},
		updateSingleOrganizationData: (state, action) => {
			state.currentOrganization = action.payload;
		},
		updateSingleEmployeeData: (state, action) => {
			state.currentCompanySingleEmployee = action.payload;
		},
		resetCurrentCompany: (state) => {
			state.currentCompany = initialState.currentCompany;
		},
		resetCurrentCompanyEmployees: (state) => {
			state.currentCompanyEmployees = initialState.currentCompanyEmployees;
		},
		resetAllUsers: (state) => {
			state.allUsers = initialState.allUsers;
		},
		resetCurrentSingleUser: (state) => {
			state.currentSingleUser = initialState.currentSingleUser;
		},
		resetUsersDashboards: (state) => {
			state.currentSingleUserCreatedDashboards = initialState.currentSingleUserCreatedDashboards;
		},
		resetAllDashboards: (state) => {
			state.allDashboards = initialState.allDashboards;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchCompanies.pending, (state) => {
				state.loading = true;
			})
			.addCase(fetchCompanies.fulfilled, (state, action) => {
				state.allCompanies = action.payload;
				state.loading = false;
			})
			.addCase(fetchCompanies.rejected, (state, action) => {
				state.error = action.payload;
				state.loading = false;
			})
			.addCase(fetchOrgs.pending, (state) => {
				state.loading = true;
			})
			.addCase(fetchOrgs.fulfilled, (state, action) => {
				state.allOrganizations = action.payload.organizations;
				state.allCompaniesList = action.payload.companies;
				state.loading = false;
			})
			.addCase(fetchOrgs.rejected, (state, action) => {
				state.error = action.payload;
				state.loading = false;
			})
			.addCase(fetchAllDashboards.pending, (state) => {
				state.loading = true;
			})
			.addCase(fetchAllDashboards.fulfilled, (state, action) => {
				state.allDashboards = action.payload;
				state.loading = false;
			})
			.addCase(fetchAllDashboards.rejected, (state, action) => {
				state.error = action.payload;
				state.loading = false;
			})
			.addCase(updateSystemVariables.pending, (state) => {
				state.loading = true;
			})
			.addCase(updateSystemVariables.fulfilled, (state) => {
				state.loading = false;
			})
			.addCase(updateSystemVariables.rejected, (state, action) => {
				state.error = action.payload;
				state.loading = false;
			})
			.addCase(getSystemVariables.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(getSystemVariables.fulfilled, (state, action) => {
				state.systemVariables = action.payload;
				state.loading = false;
			})
			.addCase(getSystemVariables.rejected, (state, action) => {
				state.error = action.payload || action.error.message;
				state.loading = false;
			})
			.addCase(archiveUser.pending, (state) => {
				state.loading = true;
			})
			.addCase(archiveUser.fulfilled, (state) => {
				state.loading = false;
			})
			.addCase(archiveUser.rejected, (state, action) => {
				state.error = action.payload;
				state.loading = false;
			})
			.addCase(expireSharedDashboards.pending, (state) => {
				state.loading = true;
			})
			.addCase(expireSharedDashboards.fulfilled, (state) => {
				state.loading = false;
			})
			.addCase(expireSharedDashboards.rejected, (state, action) => {
				state.error = action.payload;
				state.loading = false;
			})
			.addCase(makeUserActive.pending, (state) => {
				state.loading = true;
			})
			.addCase(makeUserActive.fulfilled, (state) => {
				state.loading = false;
			})
			.addCase(makeUserActive.rejected, (state, action) => {
				state.error = action.payload;
				state.loading = false;
			})
			.addCase(makeUserInActive.pending, (state) => {
				state.loading = true;
			})
			.addCase(makeUserInActive.fulfilled, (state) => {
				state.loading = false;
			})
			.addCase(makeUserInActive.rejected, (state, action) => {
				state.error = action.payload;
				state.loading = false;
			})
			.addCase(fetchRoles.pending, (state) => {
				state.loading = true;
			})
			.addCase(fetchRoles.fulfilled, (state, action) => {
				state.allRoles = action.payload;
				state.loading = false;
			})
			.addCase(fetchRoles.rejected, (state, action) => {
				state.allRoles = [
					{ value: 2, name: "User", status: "active" },
					{ value: 0, name: "Super Admin", status: "active" },
				];
				state.error = action.payload;
				state.loading = false;
			})
			.addCase(deactivateRole.pending, (state) => {
				state.loading = true;
			})
			.addCase(deactivateRole.fulfilled, (state) => {
				state.loading = false;
			})
			.addCase(deactivateRole.rejected, (state, action) => {
				state.error = action.payload;
				state.loading = false;
			})
			.addCase(deactivateCompany.pending, (state) => {
				state.loading = true;
			})
			.addCase(deactivateCompany.fulfilled, (state) => {
				state.loading = false;
			})
			.addCase(deactivateCompany.rejected, (state, action) => {
				state.error = action.payload;
				state.loading = false;
			})
			.addCase(fetchSingleCompanyData.pending, (state) => {
				state.currentCompany = initialState.currentCompany;
				state.loading = true;
			})
			.addCase(fetchSingleCompanyData.fulfilled, (state, action) => {
				state.currentCompany = action.payload;
				state.loading = false;
			})
			.addCase(fetchSingleCompanyData.rejected, (state, action) => {
				console.error("Failed to fetch single company details:", action.payload);
			})
			.addCase(fetchSingleOrganizationData.pending, (state) => {
				state.currentOrganization = initialState.currentOrganization;
				state.loading = true;
			})
			.addCase(fetchSingleOrganizationData.fulfilled, (state, action) => {
				state.currentOrganization = action.payload;
				state.loading = false;
			})
			.addCase(fetchSingleOrganizationData.rejected, (state, action) => {
				console.error("Failed to fetch single Organization details:", action.payload);
			})
			.addCase(fetchAllUsersData.pending, (state) => {
				state.loading = true;
			})
			.addCase(fetchAllUsersData.fulfilled, (state, action) => {
				state.allUsers = action.payload;
				state.loading = false;
			})
			.addCase(fetchAllUsersData.rejected, (state, action) => {
				console.error("Failed to fetch single company employee details:", action.payload);
			})
			.addCase(fetchSingleUsersData.pending, (state) => {
				state.loading = true;
				state.currentSingleUser = {};
			})
			.addCase(fetchSingleUsersData.fulfilled, (state, action) => {
				state.currentSingleUser = action.payload;
				state.loading = false;
			})
			.addCase(fetchSingleUsersData.rejected, (state, action) => {
				console.error("Failed to fetch single user details:", action.payload);
			})
			.addCase(fetchSingleUsersDashboards.pending, (state) => {
				state.loading = true;
				state.currentSingleUserCreatedDashboards = initialState.currentSingleUserCreatedDashboards;
			})
			.addCase(fetchSingleUsersDashboards.fulfilled, (state, action) => {
				state.currentSingleUserCreatedDashboards = action.payload;
				state.loading = false;
			})
			.addCase(fetchSingleUsersDashboards.rejected, (state, action) => {
				console.error("Failed to fetch single user dashboards:", action.payload);
			});
	},
});

export const {
	updateSingleCompanyData,
	updateSingleOrganizationData,
	updateSingleEmployeeData,
	resetCurrentCompany,
	resetCurrentCompanyEmployees,
	resetAllUsers,
	resetCurrentSingleUser,
	resetUsersDashboards,
	resetAllDashboards,
} = adminSlice.actions;

export default adminSlice.reducer;
