import React from "react";

const CleanModal = ({
	className = "",
	trigger = false,
	setTrigger,
	title = "Modal Title",
	children,
	customButton = false,
	hideX,
}) => {
	return (
		<>
			<div className={`client-data-upload overlay  ${trigger ? "show" : ""} `} onClick={() => {}}></div>

			<div className={`db-state-table-modal c-modal ${className} ${trigger ? "show" : ""} `}>
				<div className='c-modal-inner'>
					<div className='card'>
						<div className='card-body'>
							<div className='card-title modal-header'>
								<label htmlFor=''>{title}</label>
								{!hideX && (
									<div className='close-btn' onClick={() => setTrigger(false)}>
										<svg width='14' height='15' fill='none' xmlns='http://www.w3.org/2000/svg'>
											<path
												d='M13 1.00195L1 13.002'
												stroke='#111128'
												strokeWidth='2'
												strokeLinecap='round'
												strokeLinejoin='round'
											></path>
											<path
												d='M1 1.00195L13 13.002'
												stroke='#111128'
												strokeWidth='2'
												strokeLinecap='round'
												strokeLinejoin='round'
											></path>
										</svg>
									</div>
								)}
							</div>
							<div className='card-content modal-body'>
								<div className='db-state-table-modal-steps'>{children}</div>
							</div>
							{customButton && (
								<div className='modal-footer'>
									<div className='row m-0'>
										<div className='col col-12 save-div'>{customButton}</div>
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default CleanModal;
