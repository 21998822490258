import "./loader.styles.scss";

import React from "react";

const loader = ({ contentLoader = "", text = "" }) => {
	return (
		<div className={`site-loader-main ${contentLoader ? " content-loader" : ""}`}>
			<div className='site-loader-inner'>
				<div>
					<div className='loading-circle one' />
				</div>
				<div>
					<div className='loading-circle two' />
					<div className='loading-circle three' />
				</div>
				<div>
					<div className='loading-circle four' />
					<div className='loading-circle five' />
					<div className='loading-circle six' />
				</div>
			</div>
			<p className='loader-text'>{text}</p>
		</div>
	);
};

export default loader;
