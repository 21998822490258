import { boolean } from "boolean";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Userpilot } from "userpilot";

const userPilotEnabled = boolean(import.meta.env.VITE_REACT_APP_USERPILOT_ENABLED);

export const identifyUser = (
	user = {
		id: null,
		first_name: null,
		last_name: null,
		company_name: null,
		company_id: null,
		role: null,
		is_2fa_enabled: null,
		created_at: null,
	},
) => {
	if (userPilotEnabled) {
		Userpilot.identify(user.id, {
			name: user.first_name + " " + user.last_name,
			email: user.email,
			role: user.role,
			is_2fa_enabled: user.is_2fa_enabled,
			created_at: user.created_at,
			company: {
				id: user.company_id, // Required, used to identify the company
				name: user.company_name,
				company_type: null,
			},
		});
	}
};

export const useReloadUserpilot = () => {
	const location = useLocation();
	useEffect(() => {
		if (userPilotEnabled) {
			setTimeout(() => {
				Userpilot.reload();
				/*if(window.location.pathname === "/"){
          Userpilot.trigger("1686760778vSew1611");
        }*/
			}, 2500);
		}
	}, [location]);
	return Userpilot;
};
