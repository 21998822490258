import UserActionTypes from "./user.type";

const INITIAL_STATE = { is_2fa_enabled: false };

const userReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case UserActionTypes.SET_CURRENT_USER:
			return {
				...action.payload,
				guest: action?.payload?.role === 3 ? true : false,
				has_2fa_verified:
					action?.payload === null || action?.payload?.has_2fa_verified === undefined
						? false
						: state.has_2fa_verified === true
						? state.has_2fa_verified
						: action?.payload?.has_2fa_verified,
			};
		case UserActionTypes.VERIFY_2FA:
			return {
				...state,
				has_2fa_verified: true,
			};
		case UserActionTypes.SET_COMPANY_UPLOAD_LIMIT:
			return {
				...state,
				...action.payload,
			};
		default:
			return state;
	}
};

export default userReducer;
