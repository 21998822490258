// components/Icon/Icon.jsx

import "./Icon.style.scss";

import React from "react";

import { Icons } from "./Icons.d";

/**
  id?: string;
  className?: string;
  icon: IconOptions;
  theme?: "default" | "danger" | "info" | "primary" | "success" | "error" | "warning" | "tooltip";
  viewBox?: string;
  size?: "xs" | "sm" | "md" | "lg";
  title?: string;
  role?: "icon" | "graphic" | "img";
*/

const defaultProps = {
	icon: "circle",
	theme: "default",
	size: "md",
	viewBox: "0 0 24 24",
	role: "icon",
	stroke: 0,
	className: "defaultClass",
	color: "currentColor",
};

const Icon = (props) => {
	props = { ...defaultProps, ...props };

	return (
		<div
			id={props.id}
			className={`icon ${props.theme} ${props.size} ${props.className}`}
			title={props.title}
			data-testid={"Icon"}
			style={{ color: props.color }}
		>
			<svg
				xmlns='http://www.w3.org/2000/svg'
				width='100%'
				height='100%'
				viewBox={props.viewBox}
				stroke='none'
				strokeWidth={props.stroke}
				strokeLinecap='round'
				strokeLinejoin='round'
				fill='currentColor'
				role={props.role}
			>
				{props.title && <title>{props.title}</title>}
				{Icons[props.icon || "empty"]}
			</svg>
		</div>
	);
};

export default Icon;
