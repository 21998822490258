import { axiosGet, axiosPost } from "../axios/config";

export const enableTwoFA = async () => {
	const response = await axiosPost(`/api/2fa/enable`);
	return response;
};

export const disableTwoFA = async () => {
	const response = await axiosPost(`/api/2fa/disable`);
	return response;
};

export const sendTwoFA = async (data) => {
	const response = await axiosPost(`/api/2fa`, data);
	return response;
};

export const getUserGoogleSecretKey = async (data) => {
	const response = await axiosGet("/api/retrieve-key", data);
	return response;
};
