const approvedRoutes = [
	{ route: "/business-insights?id=", key: "business_insights" },
	{ route: "/street-insights", key: "street_insights" },
	{ route: "/community-insights", key: "community_insights" },
	{ route: "/score-insights/labor-supply", key: "score_insights" },
	{ route: "/score-insights/labor-cost", key: "score_insights" },
	{ route: "/score-insights/labor-migration", key: "score_insights" },
	{ route: "/score-insights/sustainability", key: "score_insights" },
	{ route: "/score-insights/total-market-score", key: "score_insights" },
];

export const isAiEnabled = (state, route) => {
	// console.log("route", route);
	const publicSettings = state.ai.publicSettings;

	// Do we have the settings in redux
	if (!publicSettings) {
		return false;
	}

	// Is it enabled at the system level
	const aiGloballyEnabled = publicSettings.enable_ai === "1";
	if (!aiGloballyEnabled) {
		return false;
	}
	// Is enabled at the org level
	const aiOrganizationEnabled = publicSettings.organization_wide_ai_enabled;
	if (aiOrganizationEnabled === 0) {
		return false;
	}

	// Is the current route on our "approved" list
	const approvedRoute = approvedRoutes.find((ar) => route.startsWith(ar.route));
	if (approvedRoute) {
		const isRouteEnabled = publicSettings[approvedRoute.key] === "1";
		return isRouteEnabled;
	}

	return false;
};
