import "../../pages/login/login.styles.scss";

import QRCode from "qrcode.react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Icon from "../../components/Icon/Icon";
import InputText from "../../components/InputText/InputText";
import { verify2FACode } from "../../redux/user/user.action";
import { getUserGoogleSecretKey } from "../../services/twofactor.service";

function TwoFactorForm() {
	const navigate = useNavigate();

	const user = useSelector((state) => state.user);
	const themeColor = useSelector((state) => state.theme.themeColor);
	const [key, setKey] = useState("Fetching key...");
	const [step, setStep] = useState(1);
	const dispatch = useDispatch();
	const [twoFACode, setTwoFACode] = useState("");

	const handleSubmit = async (event) => {
		dispatch(verify2FACode(twoFACode, navigate));
	};

	const runFetch = async () => {
		const res = await getUserGoogleSecretKey();
		if (res.code === 200) {
			setKey(res.data.key);
		}
	};

	useEffect(() => {
		if (!user.user_confirmed_2fa) {
			runFetch();
		}
	}, [user]);

	const copyToClipboard = (codeToCopy) => {
		if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
			return navigator.clipboard
				.writeText(codeToCopy)
				.then(() => {
					alert("2FA Secret Key copied to clipboard.");
				})
				.catch((error) => {
					console.error("Failed to copy text to clipboard:", error);
				});
		}

		return Promise.reject("The Clipboard API is not available.");
	};

	const googleAuthenticatorUri = `otpauth://totp/Bluechip%20Insights:${user.email}?secret=${key}&issuer=Bluechip%20Insights`;

	useEffect(() => {
		document.body.className = themeColor;
	}, []);

	const content = () => {
		return (
			<>
				{user.user_confirmed_2fa ? (
					<>
						<h4 style={{ fontWeight: "bold" }}>2 Factor Authentication</h4>
						<p>Please enter the code from your authenticator app in the field below</p>
						<form
							onSubmit={(e) => {
								e.preventDefault();
								handleSubmit();
							}}
						>
							<InputText
								srOnlyLabel={"Authenticator Code"}
								placeholder={"e.g. 123456"}
								value={twoFACode}
								onValueChange={(e) => setTwoFACode(e)}
							/>

							<input className='btn primary rounded' type='submit' value='Submit' />
						</form>
					</>
				) : (
					<>
						{step === 1 ? (
							<>
								<h4 style={{ fontWeight: "bold" }}>2 Factor Authentication</h4>
								<p>2 Factor Authentication is required to login.</p>
								<QRCode value={googleAuthenticatorUri} />

								<p>Do not have an Authenticator App?</p>
								<ul aria-label='Google Authenticator'>
									<li>
										<a href='https://apps.apple.com/us/app/google-authenticator/id388497605'>
											Apple
										</a>
									</li>
									<li>
										<a href='https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en_US&gl=US'>
											Google
										</a>
									</li>
								</ul>
								<p>
									<ul aria-label='Microsoft Authenticator'>
										<li>
											<a href='https://apps.apple.com/us/app/microsoft-authenticator/id983156458'>
												Apple
											</a>
										</li>
										<li>
											<a href='https://play.google.com/store/apps/details?id=com.azure.authenticator&hl=en_US&gl=US'>
												Google
											</a>
										</li>
									</ul>
								</p>
								<p>
									<details className='alternatives'>
										<summary>Click here for additional options</summary>

										<li>
											<a
												target='_blank'
												rel='noreferrer'
												href='https://chrome.google.com/webstore/detail/authenticator/bhghoamapcdpbohphigoooaddinpkbai'
											>
												Authenticator for Chrome
											</a>
										</li>
										<li>
											<a
												target='_blank'
												rel='noreferrer'
												href='https://addons.mozilla.org/en-US/firefox/addon/auth-helper/'
											>
												Authenticator for Firefox
											</a>
										</li>
										<li>
											<a
												target='_blank'
												rel='noreferrer'
												href='https://microsoftedge.microsoft.com/addons/detail/authenticator-2fa-client/ocglkepbibnalbgmbachknglpdipeoio'
											>
												Authenticator for Edge
											</a>
										</li>

										<p>
											Please use this secret key with an authenticator application for generating{" "}
											<br></br>time-based one-time passwords.
										</p>
										<p
											style={{ cursor: "pointer" }}
											onClick={() => {
												copyToClipboard(key);
											}}
										>
											<b>
												Secret Key: {key} <Icon icon={"file-copy-line"} viewBox={"0 0 24 24"} />
											</b>
										</p>
									</details>
								</p>
							</>
						) : (
							<>
								<InputText
									label={"Authenticator Code"}
									placeholder={"e.g. 123456"}
									value={twoFACode}
									onValueChange={(e) => setTwoFACode(e)}
								/>
							</>
						)}
						<p>
							{step === 2 && (
								<button
									onClick={() => {
										if (key === twoFACode) {
											alert(
												"You hav entered the Google Secret Key. Please add the Secret Key to your authenticator app and enter the provided code.",
											);
										} else {
											handleSubmit();
										}
									}}
									style={{ marginRight: "10px" }}
									className='btn primary rounded'
								>
									Verify 2FA Code
								</button>
							)}
							<button
								className='btn outline rounded'
								onClick={() => {
									step === 1 ? setStep(2) : setStep(1);
								}}
							>
								{step === 1 ? "Confirm 2 Factor Authentication" : "Back"}
							</button>
						</p>
					</>
				)}
			</>
		);
	};

	return (
		<>
			<section className='login-sec'>
				<div className='bg-pattern'>
					<img src='../assets/images/login-pattern.svg' alt='' />
				</div>
				<div className='container'>
					<div className='row'>
						<div className='col col-12 mx-auto'>
							<div className='login-card-wrapper card' style={{ width: "100%", maxWidth: "700px" }}>
								<div className='card-body text-center'>
									<div className='site-logo-icon'>
										<img
											src={
												themeColor === "dark-theme"
													? "assets/images/bluechip-logo-white.svg"
													: "assets/images/bluechip-logo-black.svg"
											}
											alt=''
										/>
									</div>
									{content()}
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}

export default TwoFactorForm;
