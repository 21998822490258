// components/InputNumber/InputNumber.jsx

import "./InputNumber.style.scss";

import React, { useState } from "react";

import { randomString } from "../../helper/string.helpers";
import Icon from "../Icon/Icon";
import InputWrapper from "../InputWrapper/InputWrapper";

/**
  id?: string;
  label?: string;
  srOnlyLabel?: boolean;
  moreInfo?: string;
  footnote?: string;
  size?: "md" | "sm";
  theme?: "light" | "dark";
  required?: boolean;
  invalid?: boolean;
  invalidMessage?: string;
  prefixIcon?: IconOptions;
  suffixIcon?: IconOptions;
  hideControls?: boolean;
  placeholder?: string;
  autocomplete?: boolean;
  readonly?: boolean;
  disabled?: boolean;
  maxValue?: number;
  minValue?: number;
  value?: number;
  onValueChange: React.Dispatch<React.SetStateAction<InputNumberProps["value"]>>;
  onBlur: React.Dispatch<React.SetStateAction<InputNumberProps["value"]>>;
*/

const defaultProps = {
	invalidMessage: "This is required",
	theme: "light",
	size: "md",
	hideControls: false,
	value: undefined,
	onValueChange: (v) => v,
	onBlur: (v) => v,
};

const InputNumber = (props) => {
	props = { ...defaultProps, ...props };

	const [id] = useState(props.id || randomString());

	const getClasses = () => {
		const classes = ["input-number"];

		// Add theme class
		props.theme && classes.push(`theme-${props.theme}`);

		// Add size class
		props.size && classes.push(`size-${props.size}`);

		// Hide controls
		props.hideControls && classes.push("hide-controls");

		// Add custom class name if provided
		props.className && classes.push(props.className);

		return classes.join(" ");
	};
	const handleChange = (e) => {
		let value = e.target.valueAsNumber;
		if (!value) {
			props.onValueChange(undefined);
			return;
		}
		if (props.maxValue && value > props.maxValue) {
			value = props.maxValue;
			props.onValueChange(value);
			return;
		}
		if (props.minValue !== undefined && value < props.minValue) {
			value = props.minValue;
			props.onValueChange(value);
			return;
		}
		props.onValueChange(value);
	};

	return (
		<InputWrapper
			id={id}
			label={props.label}
			srOnlyLabel={props.srOnlyLabel}
			required={props.required}
			moreInfo={props.moreInfo}
			footnote={props.footnote}
			invalid={props.invalid}
			invalidMessage={props.invalidMessage}
		>
			<div className={getClasses()} data-testid='InputNumber'>
				<input
					type={"number"}
					id={id}
					className={`${props.invalid ? "is-invalid" : ""} ${props.prefixIcon ? "prefix" : ""}`}
					placeholder={props.placeholder}
					readOnly={props.readonly}
					autoComplete={props.autocomplete ? "on" : "off"}
					min={props.minValue}
					max={props.maxValue}
					inputMode='numeric'
					value={props.value}
					onChange={handleChange}
					onBlur={props.onBlur}
				/>

				{props.prefixIcon && (
					<div className='input-prefix-icon'>
						<Icon icon='hash' size='sm' />
					</div>
				)}
			</div>
		</InputWrapper>
	);
};

export default InputNumber;
