/**
 * The only required field is `dashboard`.
 * **Don't send any params that don't have values**
 * The sever fills in the missing fields with defaults.
 * @param formData
 * @returns {{}}
 */
export const formDataToRequestParams = (formData = {}) => {
	const params = {};

	for (let key in formData) {
		switch (key) {
			// as-is
			case "dashboard":
			case "locations":
			case "wageBand":
			case "requestParamsHash":
			case "savedParamsHash":
			case "qualitativeValues":
			case "quantitativeHidden":
			case "qualitativeHidden":
				params[key] = formData[key];
				break;

			// numbers
			case "targetWage":
			case "weightLaborSupply":
			case "weightLaborCost":
			case "weightLaborMigration":
			case "weightLaborSustainability":
			case "driveTime":
				params[key] = Number(formData[key]);
				break;

			// transposed
			case "occupations":
				params.socCodes = formData[key];
				break;

			default:
				break;
		}
	}

	return params;
};

export const requestParamsToFormData = (requestParams) => {
	return {
		dashboard: requestParams.dashboard,
		driveTime: requestParams.driveTime,
		locations: requestParams.locations,
		targetWage: requestParams.targetWage,
		wageBand: requestParams.wageBand,
		weightLaborSupply: requestParams.weightLaborSupply,
		weightLaborCost: requestParams.weightLaborCost,
		weightLaborMigration: requestParams.weightLaborMigration,
		weightLaborSustainability: requestParams.weightLaborSustainability,
		requestParamsHash: requestParams.requestParamsHash,
		savedParamsHash: requestParams.savedParamsHash,
		// transposed
		occupations: requestParams.socCodes,
		qualitativeValues: requestParams.qualitativeValues,
		quantitativeHidden: requestParams.quantitativeHidden,
		qualitativeHidden: requestParams.qualitativeHidden,
	};
};
