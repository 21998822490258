// components/Panel/Panel.tsx

import "./Panel.style.scss";

import React from "react";

/**
 * @typedef {Object} PanelProps
 * @property {string | null} [id] -
 * @property {string | null} [className] -
 * @property {"white" | "gray" | "bordered" | "transparent"} [theme] -
 * @property {"xs" | "sm" | "md" | "lg"} padding -
 * @property children
 * @property {boolean} [well]
 */

/** @type PanelProps */
const defaultProps = {
	theme: "white",
	well: false,
	padding: "md",
};

/**
 * A Panel Component.
 * @param {PanelProps} props
 * @return {JSX.Element}
 * @constructor
 */
const Panel = (props) => {
	props = { ...defaultProps, ...props };

	return (
		<div
			className={`panel ${props.theme}${props.well ? " well" : ""} padding-${props.padding} ${props.className}`}
			id={props.id}
			data-testid='Panel'
		>
			<div className={"panel-inner"}>{props.children}</div>
		</div>
	);
};

export default Panel;
