const MicroSiteScoreTypes = {
	// Labor-Supply
	SET_LABOR_WEIGHT: "SET_LABOR_WEIGHT",
	SET_SEGMENTATION_WEIGHT: "SET_SEGMENTATION_WEIGHT",

	// Labor competition
	SET_LABOR_SHIFT_WEIGHTING: "SET_LABOR_SHIFT_WEIGHTING",

	// population Coverage
	SET_LOCATION_DATA: "SET_LOCATION_DATA",
	SET_LOCATION: "SET_LOCATION",
	SET_LOCATION_ID: "SET_LOCATION_ID",

	// labor cost
	SET_PERCENTILEWAGE: "SET_PERCENTILEWAGE",
	SET_PERCENTILEWAGENAME: "SET_PERCENTILEWAGENAME",
	SET_COSTWEIGHTING: "SET_COSTWEIGHTING",
	SET_OPTION_PERCENTILEWAGE: "SET_OPTION_PERCENTILEWAGE",

	// SCORE
	SET_SCORE_OPTION: "SET_SCORE_OPTION",
	SET_SELECTED_ITEM_FOR_SCORE: "SET_SELECTED_ITEM_FOR_SCORE",

	// coverage-zone-demographics
	SET_OPTION_FOR_DEMOGRAPHICS: "SET_OPTION_FOR_DEMOGRAPHICS",
	SET_SELECTED_ITEM_FOR_DEMOGRAPHICS: "SET_SELECTED_ITEM_FOR_DEMOGRAPHICS",

	// coverage-zone-demographics
	SET_OPTION_FOR_SEGMENTATION: "SET_OPTION_FOR_SEGMENTATION",
	SET_SELECTED_ITEM_FOR_SEGMENTATION: "SET_SELECTED_ITEM_FOR_SEGMENTATION",

	// Labor-SCORE

	SET_OCCUPATION_FOR_LABORSCORE: "SET_OCCUPATION_FOR_LABORSCORE",

	SET_OCCUPATION_OPTION_FOR_LABORSCORE: "SET_OCCUPATION_OPTION_FOR_LABORSCORE",

	SET_DRIVETIME_FOR_LABORSCORE: "SET_DRIVETIME_FOR_LABORSCORE",

	SET_DRIVETIME_OPTION_FOR_LABORSCORE: "SET_DRIVETIME_OPTION_FOR_LABORSCORE ",

	SET_MARKET_FOR_LABORSCORE: "SET_MARKET_FOR_LABORSCORE",

	SET_MARKET_OPTION_FOR_LABORSCORE: "SET_MARKET_OPTION_FOR_LABORSCORE",

	SET_LABORSUPPLY_FOR_LABORSCORE: "SET_LABORSUPPLY_FOR_LABORSCORE",

	SET_LABORCOST_FOR_LABORSCORE: "SET_LABORCOST_FOR_LABORSCORE",

	SET_LABORSUSTAINABILITY_FOR_LABORSCORE: "SET_LABORSUSTAINABILITY_FOR_LABORSCORE",

	SET_LABORCOMPETITION_FOR_LABORSCORE: "SET_LABORCOMPETITION_FOR_LABORSCORE",
};

export default MicroSiteScoreTypes;
