// components/InputToggle/InputToggle.jsx

import "./InputToggle.style.scss";

import React, { useState } from "react";

import { randomString } from "../../helper/string.helpers";

/**
 * Props for a Toggle component.
 * @typedef {Object} ToggleProps
 * @property {string | null} [id]  - The ID of the component.
 * @property {string | null} [className] - The class name of the component.
 * @property {string | null} name - `name` of the input.
 * @property {string | null} label - the label that appears to the left of the toggle.
 * @property {boolean} [srOnlyLabel] - hides the label in the browser.
 * @property {"xs" | "sm" | "md" | "lg"} [size] -
 * @property {"light" | "dark"} [theme] - changes the `theme` of the toggle for light and dark mode.
 * @property {boolean} [disabled] -
 * @property {string | null} [disabledMessage] - a tooltip that appears when you hover over a disabled Toggle
 * @property {boolean} isChecked - Checked state for the component
 * @property {CallableFunction} onValueChange -
 * @property {string | null} [trueLabel] -
 * @property {string | null} [falseLabel] -
 * @property {string | null} [trueColor] -
 * @property {string | null} [falseColor] -
 */

/** @type {ToggleProps} */
const defaultProps = {
	id: null,
	className: null,
	name: null,
	label: null,
	srOnlyLabel: false,
	size: "md",
	disabled: false,
	disabledMessage: null,
	trueLabel: "",
	falseLabel: "",
	trueColor: null,
	falseColor: null,
	isChecked: false,
	labelClass: "",
	footNote: "",
	onValueChange: (v) => v,
};

/**
 * A Toggle Component
 * @param {ToggleProps} props
 * @return {JSX.Element}
 * @constructor
 */
const InputToggle = (props) => {
	props = { ...defaultProps, ...props };

	const [id] = useState(props.id || randomString());

	const handleChange = (e) => {
		props.onValueChange(e);
	};

	// set toggle styles for true and false colors
	let toggleStyle = {};
	if (props.trueColor) {
		toggleStyle["--toggleColorTrue"] = props.trueColor;
	}
	if (props.falseColor) {
		toggleStyle["--toggleColorFalse"] = props.falseColor;
	}

	const getClasses = () => {
		const classes = ["input-toggle"];

		// ADD CUSTOM CLASS NAME
		props.className && classes.push(`${props.className}`);

		// ADD THEME CLASS
		classes.push(`theme-${props.theme}`);

		// ADD SIZE CLASS
		classes.push(`size-${props.size}`);

		// ADD DISABLED CLASS
		if (props.disabled === true) classes.push("disabled");

		return classes.toString().replace(/,/g, " ");
	};

	return (
		<>
			<div
				className={getClasses()}
				title={props.disabled ? props.disabledMessage : null}
				style={toggleStyle}
				data-testid='InputToggle'
			>
				<input
					type={"checkbox"}
					id={id}
					name={props.name}
					disabled={props.disabled}
					checked={props.isChecked}
					onChange={handleChange}
					className={props.className ?? ""}
				/>

				<label className={props.labelClass} htmlFor={id}>
					<span className='toggle' aria-hidden='true'>
						<span
							className='toggle-inner'
							data-label-true={props.trueLabel}
							data-label-false={props.falseLabel}
						></span>
						<span className='toggle-switch'></span>
					</span>
					<span className={`toggle-label ${props.srOnlyLabel ? "sr-only" : ""}`}>{props.label}</span>
				</label>
			</div>
			<div className='input-footnote' data-testid='InputFootnote'>
				{props.footNote}
			</div>
		</>
	);
};

export default InputToggle;
