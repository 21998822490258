// InputFootnote.jsx

import "./InputFootnote.style.scss";

import React from "react";

/**
 * Props for a InputFootnote component.
 * @typedef  {Object} FootnoteProps
 * @property {JSX.Element | JSX.Element[] | string | string[]} [children] -
 */

/**
 * An InputFootnote Component
 * @param {FootnoteProps} props
 * @return {JSX.Element}
 * @constructor
 */
const InputFootnote = (props) => {
	return (
		<div className='input-footnote' data-testid='InputFootnote'>
			{props.children}
		</div>
	);
};

export default InputFootnote;
