import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Icon from "../../../components/Icon/Icon";
import Tooltip from "../../../components/tooltip/tooltip";
import InputMultiSelect from "../../../components/InputMultiSelect/InputMultiSelect";
import useFormData from "../hooks/useFormData";
import { trackEvent } from "../../../hooks/useMixpanel";

function socCodesToOptions(socCodes) {
	return socCodes.map((item) => ({
		label: item.soc_name,
		value: item.soc_code,
	}));
}

function selectedSocCodesToLocalValue(socCodes, options) {
	const localValue = socCodes.reduce((acc, val) => {
		const found = options.find((option) => option.value === val);
		if (found) acc.push(found);
		return acc;
	}, []);

	return localValue.length > 0 ? localValue : options;
}

/**
 * @param onValueChange
 * @returns {JSX.Element}
 * @constructor
 */
function OccupationsSelect({ onValueChange, includesIcon }) {
	const { currentDashboard } = useSelector((state) => state.scoreInsightsDashboard);
	const { responseFormData } = useFormData();
	const [options, setOptions] = useState([]);
	// Local value is a list of objects with label and value
	// External form value is a list of soc codes
	const [localValue, setLocalValue] = useState([]);

	// options
	useEffect(() => {
		const options = socCodesToOptions(currentDashboard?.soc ?? []);
		setOptions(options);
	}, [currentDashboard]);

	// The dashboardData response includes requested occupations,
	// the source of truth for occupation selections
	useEffect(() => {
		if (!responseFormData?.occupations) return;
		const selected = selectedSocCodesToLocalValue(responseFormData.occupations, options);
		setLocalValue(selected);
	}, [responseFormData, options]);

	return (
		<div style={{ display: "flex" }}>
			<InputMultiSelect
				includesIcon={includesIcon}
				label={"Occupations"}
				labelledBy={"Select occupations"}
				options={options}
				overrideStrings={{
					"allItemsAreSelected": "All occupations",
					"selectAll": "All occupations",
					"selectSomeItems": "Select an occupation",
				}}
				value={localValue}
				onValueChange={(occupations) => {
					onValueChange(occupations.map((item) => item.value));
					setLocalValue(occupations);

					const selected = options.filter((option) => occupations.indexOf(option) > 0);
					trackEvent("Click", {
						"Location": "Filter Menu",
						"Dashboard": "Score Insights",
						"Sub-Dashboard": currentDashboard?.name,
						"Item": "Occupation Sites",
						"SOC": selected.map((item) => item.value).join(","),
						"Action": "Toggle On/Off",
					});
				}}
			/>
		</div>
	);
}

export default OccupationsSelect;
