import StreetInsightsType from "./street-insights.type";

const INITIAL_STATE = {
	selectedLocation: {},
	locationHistory: [],
};

const StreetInsightsReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case StreetInsightsType.SET_SELECTED_LOCATION:
			return { ...state, selectedLocation: action.payload };
		case StreetInsightsType.SET_LOCATION_HISTORY:
			return { ...state, locationHistory: action.payload };
		default:
			return state;
	}
};

export default StreetInsightsReducer;
