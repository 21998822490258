/**

A Checkbox component used to collect boolean values.
@param {Object} props - The props for the component.
@param {string} [props.id] - The ID for the checkbox.
@param {string} [props.label] - The text to appear next to the checkbox.
@param {boolean} [props.invalid] - A flag to indicate if the input is invalid.
@param {boolean} [props.disabled=false] - A flag to indicate if the input is disabled.
@param {boolean} [props.isChecked=false] - A flag to indicate if the checkbox is checked.
@param {Function} [props.onValueChange=(v) => v] - A callback function to handle changes to the checkbox's value.

*/

import "./InputCheckbox.style.scss";

import React, { useState } from "react";

import { randomString } from "../../helper/string.helpers";

const defaultProps = {
	disabled: false,
	isChecked: false,
	className: "",
	onValueChange: (v) => v,
};

const InputCheckbox = (props) => {
	props = { ...defaultProps, ...props };
	const [id] = useState(props.id || randomString());

	const handleChange = (e) => {
		const { checked } = e.target;
		props.onValueChange && props.onValueChange(checked);
	};

	return (
		<div className={`${props.className} checkbox`}>
			<label htmlFor={id}>{props.label}</label>
			<input
				type='checkbox'
				id={id}
				className={`${props.invalid ? "is-invalid" : ""}`}
				name={id}
				disabled={props.disabled}
				checked={props.isChecked}
				onChange={handleChange}
			/>
		</div>
	);
};

export default InputCheckbox;
