import React, { useEffect, useMemo, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { trackEvent } from "../../../hooks/useMixpanel";

import InputNumber from "../../../components/InputNumber/InputNumber";
import InputRange from "../../../components/InputRange/InputRange";
import useFormData from "../hooks/useFormData";
import Tooltip from "../../../components/tooltip/tooltip";
import Icon from "../../../components/Icon/Icon";
import { debounce } from "lodash";
const HOURLY = "HOURLY";
const YEARLY = "YEARLY";
const MIN_HOURLY = 10;
const MAX_HOURLY = 150;
const MIN_YEARLY = Math.round(MIN_HOURLY * 2.08);
const MAX_YEARLY = Math.round(MAX_HOURLY * 2.08);

function hourlyToYearly(value) {
	const newValue = Math.min(MAX_YEARLY, Math.max(MIN_YEARLY, value * 2.08));
	return parseFloat(newValue.toFixed(2));
}

function yearlyToHourly(value) {
	const newValue = Math.min(MAX_HOURLY, Math.max(MIN_HOURLY, value / 2.08));
	return Math.floor(newValue);
}

function constrain(value, salaryType) {
	const min = salaryType === HOURLY ? MIN_HOURLY : MIN_YEARLY;
	const max = salaryType === HOURLY ? MAX_HOURLY : MAX_YEARLY;
	return Math.min(max, Math.max(min, value));
}

const timeout = debounce((value, currentDashboard) => {
	trackEvent("Click", {
		"Location": "Filter Menu",
		"Dashboard": "Score Insights",
		"Sub-Dashboard": currentDashboard?.name ?? "",
		"Item": "Wage Slider",
		"Item Value": value,
	});
}, 5000);

function TargetWageInput({ onValueChange, includesIcon }) {
	const { responseFormData } = useFormData();
	const [salaryType, setSalaryType] = useState(HOURLY);
	const [numberValue, setNumberValue] = useState(responseFormData.targetWage);
	const [rangeValue, setRangeValue] = useState(responseFormData.targetWage);
	const debouceRequest = useCallback((value) => timeout(value), []);
	const currentDashboard = useSelector((state) => state.scoreInsightsDashboard);

	const minValue = useMemo(() => {
		return salaryType === HOURLY ? MIN_HOURLY : MIN_YEARLY;
	}, [salaryType]);

	const maxValue = useMemo(() => {
		return salaryType === "HOURLY" ? MAX_HOURLY : MAX_YEARLY;
	}, [salaryType]);

	// convert value to hourly or yearly when salaryType changes
	useEffect(() => {
		const newValue = salaryType === YEARLY ? hourlyToYearly(numberValue) : yearlyToHourly(numberValue);
		setNumberValue(newValue);
		setRangeValue(newValue);
		// we don't want to trigger this effect when numberValue changes
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [salaryType]);

	// sync numberValue and rangeValue when responseFormData is received
	useEffect(() => {
		let newValue =
			salaryType === YEARLY ? hourlyToYearly(responseFormData.targetWage) : responseFormData.targetWage;
		newValue = constrain(newValue, salaryType);
		setNumberValue(newValue);
		setRangeValue(newValue);
		// we don't want to trigger this effect when salaryType changes
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [responseFormData.targetWage]);

	const handleNumberBlur = (value) => {
		if (value === "") value = 0;
		value = constrain(value, salaryType);
		setNumberValue(value);
		setRangeValue(value);

		const hourly = salaryType === YEARLY ? yearlyToHourly(value) : value;
		onValueChange(hourly);
	};

	const handleNumberChange = (value) => {
		// can be anything because the final value is set on blur
		// `value` is `undefined` if the input is empty
		const inputElementValue = value ?? "";
		setNumberValue(inputElementValue);

		// let the slider respond for visual feedback
		const numberValue = inputElementValue === "" ? 0 : Number(inputElementValue);
		setRangeValue(constrain(numberValue, salaryType));
	};

	const handleRangeChange = (value) => {
		setRangeValue(value);
		setNumberValue(value); // setting the number value will trigger its useEffect that
		const hourly = salaryType === YEARLY ? yearlyToHourly(value) : value;
		onValueChange(hourly);
	};

	return (
		<>
			<div className='input-label'>
				Target Wage
				{includesIcon && (
					<Tooltip tooltipFor='term' description={includesIcon.description}>
						<label htmlFor=''>
							<Icon size={"sm"} icon={includesIcon.icon} />
						</label>
					</Tooltip>
				)}
			</div>

			<div className='target-wage-wrapper'>
				<span className='input-prefix'>$</span>

				<InputNumber
					type={"number"}
					hideControls={true}
					theme={"no-outline"}
					value={numberValue}
					onValueChange={(value) => {
						handleNumberChange(value);

						trackEvent("Click", {
							"Location": "Filter Menu",
							"Dashboard": "Score Insights",
							"Sub-Dashboard": currentDashboard?.name ?? "",
							"Item": "Wage Box",
							"Item Value": value,
						});
					}}
					onBlur={(e) => handleNumberBlur(e.target.value)}
				/>
				<span className='input-suffix'>/ {salaryType === HOURLY ? "HR" : "YR"}</span>
			</div>
			<InputRange
				formatLabel={(label) => `$${label}${salaryType === HOURLY ? "/hr" : "/k"}`}
				minValue={minValue}
				maxValue={maxValue}
				step={1}
				value={rangeValue}
				onValueChange={(value) => {
					handleRangeChange(value, currentDashboard);

					// Debounce event tracking. We don't want to track every single change.
					debouceRequest(value);
				}}
			/>
			<div className='wage-wrapper'>
				<button
					className={`${
						salaryType === HOURLY ? "active" : "inactive"
					} btn theme-primary size-md width-standard btn-round`}
					onClick={() => {
						setSalaryType(HOURLY);

						trackEvent("Click", {
							"Location": "Filter Menu",
							"Dashboard": "Score Insights",
							"Sub-Dashboard": currentDashboard?.name ?? "",
							"Item": "Wage Type",
							"Item Value": "Hourly",
						});
					}}
					disabled={salaryType === HOURLY}
				>
					{salaryType === HOURLY && (
						<svg width='13' height='10' viewBox='0 0 13 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
							<path
								d='M12.9199 1.71004L11.5099 0.290039L4.91986 6.88004L2.33986 4.31004L0.919861 5.72004L4.91986 9.71004L12.9199 1.71004Z'
								fill='white'
							/>
						</svg>
					)}{" "}
					Hour
				</button>
				<button
					className={`${
						salaryType === YEARLY ? "active" : "inactive"
					} btn theme-primary size-md width-standard btn-round`}
					onClick={() => {
						setSalaryType(YEARLY);

						trackEvent("Click", {
							"Location": "Filter Menu",
							"Dashboard": "Score Insights",
							"Sub-Dashboard": currentDashboard?.name ?? "",
							"Item": "Wage Type",
							"Item Value": "Yearly",
						});
					}}
					disabled={salaryType === YEARLY}
				>
					{salaryType === YEARLY && (
						<svg width='13' height='10' viewBox='0 0 13 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
							<path
								d='M12.9199 1.71004L11.5099 0.290039L4.91986 6.88004L2.33986 4.31004L0.919861 5.72004L4.91986 9.71004L12.9199 1.71004Z'
								fill='white'
							/>
						</svg>
					)}{" "}
					Annual
				</button>
			</div>
		</>
	);
}

export default TargetWageInput;
