import "./tooltip.scss";

import React, { useState } from "react";

function Tooltip(props) {
	let timeout;
	const [active, setActive] = useState(false);

	const showTip = () => {
		timeout = setTimeout(() => {
			setActive(true);
		}, props.delay || 400);
	};

	const hideTip = () => {
		clearInterval(timeout);
		setActive(false);
	};

	return (
		<div
			className={`Tooltip-Wrapper ${props.class && props.class}`}
			onClick={() =>
				props.tooltipFor === "term"
					? ""
					: props.activeIndex === null
					? props.index2 !== undefined
						? props.setActiveIndex([props.index, props.index2])
						: props.setActiveIndex(props.index)
					: props.setActiveIndex(null)
			}
			style={props.style && props.style}
			// When to show the tooltip
			onMouseEnter={showTip}
			onMouseLeave={hideTip}
		>
			{/* Wrapping */}
			{props.children}

			{active &&
				(props?.tooltipFor === "amazon" ? (
					<div className='Tooltip-Tip'>
						{props?.subLabel ? <div className='tooltip-group-name'>{props.subLabel}</div> : null}
						<div className='tooltip-group-name'>{props.group && props.group}</div>
						<div className='tooltip-kwy-value-wrap'>
							<span>Number Of Employee :</span>
							<span>{props.employee && props.employee}</span>
						</div>
						<div className='tooltip-kwy-value-wrap'>
							<span>Mosaic Link :</span>
							<span>{props.url ? props.url : "*"}</span>
						</div>
					</div>
				) : props?.tooltipFor === "term" ? (
					<div className='Tooltip-Tip'>
						<div className='tooltip-group-name'>{props.description && props.description}</div>
					</div>
				) : (
					<div className='Tooltip-Tip'>
						<div className='tooltip-title'>{props.status && props.status}</div>
						<div className='tooltip-group-name'>{props.group && props.group}</div>
						<div className='tooltip-kwy-value-wrap'>
							<span>Employee Count :</span>
							<span>{props.employee && props.employee}</span>
						</div>
						<div className='tooltip-kwy-value-wrap'>
							<span>% of Total :</span>
							<span>{props.percentage && props.percentage.toFixed(2)}</span>
						</div>
						<div className='tooltip-kwy-value-wrap'>
							<span>Average Term :</span>
							<span>{props.avg_term && props.avg_term.toFixed(2)}</span>
						</div>
					</div>
				))}
		</div>
	);
}

export default Tooltip;
