import NationalinsightsType from "./national-insights.type";

// Labor-Supply

export const setSelectedStateCount = (data) => ({
	type: NationalinsightsType.SET_SELECTED_STATE_COUNT,
	payload: data,
});

export const setCompareToggle = (data) => ({
	type: NationalinsightsType.SET_COMPARE_TOGGLE,
	payload: data,
});

export const setIntroModelToggle = (data) => ({
	type: NationalinsightsType.SET_INTRO_MODEL,
	payload: data,
});
