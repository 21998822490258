import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	open: false,
	showIcon: false,
	publicSettings: null,
};

export const aiSlice = createSlice({
	name: "ai",
	initialState,
	reducers: {
		update: (state, action) => {
			state.open = action.payload;
		},
		show: (state, action) => {
			// console.log("ai--showIcon", action.payload);
			state.showIcon = action.payload;
		},
		updatePublicSettings: (state, action) => {
			state.publicSettings = action.payload;
		},
	},
});

export const { update, show, updatePublicSettings } = aiSlice.actions;

export default aiSlice.reducer;
