import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import Button from "../../../components/Button/Button";
import ButtonRow from "../../../components/ButtonRow/ButtonRow";
import Icon from "../../../components/Icon/Icon";
import InputNumber from "../../../components/InputNumber/InputNumber";
import InputRadioPillGroup from "../../../components/InputRadioPillGroup/InputRadioPillGroup";
import InputRange from "../../../components/InputRange/InputRange";
import InputSelect from "../../../components/InputSelect/InputSelect";
import Panel from "../../../components/Panel/Panel";
import ProgressBar from "../../../components/ProgressBar/ProgressBar";
import Tooltip from "../../../components/tooltip/tooltip";
import { trackEvent } from "../../../hooks/useMixpanel";
import LocationsSelect from "../components/LocationsSelect";
import OccupationsSelect from "../components/OccupationsSelect";
import TargetWageInput from "../components/TargetWageInput";
import useFormData from "../hooks/useFormData";

const WAGE_BAND_OPTIONS = [
	{ label: "10% Hourly Earnings", value: "10%" },
	{ label: "25% Hourly Earnings", value: "25%" },
	{ label: "Avg. Hourly Earnings", value: "avg" },
	{ label: "75% Hourly Earnings", value: "75%" },
	{ label: "90% Hourly Earnings", value: "90%" },
];

/**
 * The server returns form params along with the dashboard data.
 * The server-returned form state is the source of truth at the moment new dashboard data
 * is received, and the local form state is updated with that.
 *
 * On initial render, the form state will be one of these in order of precedence:
 * - The form state that was persisted in local storage (sent with the initial request)
 * - The user-saved form state from the database (if no local storage data exists)
 * - The initial default form state (server-side default)
 *
 * @returns {JSX.Element|null}
 * @constructor
 */
function FilterForm() {
	const { isReady } = useFormData();

	// `formData` is initially set by the response from the server.
	// Don't render the form until it is available.
	return isReady ? <FilterFormInner /> : null;
}

function FilterFormInner() {
	const { formData, setFormData, isFormDirty, submitForm } = useFormData();

	// Conditionally set the active state of the nav links by route.
	const location = useLocation();
	const pathSegments = location.pathname.split("/");
	const isTotalMarketScore = pathSegments.includes("total-market-score");
	const isLaborSupply = pathSegments.includes("labor-supply");
	const isLaborCost = pathSegments.includes("labor-cost");
	const isLaborMigration = pathSegments.includes("labor-migration");
	const isLaborSustainability = pathSegments.includes("labor-sustainability");
	const isCostSummary = pathSegments.includes("labor-cost-summary");
	const { currentDashboard } = useSelector((state) => state.scoreInsightsDashboard);

	const weightTotal = () => {
		return (
			formData.weightLaborSupply +
			formData.weightLaborMigration +
			formData.weightLaborCost +
			formData.weightLaborSustainability
		);
	};

	return (
		<form className={"score-insights-form-column-inner"}>
			<h6 className={"sidebar-section-header"}>Filters</h6>

			{/* ALL DASHBOARDS */}
			<div>
				{/* The location and occupation selects handle their own state to prevent bugs */}
				<LocationsSelect
					includesIcon={{
						description: "Select/deselect all locations to be compared and scored for all dashboards.",
						icon: "information-line",
					}}
					onValueChange={(locations) => {
						setFormData({ ...formData, locations });
					}}
				/>

				<OccupationsSelect
					includesIcon={{
						description: "Select/deselect all occupations to be compared and scored for all dashboards.",
						icon: "information-line",
					}}
					onValueChange={(occupations) => {
						setFormData({ ...formData, occupations });
					}}
				/>

				<InputRadioPillGroup
					id={"travel-time-radio-group-input"}
					label={"DRIVETIME (MINUTES)"}
					includeActiveCheck={true}
					includesIcon={{
						description:
							"Adjust the drive time to change the amount of data is calculated from each location. Ex. 15 minute drive time will calculate all block groups that are reachable within 15 minutes of that location.",
						icon: "information-line",
					}}
					options={[
						{ label: "15", value: 15 },
						{ label: "30", value: 30 },
						{ label: "45", value: 45 },
					]}
					value={formData.driveTime}
					onValueChange={(e) => {
						setFormData({ ...formData, driveTime: e });

						trackEvent("Click", {
							"Location": "Filter Menu",
							"Dashboard": "Score Insights",
							"Sub-Dashboard": currentDashboard?.name ?? "",
							"Item": "Drivetime Button",
							"Item Value": e,
						});
					}}
				/>
			</div>

			{/* LABOR SUPPLY || LABOR MIGRATION */}
			<div
				className='control'
				style={{ display: isTotalMarketScore || isLaborSupply || isLaborMigration ? "block" : "none" }}
			>
				<TargetWageInput
					includesIcon={{
						description:
							"Change the target wage for the selected occupations. If more than 1 occupation is selected, all the occupations and their wages will be averaged together for each location",
						icon: "information-line",
					}}
					onValueChange={(e) => setFormData({ ...formData, targetWage: e })}
				/>
			</div>

			{/* LABOR COST */}
			<div className='control' style={{ display: isLaborCost ? "block" : "none" }}>
				<InputSelect
					label={"Wage Band"}
					includesIcon={{
						description:
							"Change the wage band to see calculate what wage is needed to for that percentile in that location. E.G. a wage band of 75% shows what the 75 percentile is in that location.",
						icon: "information-line",
					}}
					theme={"gray-outline"}
					options={WAGE_BAND_OPTIONS}
					value={formData.wageBand}
					onValueChange={(e) => setFormData({ ...formData, wageBand: e })}
				/>
			</div>

			{/* LABOR SUSTAINABILITY */}
			<div className='control' style={{ display: isLaborSustainability ? "block" : "none" }}>
				{/* EMPTY */}
			</div>

			{/* COST SUMMARY */}
			<div className='control' style={{ display: isCostSummary ? "block" : "none" }}>
				<InputSelect
					label={"Wage Band"}
					theme={"gray-outline"}
					options={WAGE_BAND_OPTIONS}
					value={formData.wageBand}
					onValueChange={(e) => setFormData({ ...formData, wageBand: e })}
				/>

				<InputNumber
					label={"Warehouse FTEs"}
					value={formData.etf}
					onValueChange={(e) => setFormData({ ...formData, etf: e })}
				/>
			</div>

			{/* TOTAL MARKET SCORE */}
			<div className='control' style={{ display: isTotalMarketScore ? "block" : "none" }}>
				<h6 style={{ marginTop: "20px" }} className={"sidebar-section-header"}>
					Weighting
					<Tooltip
						tooltipFor='term'
						description={
							"Adjust the weights to change how the different scores are calculated. Weights are only used on the Total Market Score/Executive Summary dashboard."
						}
					>
						<label htmlFor=''>
							<Icon size={"sm"} icon={"information-line"} />
						</label>
					</Tooltip>
				</h6>
				<p className={"sub-title"}>Total sum of the weights/scores must add up to 100%</p>

				<InputRange
					label={"Labor Supply"}
					includeNestedInput={true}
					labelDescriptor={"%"}
					formatLabel={(label) => `${label}`}
					minValue={0}
					maxValue={100}
					step={1}
					value={formData.weightLaborSupply}
					onValueChange={(e) => setFormData({ ...formData, weightLaborSupply: e })}
				/>

				<InputRange
					label={"Labor Migration"}
					includeNestedInput={true}
					labelDescriptor={"%"}
					formatLabel={(label) => `${label}`}
					minValue={0}
					maxValue={100}
					step={1}
					value={formData.weightLaborMigration}
					onValueChange={(e) => {
						setFormData({ ...formData, weightLaborMigration: e });
					}}
				/>

				<InputRange
					label={"Labor Cost"}
					includeNestedInput={true}
					labelDescriptor={"%"}
					formatLabel={(label) => `${label}`}
					minValue={0}
					maxValue={100}
					step={1}
					value={formData.weightLaborCost}
					onValueChange={(e) => setFormData({ ...formData, weightLaborCost: e })}
				/>

				<InputRange
					label={"Labor Sustainability"}
					includeNestedInput={true}
					labelDescriptor={"%"}
					formatLabel={(label) => `${label}`}
					minValue={0}
					maxValue={100}
					step={1}
					value={formData.weightLaborSustainability}
					onValueChange={(e) => setFormData({ ...formData, weightLaborSustainability: e })}
				/>
			</div>

			<div className={"flex-grow-1"}></div>

			<div className={"sidebar-footer"}>
				<Panel theme={"gray"}>
					<div
						style={{
							display: isTotalMarketScore ? "block" : "none",
						}}
					>
						<ProgressBar showPercent size={"sm"} progress={weightTotal()} />
					</div>
					<ButtonRow align={"center"}>
						<Button
							theme={"primary"}
							disabled={weightTotal() !== 100 || !isFormDirty}
							disabledMessage={"Weight total must equal 100%"}
							onClick={() => submitForm()}
						>
							Apply
						</Button>
					</ButtonRow>
				</Panel>
			</div>
		</form>
	);
}

export default FilterForm;
