// components/Button/Button.jsx

import "./Button.style.scss";

import React from "react";

import Icon from "../Icon/Icon";

/**
 * Props for a Button component.
 * @typedef  {Object} ButtonProps
 * @property {string | null} [id]  - The ID of the component.
 * @property {string | null} [className] - The class name of the component.
 * @property {boolean} [active] - Adds the `active` class to the button. For use with buttons with state.
 * @property {"default" | "primary" | "primary-outline"} [theme] - changes the `theme` of the button
 * @property {string | null} [title] - a tooltip that appears when you hover over a NOT disabled Button
 * @property {boolean} [disabled] -
 * @property {string | null} [disabledMessage] - a tooltip that appears when you hover over a disabled Button
 * @property {"button" | "submit" | "reset"} [type] -
 * @property {"xs" | "sm" | "md" | "lg"} [size] -
 * @property {boolean} [rounded] -
 * @property {string} [iconLeft] - an Icon name. Places an Icon before the Button text
 * @property {string} [iconRight] - an Icon name.  Places an Icon after the Button text
 * @property {"block" | "wide" | "standard" | "narrow" | "square" | "flex"} [width] -
 * @property {JSX.Element | JSX.Element[] | string | string[]} [children] -
 */

/** @type {ButtonProps} */
const defaultProps = {
	id: null,
	className: null,
	active: false,
	theme: "default",
	disabled: false,
	disabledMessage: null,
	type: "button",
	size: "md",
	rounded: true,
	width: "standard",
	opacity: 1,
};

/**
 * A Button Component
 * @param {ButtonProps} props
 * @return {JSX.Element}
 * @constructor
 */
const Button = (props) => {
	props = { ...defaultProps, ...props };

	const getClasses = () => {
		const classes = ["btn"];

		// ADD CUSTOM CLASS NAME
		props.className && classes.push(`${props.className}`);

		// ADD THEME CLASS
		classes.push(`theme-${props.theme}`);

		// ADD SIZE CLASS
		classes.push(`size-${props.size}`);

		// ADD WIDTH CLASS
		classes.push(`width-${props.width}`);

		// ADD DISABLED CLASS
		if (props.disabled === true) classes.push("disabled");

		// ADD ROUNDED CLASS
		if (props.rounded === true) classes.push("btn-round");

		// ADD ICON POSITION CLASS
		if ((props.iconLeft || props.iconRight) && props.children === undefined) classes.push("icon");
		if (props.iconLeft) classes.push(`icon-left`);
		if (props.iconRight) classes.push(`icon-right`);

		// ADD ACTIVE CLASS
		if (props.active === true) classes.push("active");

		return classes.toString().replace(/,/g, " ");
	};

	return (
		<button
			style={{ opacity: props.opacity }}
			id={props.id}
			className={getClasses()}
			data-testid='Button'
			type={props.type}
			title={props.disabled ? props.disabledMessage : props.title ? props.title : null}
			disabled={props.disabled}
			onClick={(e) => props.onClick(e)}
		>
			{props.iconLeft && <Icon icon={props.iconLeft} size={props.size} />}
			{props.children}
			{props.iconRight && <Icon icon={props.iconRight} size={props.size} />}
		</button>
	);
};

export default Button;
