import "./sass/index.scss";
import "./sass/dark-theme-style.scss";
import "./mapbox-gl/dist/mapbox-gl.css";

import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { Userpilot } from "userpilot";

import App from "./App";
import { persistor, store } from "./redux/store";
import reportWebVitals from "./reportWebVitals";

// Initialize Userpilot
Userpilot.initialize(import.meta.env.VITE_REACT_APP_USERPILOT_TOKEN);

const checkAndStoreCollaborateUrl = () => {
	const url = new URL(window.location.href);

	const collaborationToken = url.searchParams.get("collaborationInvitationToken");

	if (collaborationToken) {
		const redirectUrl = url.pathname + url.search;

		localStorage.setItem("collaborateRedirectUrl_nonPersist", redirectUrl);
	}
};

checkAndStoreCollaborateUrl();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<Provider store={store}>
		<PersistGate persistor={persistor}>
			<App />
		</PersistGate>
	</Provider>,
);

reportWebVitals();
