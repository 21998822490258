import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import adminReducer from "./administration/adminSlice";
import aiReducer from "./ai/aiSlice";
import AmazonReducer from "./amazon/amazon.reducer";
import ciReducer from "./community-insights/communityInsightsSlice";
import laborShedReducer from "./labor-shed/laborshedReducer";
import locationReducer from "./location/locationReducer";
import MicroSiteScoreReducer from "./micro-site-score/micro-site-score.reducer";
import nationalinsightsReducer from "./national-insights/national-insights.reducer";
import scoreInsightsReducer from "./score-insights/scoreInsightsReducer";
import StreetInsightsReducer from "./street-insights/street-insights.reducer";
import siReducer from "./street-insights/streetInsightsSlice";
import themeReducer from "./theme/theme.reducer";
import toastReducer from "./toast.duck";
import tokenReducer from "./token/token.reducer";
import userReducer from "./user/user.reducer";

const persistConfig = {
	key: "esrp",
	storage,
	blacklist: ["microSiteScore", "amazonStore", "scoreInsightsDashboard", "admin", "ci", "si", "ai"],
};

const rootReducer = combineReducers({
	theme: themeReducer,
	user: userReducer,
	token: tokenReducer,
	microSiteScore: MicroSiteScoreReducer,
	amazonStore: AmazonReducer,
	nationalInsights: nationalinsightsReducer,
	streetInsights: StreetInsightsReducer,
	toast: toastReducer,
	location: locationReducer,
	laborShed: laborShedReducer,
	scoreInsightsDashboard: scoreInsightsReducer,
	admin: adminReducer,
	ai: aiReducer,
	ci: ciReducer,
	si: siReducer,
});

export default persistReducer(persistConfig, rootReducer);
