export function convertMarkdownBoldToHtml(inputText) {
	const regex = /(\d+\.\s+)?\*\*(.*?)\*\*/g;

	return inputText.replace(regex, (match, number, boldText) => {
		if (number) {
			return `<b>${number}${boldText}</b>`;
		}
		return `<b>${boldText}</b>`;
	});
}
