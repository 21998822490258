import React, { useState, useEffect } from "react";
import InputText from "../../../../components/InputText/InputText";
import InputCheckbox from "../../../../components/InputCheckbox/InputCheckbox";
import Chip from "../../../../components/Chip/Chip";
import { searchSocCodes } from "../../../../services/soc-code.service";

function AddSocCodeOnet({
	socCodeList = [],
	selectedSocCode = [],
	setSelectedSocCode = () => false,
	handleToggleCheckboxSocCode,
	errorSOCValidation,
	setSelectedSOCCodes = () => false,
	dashboardDataSocs,
	selectedSOCCodes,
	socCodes = [],
	setSocCodes = () => false,
}) {
	const [checkedItems, setCheckedItems] = useState([]);

	useEffect(() => {
		if (Array.isArray(dashboardDataSocs)) {
			const initialCheckedItems = dashboardDataSocs.map((code) => ({
				id: code.id,
				soc_code: code.soc_code,
				soc_name: code.soc_name,
				name: code.name,
				search_term: code.search_term,
			}));
			setCheckedItems(initialCheckedItems);
		}
	}, [dashboardDataSocs]);

	useEffect(() => {
		setCheckedItems(selectedSocCode);
	}, [selectedSocCode]);

	const [socCodeSearchInputValue, setSocCodeSearchInputValue] = useState("");

	const handleInputChange = (newValue) => {
		setSocCodeSearchInputValue(newValue);
	};

	const handleCheckboxChange = (isChecked, socCodeObj) => {
		const { soc_code, title, id, search_term } = socCodeObj;

		setCheckedItems((prevCheckedItems) => {
			let newCheckedItems;
			if (isChecked) {
				// Add the item to the array if it doesn't exist
				if (!prevCheckedItems.some((item) => item.id === id)) {
					newCheckedItems = [...prevCheckedItems, { soc_code, soc_name: title, id, search_term }];
				} else {
					newCheckedItems = prevCheckedItems;
				}
			} else {
				// Remove the item from the array
				newCheckedItems = prevCheckedItems.filter((item) => item.id !== id);
			}

			const updatedSelectedSocCode = newCheckedItems.map((item) => ({
				id: item.id,
				soc_code: item.soc_code,
				soc_name: item.soc_name,
				name: item.soc_name,
				search_term: item.search_term || "",
			}));

			setSelectedSocCode(updatedSelectedSocCode);
			setSelectedSOCCodes(updatedSelectedSocCode);

			return newCheckedItems;
		});
	};

	const handleRemoveChip = (socCodeObj) => {
		handleCheckboxChange(false, socCodeObj);
	};

	const fetchSocCodesBySearch = async (title) => {
		const fetchSocCodes = await searchSocCodes({ title });
		if (fetchSocCodes && fetchSocCodes.data && fetchSocCodes.data.soc_codes) {
			setSocCodes(fetchSocCodes.data.soc_codes);
		}
	};

	return (
		<div className='db-state-table-modal-step-item step-three'>
			<div>
				<div>
					<div style={{ display: "flex", alignItems: "center", gap: "30px" }}>
						<InputText
							className={"job-search"}
							type='text'
							value={socCodeSearchInputValue}
							onValueChange={handleInputChange}
							label=''
							placeholder='Search by Job Title'
						/>
						<button
							style={{ minWidth: "auto" }}
							onClick={() => {
								fetchSocCodesBySearch(socCodeSearchInputValue);
							}}
							className='btn primary'
						>
							Search
						</button>
					</div>
					<div className='chip-list'>
						{selectedSocCode.map((item) => (
							<Chip size='28' key={`chip-${item.soc_code}-${item.soc_name}`} dismissible={true}>
								{item.soc_name || item.name}
								<span
									data-id={`${item.name} ${item.soc_code}`}
									className='remove pointer'
									onClick={() => handleRemoveChip(item)}
								>
									&times;
								</span>
							</Chip>
						))}
					</div>
					<div className='card-content modal-body' style={{ overflow: "hidden" }}>
						<div style={{ marginTop: "30px", height: "500px", overflow: "auto" }} className='checkbox-list'>
							{!socCodes.length && "Enter a search term to look up SOC codes by job title"}
							{socCodes.map((socCodeObj, index) => (
								<InputCheckbox
									key={`list-${socCodeObj.soc_code}-${index}`}
									label={socCodeObj.title}
									isChecked={checkedItems.some((item) => item.id === socCodeObj.id)}
									onValueChange={(isChecked) => handleCheckboxChange(isChecked, socCodeObj)}
								/>
							))}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default AddSocCodeOnet;
