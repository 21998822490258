import React from "react";

function SortAsc() {
	return (
		<g>
			<path d='M19 3L23 8H20V20H18V8H15L19 3ZM14 18V20H3V18H14ZM14 11V13H3V11H14ZM12 4V6H3V4H12Z'></path>
		</g>
	);
}

export default SortAsc;
