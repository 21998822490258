import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

function CSVUpload({ csvFileName, progressFileUpload, errorFileUpload, handleOnChangeFile }) {
	const dropZone = document.getElementsByClassName("upload-area");
	useEffect(() => {
		if (dropZone.length > 0) {
			dropZone[0].addEventListener("dragover", function (e) {
				e.stopPropagation();
				e.preventDefault();
				e.dataTransfer.dropEffect = "copy";
			});

			dropZone[0].addEventListener("drop", function (e) {
				e.stopPropagation();
				e.preventDefault();
				const { files } = e.dataTransfer;
				// console.log("drop", files[0]);
				if (files.length > 0) {
					handleOnChangeFile(files[0]);
				}
			});
		}

		// eslint-disable-next-line
	}, []);

	const maxAllowed = useSelector((state) => state.user.employee_bi_upload_limit);
	return (
		<div className='db-state-table-modal-step-item step-one'>
			<div className='tool-upload-wrapper'>
				<div className='inner'>
					<div className='icon-text'>
						<div className='tool-upload-icon'>
							<img src='/assets/images/dashboard-upload-big-ico.svg' alt='' />
						</div>
						<div className='tool-upload-title'>
							<h4>Upload Files</h4>
							<span className='sub-text'>You may add up to {maxAllowed} employees</span>
							<label className='title'>Files must be in CSV/XLSX format.</label>
						</div>
					</div>
					<div className='upload-area'>
						<input
							type='file'
							accept='.xlsx, .xls, .csv'
							name='file'
							id='file'
							onChange={handleOnChangeFile}
							onClick={(event) => {
								event.target.value = null;
							}}
						/>
						<span>Drag & drop files to upload or</span>
						<u>browse</u>
					</div>
					{csvFileName && (
						<div className='upload-progres-title-wrapper'>
							<div className='upload-progres-title'>
								<div className='upload-icon-text'>
									<img src='/assets/images/csv-file-icon.svg' alt='' />
									<label htmlFor='' className=''>
										{csvFileName}
									</label>
								</div>
								<div className='upload-progres-bar'>
									<div className='progres-bar'>
										<div
											className='inner'
											style={{
												backgroundColor: "#5C86C1",
												width: `${progressFileUpload}%`,
											}}
										/>
									</div>
									{progressFileUpload === 100 ? (
										<span style={{ color: "green" }}>Uploaded</span>
									) : (
										<span>{progressFileUpload}%</span>
									)}
								</div>
								{progressFileUpload === 100 && (
									<div
										className='stop-progress'
										onClick={() => {
											handleOnChangeFile({});
										}}
									>
										<svg
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											xmlns='http://www.w3.org/2000/svg'
										>
											<path
												d='M18 6L6 18'
												stroke='#111128'
												strokeWidth='2'
												strokeLinecap='round'
												strokeLinejoin='round'
											/>
											<path
												d='M6 6L18 18'
												stroke='#111128'
												strokeWidth='2'
												strokeLinecap='round'
												strokeLinejoin='round'
											/>
										</svg>
									</div>
								)}
							</div>
						</div>
					)}
					<div className='demo-text'>
						<Link to='/assets/sample_temp.xlsx' target='_blank' download>
							Click to Download Template
						</Link>
					</div>
					{errorFileUpload && (
						<div className='upload-progres-title-wrapper'>
							<span
								style={{
									color: "#F44336",
								}}
							>
								{`* ${errorFileUpload}`}
							</span>
						</div>
					)}
				</div>
			</div>
		</div>
	);
}

export default CSVUpload;
