import React from "react";
import { Link } from "react-router-dom";

export const footerRef = React.createRef();

function Footer() {
	return (
		<footer className='site-footer' id='SiteFooter' ref={footerRef}>
			<div className='container'>
				<div className='row'>
					<div className='col col-4'>
						<div className='footer-logo-text'>
							<a href='#!'>BLUECHIP INSIGHTS</a>
						</div>
					</div>
					<div className='col col-4'>
						<div className='footer-terms-faq-wrapper'>
							<ul>
								<li>
									<Link to='/policy' className='caption'>
										Terms of Service
									</Link>
								</li>
								{/* <li>
                    <Link to="/faq" class="caption">
                      FAQs
                    </Link>
                  </li> */}
							</ul>
						</div>
					</div>
					<div className='col col-4'>
						<div className='footer-year-text'>
							<label>&copy; {new Date().getFullYear()}</label>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
}

export default Footer;
