import { sortByKey } from "../../helper/array.helpers";
import { requestParamsToFormData } from "../../pages/score-insights/data-transform/formData";
import {
	CLEAR_ALL,
	CLEAR_CURRENT_DASHBOARD,
	CLEAR_LOADING,
	DASHBOARDS_ERROR,
	GET_DASHBOARD_BLOCK_FEATURES,
	GET_DASHBOARDS,
	GET_LOCATION_BLOCK_GROUPS,
	SET_CURRENT_DASHBOARD,
	SET_CURRENT_DASHBOARD_LOADING_STATE,
	SET_DASHBOARD_DATA,
	SET_DASHBOARD_DATA_LOADING_STATE,
	SET_FORM_DATA,
	SET_LOADING,
	SET_RESPONSE_FORM_DATA,
	SORT_DASHBOARDS,
} from "./scoreInsightsTypes";

const initialState = {
	loading: true,
	errors: null,
	dashboards: [],
	currentDashboard: null,
	currentDashboardLoadingState: null,
	currentDashboardId: null,
	dashboardData: null,
	dashboardDataLoadingState: null,
	dashboardBlockProperties: null,
	dashboardBlockGeometries: null,
	locationBlockGroups: null,
	dashboardBlockFeatures: null,
	dashboardLaborSupply: [],
};

const scoreInsightsReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_LOADING: {
			return {
				...state,
				loading: action.payload,
			};
		}
		case CLEAR_LOADING: {
			return {
				...state,
				loading: false,
			};
		}
		case GET_DASHBOARDS:
			return {
				...state,
				dashboards: action.payload,
				errors: null,
				loading: false,
			};
		case SORT_DASHBOARDS:
			return {
				...state,
				dashboards: sortByKey(state.dashboards, action.payload.orderBy, action.payload.sortOrder),
			};
		case SET_CURRENT_DASHBOARD: {
			return {
				...state,
				currentDashboard: action.payload,
				currentDashboardId: action.payload?._id,
				errors: null,
				loading: false,
			};
		}
		case SET_CURRENT_DASHBOARD_LOADING_STATE: {
			// null | 'loading' | 'error'
			return {
				...state,
				currentDashboardLoadingState: action.payload,
			};
		}
		case SET_DASHBOARD_DATA: {
			const requestParams = action.payload?.requestParams ?? null;
			const transformedData = requestParams ? requestParamsToFormData(requestParams) : null;
			return {
				...state,
				dashboardData: action.payload,
				responseFormData: transformedData,
				// sync the form data with the response data so the
				// user knows what they're looking at upon response
				formData: transformedData,
			};
		}
		case SET_RESPONSE_FORM_DATA: {
			return {
				...state,
				responseFormData: action.payload,
			};
		}
		case SET_FORM_DATA: {
			return {
				...state,
				formData: action.payload,
			};
		}
		case SET_DASHBOARD_DATA_LOADING_STATE: {
			// null | 'loading' | 'error'
			return {
				...state,
				dashboardDataLoadingState: action.payload,
			};
		}
		case GET_LOCATION_BLOCK_GROUPS: {
			return {
				...state,
				locationBlockGroups: action.payload,
				loading: false,
			};
		}
		case GET_DASHBOARD_BLOCK_FEATURES: {
			return {
				...state,
				dashboardBlockFeatures: action.payload,
			};
		}
		case DASHBOARDS_ERROR:
			return {
				...state,
				errors: action.payload,
			};
		case CLEAR_CURRENT_DASHBOARD:
			return {
				...state,
				currentDashboard: null,
				dashboardData: null,
				blockProperties: null,
				blockGeometries: null,
			};
		case CLEAR_ALL: {
			return initialState;
		}
		default:
			return state;
	}
};

export default scoreInsightsReducer;
