import { ErrorMessage } from "formik";
import React from "react";

function Textarea(props) {
	const handleFocus = (e) => {
		e.target.classList.add("hasFocus");
	};

	const handleBlur = (e) => {
		if (e.target.value === "") {
			e.target.classList.remove("hasFocus");
		}
	};

	return (
		<div className='input-item'>
			<textarea
				name={props.name}
				className={props.value ? "hasFocus" : ""}
				id={props.id}
				defaultValue={props.value}
				onFocus={handleFocus}
				onBlur={handleBlur}
				onChange={props.handleChange}
				rows='10'
			/>
			<label htmlFor={props.id}>{props.lable}</label>
			{/* <ErrorMessage name={props.name}>
        {(msg) => <span className="fm_error">{msg}</span>}
      </ErrorMessage> */}
		</div>
	);
}

export default Textarea;
