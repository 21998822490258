import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { update } from "../redux/ai/aiSlice";

export function useSetModalOpen() {
	const dispatch = useDispatch();

	const setModalOpen = useCallback(
		(value) => {
			dispatch(update(value));
		},
		[dispatch],
	);

	return setModalOpen;
}
