import { axiosGet, axiosPost } from "../axios/config";

const prefix = "/api/v2/soc-codes/";

export const getAllSocCodes = (data) => {
	return axiosGet(`${prefix}all`, data);
};
export const searchSocCodes = async (data) => {
	const response = await axiosPost(`${prefix}search`, data);
	return response;
};
