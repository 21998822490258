import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { trackEvent } from "../../../hooks/useMixpanel";

import InputMultiSelect from "../../../components/InputMultiSelect/InputMultiSelect";
import useFormData from "../hooks/useFormData";

function locationsToOptions(locations) {
	return locations.map((item) => ({
		label: item.name,
		value: item._id,
	}));
}

function selectedSocCodesToLocalValue(locations, options) {
	const localValue = locations.reduce((acc, val) => {
		const found = options.find((option) => option.value === val);
		if (found) acc.push(found);
		return acc;
	}, []);

	return localValue.length > 0 ? localValue : options;
}

/**
 * @param onValueChange
 * @returns {JSX.Element}
 * @constructor
 */
function LocationsSelect({ onValueChange, includesIcon }) {
	const { currentDashboard } = useSelector((state) => state.scoreInsightsDashboard);
	const { responseFormData } = useFormData();
	const [options, setOptions] = useState([]);
	// Local value is a list of objects with label and value
	// External form value is a list of location ids
	const [localValue, setLocalValue] = useState([]);

	// options
	useEffect(() => {
		const options = locationsToOptions(currentDashboard?.locations ?? []);
		setOptions(options);
	}, [currentDashboard]);

	// The dashboardData response includes requested locations,
	// the source of truth for location selections
	useEffect(() => {
		if (!responseFormData?.locations) return;
		const selected = selectedSocCodesToLocalValue(responseFormData.locations, options);
		setLocalValue(selected);
	}, [responseFormData, options]);

	return (
		<InputMultiSelect
			includesIcon={includesIcon}
			label={"Potential Sites"}
			labelledBy={"Select locations"}
			options={options}
			overrideStrings={{
				"allItemsAreSelected": "All locations",
				"selectAll": "All locations",
				"selectSomeItems": "Select a location",
			}}
			value={localValue}
			onValueChange={(locations) => {
				onValueChange(locations.map((item) => item.value));
				setLocalValue(locations);

				const selected = options.filter((option) => locations.indexOf(option) > 0);
				trackEvent("Click", {
					"Location": "Filter Menu",
					"Dashboard": "Score Insights",
					"Sub-Dashboard": currentDashboard?.name ?? "",
					"Item": "Potential Sites",
					"Potential Site Names": selected.map((item) => item.label).join(", "),
					"Action": "Toggle On/Off",
				});
			}}
		/>
	);
}

export default LocationsSelect;
