const AmazonTypes = {
	// Labor-SCORE

	SET_OCCUPATION_FOR_LABORSCORE: "SET_OCCUPATION_FOR_LABORSCORE",

	SET_OCCUPATION_OPTION_FOR_LABORSCORE: "SET_OCCUPATION_OPTION_FOR_LABORSCORE",

	SET_DRIVETIME_FOR_LABORSCORE: "SET_DRIVETIME_FOR_LABORSCORE",

	SET_DRIVETIME_OPTION_FOR_LABORSCORE: "SET_DRIVETIME_OPTION_FOR_LABORSCORE ",

	SET_MARKET_FOR_LABORSCORE: "SET_MARKET_FOR_LABORSCORE",

	SET_MARKET_OPTION_FOR_LABORSCORE: "SET_MARKET_OPTION_FOR_LABORSCORE",

	SET_LABORSUPPLY_FOR_LABORSCORE: "SET_LABORSUPPLY_FOR_LABORSCORE",

	SET_LABORCOST_FOR_LABORSCORE: "SET_LABORCOST_FOR_LABORSCORE",

	SET_LABORSUSTAINABILITY_FOR_LABORSCORE: "SET_LABORSUSTAINABILITY_FOR_LABORSCORE",

	SET_LABORCOMPETITION_FOR_LABORSCORE: "SET_LABORCOMPETITION_FOR_LABORSCORE",

	// Labor-Supply

	SET_LABORWEIGTH_FOR_LABORSUPPLY: "SET_LABORWEIGTH_FOR_LABORSUPPLY",

	SET_SEGMENTATIONWEITH_FOR_LABORSUPPLY: "SET_SEGMENTATIONWEITH_FOR_LABORSUPPLY",

	// labor cost

	SET_OPTION_PERCENTILE_WAGE_FOR_LABORCOST: "SET_OPTION_PERCENTILE_WAGE_FOR_LABORCOST",

	SET_SELECTED_PERCENTILE_WAGE_FOR_LABORCOST: "SET_SELECTED_PERCENTILE_WAGE_FOR_LABORCOST",

	SET_COST_WEIGTHING_FOR_LABORCOST: "SET_COST_WEIGTHING_FOR_LABORCOST",

	// Labor competition

	SET_LABOR_SHIFTWEIGTHING_FOR_LABORCOMPETITION: "SET_LABOR_SHIFTWEIGTHING_FOR_LABORCOMPETITION",

	// potential employee

	SET_MOSAIC_HOUSEHOLD_FOR_POTENTIALEMPLOYEE: "SET_MOSAIC_HOUSEHOLD_FOR_POTENTIALEMPLOYEE",

	SET_MOSAIC_HOUSEHOLD_OPTION_FOR_POTENTIALEMPLOYEE: "SET_MOSAIC_HOUSEHOLD_OPTION_FOR_POTENTIALEMPLOYEE",
};

export default AmazonTypes;
