// pages/TempMapTest/TempMapTest.jsx

import "./TempMapTest.styles.scss";

import React, { useEffect, useState } from "react";

import InputText from "../../components/InputText/InputText";
import InputToggle from "../../components/InputToggle/InputToggle";
import LocationMarker from "../../components/Map/LocationMarker";
import MapboxMap from "../../components/Map/Map";
import POIMarker from "../../components/Map/POIMarker";
import TravelTimeShape from "../../components/Map/TravelTimeShape";
import Panel from "../../components/Panel/Panel";
import { useTravelTime } from "../../hooks/useTravelTime";
import PointsOfInterestService from "../../services/points-of-interest.service";

function TempMapTest() {
	const [mapConfig, setMapConfig] = useState({
		latitude: 37.7577,
		longitude: -122.4376,
		zoom: 13,
		bearing: 0,
		pitch: 0,
	});

	// CURRENT LOCATION
	const [currentLocation, setCurrentLocation] = useState({
		_id: 123,
		lat: 37.76901702794142,
		lon: -122.42509198475634,
		location_name: "123 Current Location",
	});

	// PROPOSED LOCATIONS
	const [proposedLocations, setProposedLocations] = useState([
		{
			_id: 1,
			lat: 37.76513408321154,
			lon: -122.435752424979,
			location_name: "1101 Flower Mound Road",
			leasing_company: "Stream",
			area_price: 22.5,
			notes: "Great location, bad access",
		},
		{ _id: 2, lat: 37.77330307891839, lon: -122.42323862290478, location_name: "123 San Fran Building" },
	]);
	const [focusedLocation, setFocusedLocation] = useState(null);

	const handleLocationMouseEnter = (id) => {
		setFocusedLocation(id);
	};

	const handleLocationMouseLeave = () => {
		setFocusedLocation(null);
	};

	// SELECTED LOCATION
	const [selectedLocation, setSelectedLocation] = useState(123);
	const [selectedCoordinates, setSelectedCoordinates] = useState(null);

	// POINTS OF INTEREST
	const [POIData, setPOIData] = useState([]);
	const [showPOI, setShowPOI] = useState(false);
	const [POISearchQuery, setPOISearchQuery] = useState({
		radius: 5000,
		type: "restaurant", // https://developers.google.com/maps/documentation/places/web-service/supported_types
		minprice: 0, // 0-4
		maxprice: 4, // 0-4
		keyword: null,
		lat: 37.7621,
		lon: -122.4353,
	});
	const [POILoading, setPOILoading] = useState(false);

	const searchPOI = () => {
		setPOILoading(true);

		PointsOfInterestService.search(POISearchQuery)
			.then((res) => {
				if (res.results.length > 0) {
					setPOIData(res.results);
				} else {
					setPOIData([]);
				}
			})
			.catch((error) => {
				console.log(error);
			})
			.finally(() => {
				setPOILoading(false);
			});
	};

	useEffect(() => {
		searchPOI();
	}, [POISearchQuery]);

	// TRAVEL TIME
	const [travelTime, setTravelTime] = useTravelTime();
	const [showTravelTime, setShowTravelTime] = useState(false);
	const [travelTimeSearchQuery, setTravelTimeSearchQuery] = useState({
		driving: [15, 30, 45],
		public_transport: [],
		walking: [],
	});
	const [travelTimeLoading, setTravelTimeLoading] = useState(false);

	useEffect(() => {
		setTravelTime({ lat: 37.76901702794142, lon: -122.42509198475634 }, travelTimeSearchQuery);
	}, []);

	// ZOOM
	const [mapBounds, setMapBounds] = useState([]);

	useEffect(() => {
		if (showPOI && POIData && POIData.length > 0) {
			/** @type CoordinateArray */
			const POICoordinateArray = [];

			if (POIData) {
				POICoordinateArray.push(
					...POIData.map((poi) => [poi.geometry.location.lng, poi.geometry.location.lat]),
				);
			}

			// if there is more than one set of coordinates in the array update the map.
			if (POICoordinateArray.length > 1) {
				setMapBounds(POICoordinateArray);
			}
		}
	}, [showPOI, POIData]);

	return (
		<div className={"temp-map-test"}>
			<MapboxMap
				viewportConfig={mapConfig}
				loading={POILoading || travelTimeLoading}
				centerOnCoordinates={selectedCoordinates}
				fitToBounds={mapBounds}
			>
				{showPOI && POIData.map((poi, index) => <POIMarker key={index} poi={poi} />)}

				{proposedLocations &&
					proposedLocations.map((location, index) => (
						<LocationMarker
							key={index}
							location={location}
							label={index + 1}
							selectedLocation={selectedLocation}
							focusedLocation={focusedLocation}
						/>
					))}

				<LocationMarker
					location={currentLocation}
					current
					selectedLocation={selectedLocation}
					focusedLocation={focusedLocation}
				/>

				{showTravelTime &&
					travelTime &&
					travelTime.travelTime &&
					travelTime.travelTime.map((shape, index) => <TravelTimeShape key={index} shape={shape} />)}
			</MapboxMap>

			<div className={"floating-sidebar"}>
				<Panel>
					<h6>Current Location</h6>
					<p
						className={`location-label ${currentLocation._id === selectedLocation ? "active" : ""}`}
						onMouseEnter={() => handleLocationMouseEnter(currentLocation._id)}
						onMouseLeave={() => handleLocationMouseLeave()}
						onClick={() => {
							setSelectedLocation(currentLocation._id);
							setSelectedCoordinates({ lat: currentLocation.lat, lon: currentLocation.lon });
						}}
					>
						{currentLocation.location_name}
					</p>

					<h6>Proposed Locations</h6>
					{proposedLocations &&
						proposedLocations.map((location, index) => (
							<p
								key={index}
								className={`location-label ${location._id === selectedLocation ? "active" : ""}`}
								onMouseEnter={() => handleLocationMouseEnter(location._id)}
								onMouseLeave={() => handleLocationMouseLeave()}
								onClick={() => {
									setSelectedLocation(location._id);
									setSelectedCoordinates({ lat: location.lat, lon: location.lon });
								}}
							>
								<b>{index + 1}</b> - {location.location_name}
							</p>
						))}

					<h6>Points of Interest</h6>
					<InputToggle
						label={"Show Points of Interest"}
						isChecked={showPOI}
						onValueChange={(e) => setShowPOI(e.target.checked)}
					/>
					<InputText
						label={"Search Term"}
						placeholder={"e.g. Steakhouse"}
						value={POISearchQuery.keyword}
						onValueChange={(e) => setPOISearchQuery({ ...POISearchQuery, keyword: e })}
					/>

					<h6>Travel Time</h6>
					<InputToggle
						label={"Show Travel Time"}
						isChecked={showTravelTime}
						onValueChange={(e) => setShowTravelTime(e.target.checked)}
					/>
				</Panel>
			</div>
		</div>
	);
}

export default TempMapTest;
