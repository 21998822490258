import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import SelectWithSearchBox from "../../../pages/townson-dashboard-list/components/client-data-upload-component/select-with-search-box";
import SelectBox from "../../control-component/new-select-box.component";
import Textarea from "../../control-component/text-area.component";
import "../../../pages/townson-dashboard-list/townson-dashabord-list.scss";
import Icon from "../../Icon/Icon";
import { checkValueIsPositiveDecimal } from "../../../utils/constants";
import UploadPDFsToLocations from "../../../pages/townson-dashboard-list/components/client-data-upload-component/upload-pdfs-to-locations";
function AddEditLocations({
	dynamicArray,
	proposedLocationIndex,
	setLocation,
	locationDescription,
	setLocationDescription,
	setProposedLocationIndex,
	handleFocus = () => {},
	handleBlur = () => {},
	maxLengthTextArea,
	maxAllowed,
	handleOnChangeFile,
	csvError,
	location,
	error,
	errorMessage,
	handleLocationPDFFiles,
	proposedLocations,
	pdfFileName,
	progressFileUpload,
	errorFileUpload,
	removePDFFile,
	cancelPDFUpload,
	locationPDFFilesUpload,
}) {
	const currentDashboardData = useSelector((state) => state.scoreInsightsDashboard.currentDashboard);

	const [errorLocationValidation, setErrorLocationValidation] = useState([]);

	useEffect(() => {
		if (currentDashboardData && currentDashboardData.locations) {
			const mappedLocations = currentDashboardData.locations.map((location) => ({
				address: { freeformAddress: location.address },
				position: {
					is_current: location.is_current,
					lat: location.lat,
					lon: location.lng,
				},
			}));

			const mappedDescriptions = mappedLocations.map((location) => ({
				is_current: location.position.is_current,
			}));

			setLocation((prevLocation) => ({
				...prevLocation,
				proposedLocations: mappedLocations,
			}));

			setLocationDescription(mappedDescriptions);
		}
	}, [currentDashboardData, setLocation]);

	const handleOnAddressChange = (value, name, index = 0) => {
		console.log("location", location);

		if (errorLocationValidation.find((obj) => obj.type === "proposedLocations")) {
			setErrorLocationValidation([]);
		}
		setLocation((prevLocation) => {
			if (name === "currentLocation") {
				return {
					...prevLocation,
					[name]: value,
				};
			} else if (name === "proposedLocations") {
				const newProposedLocations = [...prevLocation.proposedLocations];
				newProposedLocations[index] = value;
				return {
					...prevLocation,
					proposedLocations: newProposedLocations,
				};
			}
			return prevLocation;
		});
	};

	const handleOnDiscriptionChange = (name, value, index) => {
		setLocationDescription((prevDescription) => {
			const updatedDescription = [...prevDescription];
			const currentItem = { ...updatedDescription[index] };

			const updatedItem = {
				name: currentItem.name || "",
				status: currentItem.status || "",
				area_price: currentItem.area_price || "",
				opex_value: currentItem.opex_value || "",
				leasing_company: currentItem.leasing_company || "",
				is_current: currentItem.is_current || false,
				note: currentItem.note || "",
				[name]: value,
			};

			if (name === "leasing_company") {
				updatedItem.leasing_company = value;
			} else if (name === "is_current") {
				updatedItem.is_current = value;
			}

			updatedDescription[index] = updatedItem;
			return updatedDescription;
		});
	};

	const handleRemoveLocation = (index) => {
		if (index > -1) {
			setLocationDescription((prevDescription) => {
				const newDescription = [...prevDescription];
				newDescription.splice(index, 1);
				return newDescription;
			});

			setLocation((prevLocation) => {
				if (prevLocation && prevLocation.proposedLocations) {
					const newProposedLocations = [...prevLocation.proposedLocations];
					newProposedLocations.splice(index, 1);
					return {
						...prevLocation,
						proposedLocations: newProposedLocations,
					};
				}
				return prevLocation;
			});

			setProposedLocationIndex((prevIndex) => prevIndex - 1);
		}
	};

	return (
		<>
			<div class='card-title'>
				<label style={{ marginRight: 0 }} for=''>
					Locations
				</label>
				<span class='sub-text'>You may add up to {maxAllowed} locations</span>
			</div>
			{error && (
				<span className='sub-text' style={{ color: "red" }}>
					*{errorMessage}
				</span>
			)}
			<div className='form-row show-all'>
				{dynamicArray?.map((item, index) => {
					if (proposedLocationIndex > index || (proposedLocationIndex === 0 && index === 0)) {
						return (
							<div className='group-field' key={index}>
								{index > 0 && (
									<div
										style={{ width: "50px", height: "50px", top: "-26px" }}
										className='icon-remove d-block z-1'
										onClick={() => handleRemoveLocation(index)}
									>
										<Icon
											theme='primary'
											className='pointer svg-fill--primary border-0'
											size='lg'
											icon={"close-circle-line"}
										/>
									</div>
								)}

								<SelectWithSearchBox
									selectedProposedLocation={location.proposedLocations}
									setSelectedItem={(e) => {
										handleOnAddressChange(e, "proposedLocations", index);
									}}
									key={index}
									index={index}
									lableName='Location'
								/>
								<div
									className='input-item'
									style={{
										display: "flex",
										flexDirection: "row",
										alignItems: "center",
									}}
								>
									<input
										type='checkbox'
										name='is_current'
										checked={locationDescription[index] && locationDescription[index].is_current}
										onChange={(e) => {
											handleOnDiscriptionChange("is_current", e?.target?.checked, index);
										}}
										style={{ display: "inline" }}
									/>
									<label
										style={{
											display: "inline-block",
											position: "relative",
											top: "unset",
											left: "unset",
										}}
									>
										Is a Current Location?
									</label>
								</div>
								<div style={{ marginBottom: "10px" }}></div>
								<SelectBox
									selectItem={locationDescription[index] && locationDescription[index]?.status}
									setSelectedItem={(e) => {
										handleOnDiscriptionChange("status", e.name, index);
									}}
									lableName='Select Status'
									options={[
										{
											name: "Existing",
										},
										{
											name: "Under Construction",
										},
										{
											name: "Proposed",
										},
									]}
									searchable={false}
									id='name'
									name='name'
								/>

								<div className='input-item'>
									<input
										className={
											"select-trigger-wrap" +
											(locationDescription[index] && locationDescription[index]?.area_price
												? " hasFocus"
												: "")
										}
										type='text'
										name='area_price'
										onFocus={handleFocus}
										onBlur={handleBlur}
										value={
											locationDescription[index] && locationDescription[index]?.area_price
												? locationDescription[index]?.area_price
												: ""
										}
										onChange={(e) => {
											handleOnDiscriptionChange(
												"area_price",
												checkValueIsPositiveDecimal(e?.target?.value),
												index,
											);
										}}
									/>
									<label className=''>Price per Square Foot (Annually)</label>
								</div>

								<div className='input-item'>
									<input
										className={
											"select-trigger-wrap" +
											(locationDescription[index] && locationDescription[index]?.opex_value
												? " hasFocus"
												: "")
										}
										type='text'
										name='opex_value'
										onFocus={handleFocus}
										onBlur={handleBlur}
										value={
											locationDescription[index] && locationDescription[index]?.opex_value
												? locationDescription[index]?.opex_value
												: ""
										}
										onChange={(e) => {
											handleOnDiscriptionChange(
												"opex_value",
												checkValueIsPositiveDecimal(e?.target?.value),
												index,
											);
										}}
									/>
									<label className=''>Operating Expenses (Annually)</label>
								</div>
								<div className='input-item'>
									<input
										className={
											"select-trigger-wrap" +
											(locationDescription[index] && locationDescription[index]?.leasing_company
												? " hasFocus"
												: "")
										}
										type='text'
										name='leasing_company'
										onFocus={handleFocus}
										onBlur={handleBlur}
										value={
											locationDescription[index] && locationDescription[index]?.leasing_company
												? locationDescription[index]?.leasing_company
												: ""
										}
										onChange={(e) => {
											handleOnDiscriptionChange("leasing_company", e?.target?.value, index);
										}}
									/>
									<label className=''>Leasing Company Name</label>
								</div>

								<div className='input-item-wrap'>
									<Textarea
										name='notes'
										lable='Notes'
										id={index}
										value={
											locationDescription[index] && locationDescription[index]?.note
												? locationDescription[index]?.note
												: ""
										}
										handleChange={(e) => {
											if (e?.target?.value.length <= maxLengthTextArea) {
												handleOnDiscriptionChange("note", e?.target?.value, index);
											}
										}}
									/>
									<em>
										{locationDescription[index]?.note ? locationDescription[index]?.note.length : 0}
										/{maxLengthTextArea.toString()}
									</em>
								</div>

								<div>
									<UploadPDFsToLocations
										index={index}
										handleLocationPDFFiles={handleLocationPDFFiles}
										location={proposedLocations ? proposedLocations[index] : {}}
										pdfFileName={pdfFileName?.filter((files) => files.location_index === index)}
										progressFileUpload={progressFileUpload}
										errorFileUpload={errorFileUpload}
										removePDFFile={removePDFFile}
										cancelPDFUpload={cancelPDFUpload}
									/>
								</div>
							</div>
						);
					}
				})}
				{proposedLocationIndex < maxAllowed && (
					<div className='text-center add-more-filed-btn'>
						<button
							className='btn primary rounded'
							onClick={(e) => {
								e.preventDefault();
								setProposedLocationIndex(proposedLocationIndex + 1);
							}}
						>
							Add New
						</button>
						<div className='upload-btn-wrapper'>
							<div className='upload-area'>
								<input
									type='file'
									name='file'
									id='file'
									onChange={handleOnChangeFile}
									onClick={(event) => {
										event.target.value = null;
									}}
								/>
								<b>Upload csv/xlsx</b>
							</div>
							<span className='fm_error'>
								{csvError.map((data, index) => (
									<React.Fragment key={index}>
										{data}
										<br />
									</React.Fragment>
								))}
							</span>
						</div>
					</div>
				)}
			</div>
		</>
	);
}

export default AddEditLocations;
