import React from "react";

function TvTrayLine() {
	return (
		<g>
			<path
				d='M8.66602 16H13.666V11H8.66602V16ZM8.66602 9H20.666V4H8.66602V9ZM15.666 16H20.666V11H15.666V16ZM8.66602 18C8.11602 18 7.64518 17.8042 7.25352 17.4125C6.86185 17.0208 6.66602 16.55 6.66602 16V4C6.66602 3.45 6.86185 2.97917 7.25352 2.5875C7.64518 2.19583 8.11602 2 8.66602 2H20.666C21.216 2 21.6868 2.19583 22.0785 2.5875C22.4702 2.97917 22.666 3.45 22.666 4V16C22.666 16.55 22.4702 17.0208 22.0785 17.4125C21.6868 17.8042 21.216 18 20.666 18H8.66602ZM4.66602 22C4.11602 22 3.64518 21.8042 3.25352 21.4125C2.86185 21.0208 2.66602 20.55 2.66602 20V6H4.66602V20H18.666V22H4.66602Z'
				fill='#93A3BE'
			/>
		</g>
	);
}

export default TvTrayLine;
