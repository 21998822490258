// services/points-of-interest.service.tsx

import { axiosGet } from "../axios/config";
import { GOOGLE_PLACES_TYPES_WITH_PRICE } from "../constants/google-places.const";

const PointsOfInterestService = {
	resource: "points-of-interest",
	url: "/api/v2/google-maps-proxy",

	/**
	 * Fetch all saved points of interest for a dashboard.
	 * @async
	 * @param {string} id - dashboard id
	 * @returns {Promise} - A promise that resolves to the response data.
	 * @throws {Error} - If the API call fails.
	 */
	getAll: async function (id) {
		try {
			let url = `${PointsOfInterestService.url}/${id}`;

			const response = await axiosGet(url);

			if (response.status < 200 || response.status >= 300) {
				throw new Error(`PointsOfInterestService.getAll: ${response.status} ${response.statusText}`);
			}

			return response;
		} catch (error) {
			console.error(error);
			throw error;
		}
	},

	/**
	 * Save one POI to the dashboard
	 * @async
	 * @param {string} id - dashboard id
	 * @param {{}} poi - Point of Interest to save
	 * @returns {null}
	 */
	post: () => null,

	/**
	 * Searches Google Places for POIs
	 * @param {{ lat, lon, radius, [type], [minprice], [maxprice], [keyword] }} options
	 * @returns {Promise<void>}
	 */
	search: async function (options) {
		/** Remove `minprice` & `maxprice` param if Google Places API doesn't support them with selected `type` */
		if (GOOGLE_PLACES_TYPES_WITH_PRICE.indexOf(options.type) === -1) {
			options.maxprice = undefined;
			options.minprice = undefined;
		}

		try {
			const url = `${PointsOfInterestService.url}/maps/api/place/nearbysearch/json`;
			const params = {
				key: import.meta.env.VITE_REACT_APP_GOOGLE_MAPS_API_KEY,
				location: `${options.lat},${options.lon}`,
				radius: options.radius || 5000, // Search within a 5km radius
				keyword: options.keyword || undefined,
				type: options.type ? options.type : undefined,
				minprice: options.minprice || undefined,
				maxprice: options.maxprice || undefined,
				pagetoken: options.pagetoken || undefined,
				language: "en", // Results in English
			};

			const response = await axiosGet(url, params);

			if (response.status < 200 || response.status >= 300) {
				throw new Error(`PointsOfInterestService.search: ${response.status} ${response.statusText}`);
			}

			return response;
		} catch (error) {
			console.error(error);
			throw error;
		}
	},
};

export default PointsOfInterestService;
