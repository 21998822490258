// components/InputSelect/InputSelect.tsx

import "./InputMultiSelect.style.scss";

import React, { useState } from "react";
import { MultiSelect } from "react-multi-select-component";

import { randomString } from "../../helper/string.helpers";
import InputWrapper from "../InputWrapper/InputWrapper";

/**
 * @typedef {Object} Option
 * @property {string | number} value
 * @property {string} label
 */

/**
 * @typedef {Object} InputSelectProps
 * @property {string | null} [id] - The ID for the input
 * @property {string | null} [className] - Additional Class(s) to add to the Component.
 * @property {string} [label] - The label to appear above the input
 * @property {string} [labelledBy] -
 * @property {boolean} [srOnlyLabel] -
 * @property {string} [moreInfo] -
 * @property {string} [footnote] -
 * @property {"default" | "gray-outline"} [theme] -
 * @property {boolean} [required] -
 * @property {boolean} [invalid] -
 * @property {string} [invalidMessage] -
 * @property {Array<Option>} options -
 * @property {Option[]} [value] -
 * @property {function} onValueChange -
 */

/** @type InputSelectProps */
const defaultProps = {
	theme: "light",
	size: "md",
	allowNull: true,
	nullOptionText: "Choose One",
	options: [],
	value: null,
	includesIcon: false,
	labelClass: "",
};

/**
 * An InputSelect Component.
 * @param {InputSelectProps} props
 * @return {JSX.Element}
 * @constructor
 */
const InputMultiSelect = (props) => {
	props = { ...defaultProps, ...props };

	const [id] = useState(props.id || randomString());

	const getClasses = () => {
		const classes = ["input-multi-select"];

		// ADD THEME CLASS
		props.theme && classes.push(`theme-${props.theme}`);

		return classes.toString().replace(/,/g, " ");
	};

	return (
		<InputWrapper
			includesIcon={props.includesIcon}
			id={id}
			labelClass={props.labelClass}
			theme={props.theme}
			label={props.label}
			srOnlyLabel={props.srOnlyLabel}
			required={props.required}
			moreInfo={props.moreInfo}
			footnote={props.footnote}
			inline={props.inline}
			invalid={props.invalid}
			invalidMessage={props.invalidMessage}
		>
			<div className={getClasses()} data-testid='InputSelect'>
				<MultiSelect
					labelledBy={props.label || props.labelledBy}
					options={props.options}
					overrideStrings={props.overrideStrings}
					value={props.value}
					onChange={(e) => {
						props.onValueChange(e);
					}}
				/>
			</div>
		</InputWrapper>
	);
};

export default InputMultiSelect;
