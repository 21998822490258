import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	message: "",
	data: "",
	type: "",
};

export const toastSlice = createSlice({
	name: "toast",
	initialState,
	reducers: {
		ToastShow: (state, action) => {
			state.message = action.payload.message;
			state.data = action.payload;
			state.type = action.payload.type;
		},
	},
});

export const toastSelector = (state) => {
	return { message: state.toast.message, type: state.toast.type };
};

export const toastError = (state) => {
	const dataError = state.toast?.data?.dataError ? state.toast?.data?.dataError : {};
	const type = state.toast?.type;
	return { data: dataError, type: type };
};

const { actions, reducer } = toastSlice;

export const { ToastShow } = actions;

export default reducer;
