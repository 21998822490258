/* eslint-disable react-hooks/exhaustive-deps */
// hooks/useTravelTime.js

import { useCallback, useEffect, useState } from "react";

import { isValidLatLon } from "../helper/coordinate.helpers";
import { randomString } from "../helper/string.helpers";
import TravelTimeService from "../services/travel-time.service";

/**
 * Accepts a lat and lon value and returns a set of travel time shapes in GeoJson
 * @param {Coordinates} props - lat and lon for the location
 * @param {{ nonce?: boolean, driving?, public_transport?, walking? }} options - some options
 * @returns {[{}, CallableFunction]}
 */
export const useTravelTime = (props = {}, options = {}) => {
	/** {{ lat: number, lon: number }} - associated with the current `travelTime` */
	const [location, setLocation] = useState();

	/** { nonce?: boolean, driving?, public_transport?, walking? } - Options from the previous request */
	const [optionsCache, setOptionsCache] = useState();

	/**
	 * holds a random string
	 * @type {[string, React.Dispatch<React.SetStateAction<string>>]}
	 */
	const [nonce, setNonce] = useState();

	/**
	 * objects for all Travel Time Shapes associated with `location`
	 * @type {[GeoJson[], React.Dispatch<React.SetStateAction<GeoJson[]>>]}
	 */
	const [travelTime, setTravelTime] = useState({});

	/** Initializes the `useTravelTime` hook */
	useEffect(() => {
		if (isValidLatLon(props)) {
			updateState(props, options);
		}
	}, [props, options]);

	/**
	 * Checks to see if parameters have changed. If no change to input, no need for an API call.
	 * @returns {boolean}
	 */
	const isNewRequest = (coordinates, queryOptions) => {
		return coordinates !== location || queryOptions.nonce || queryOptions !== optionsCache;
	};

	/**
	 * Fetches GeoJson from API and updates the state
	 * @param coordinates { {lat: number, lon: number} }
	 * @param {{ nonce?: boolean, driving?, public_transport?, walking? }} queryOptions - some options
	 */
	const updateState = useCallback(
		(coordinates, queryOptions = {}) => {
			if (isNewRequest(coordinates, queryOptions)) {
				setLocation(coordinates);
				setOptionsCache(queryOptions);

				if (queryOptions.nonce) {
					setNonce(randomString());
				}

				if (isValidLatLon(coordinates)) {
					const requestOptions = queryOptions;
					if (nonce) {
						requestOptions[nonce] = nonce;
					}

					TravelTimeService.get(coordinates, requestOptions)
						.then((res) => {
							// console.log(`useTravelTime > TravelTimeService.get > SUCCESS!`);
							if (res && res.code === 200) {
								setTravelTime(res.data);
							}
						})
						.catch((error) => {
							console.error(error);
						});
				} else {
					// reset state
					setLocation({});
					setTravelTime({});
					setNonce(null);
				}
			} else {
				// Sets travelTime to current value.
				setTravelTime(travelTime);
			}
		},
		[nonce, travelTime],
	);

	return [travelTime, updateState];
};
