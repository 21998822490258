import NationalinsightsType from "./national-insights.type";

const INITIAL_STATE = {
	selectedStateCount: 0,
	compareToggle: false,
	introModelToggle: true,
};

const NationalinsightsReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case NationalinsightsType.SET_SELECTED_STATE_COUNT:
			return { ...state, selectedStateCount: action.payload };

		case NationalinsightsType.SET_COMPARE_TOGGLE:
			return { ...state, compareToggle: action.payload };

		case NationalinsightsType.SET_INTRO_MODEL:
			return { ...state, introModelToggle: action.payload };

		default:
			return state;
	}
};

export default NationalinsightsReducer;
