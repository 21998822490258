// components/InputLabel/InputLabel.jsx

import "./InputLabel.style.scss";

import React from "react";

/**
 * Props for a InputLabel component.
 * @typedef  {Object} LabelProps
 * @property {string} for - ID of the input this label is for.
 * @property {boolean} required -
 * @property {boolean} srOnlyLabel - Hides the label in the browser, but not for screen readers.
 * @property {boolean} inline -
 * @property {string} moreInfo - Adds a tooltip after the label.
 * @property {JSX.Element | JSX.Element[] | string | string[]} [children] -
 */

/**
 * An InputLabel Component
 * @param {LabelProps} props
 * @return {JSX.Element}
 * @constructor
 */
const InputLabel = (props) => {
	return (
		<label
			className={`input-label ${props.labelClass} ${props.required && "required"} ${
				props.srOnlyLabel && "sr-only"
			} ${props.inline && "inline"}`}
			htmlFor={props.for}
			data-testid='InputLabel'
		>
			{props.children}
			{/*{props.moreInfo && <Tooltip tip={props.moreInfo} />}*/}
		</label>
	);
};

export default InputLabel;
