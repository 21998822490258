import { isEmpty, isEqual } from "lodash";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { fetchDashboardData } from "../../../redux/score-insights/scoreInsightsActions";
import { SET_FORM_DATA } from "../../../redux/score-insights/scoreInsightsTypes";
import { formDataToRequestParams } from "../data-transform/formData";

const STORAGE_KEY = "ScoreInsightsFormData";

function getInitialRequestParams(dashboardId) {
	let locallyStoredFormData = null;
	try {
		const parsed = JSON.parse(window.localStorage.getItem(STORAGE_KEY));
		if (typeof parsed === "object") {
			locallyStoredFormData = parsed;
		}
	} catch (e) {
		/* noop */
	}

	return locallyStoredFormData?.dashboard === dashboardId
		? formDataToRequestParams(locallyStoredFormData)
		: { dashboard: dashboardId };
}

/**
 * This hook encapsulates concerns related to the form data.
 * Returns:
 * - initialRequestParams: the initial request params for the dashboard data on first load
 * - formData: the current state of the form (changes are not committed to the store until submit)
 * - setFormData: a function to set the form data (does not commit to the store)
 * - patchFormData: a function to patch the form data (does not commit to the store)
 * - use `initialRequestParams` to make the first call for dashboard data
 * - isFormDirty: whether the form has been changed since the last submit (compares formData to responseFormData)
 * - isReady: whether the formData has been set (initially false, then true after the first set)
 * - submitForm: a function to submit the form data to the store and fetch the dashboard data
 */
function useFormData() {
	const { dashboardId } = useParams();
	const dispatch = useDispatch();
	const { formData, responseFormData } = useSelector((state) => state.scoreInsightsDashboard);
	const initialRequestParams = useRef(getInitialRequestParams(dashboardId));

	const isFormDirty = useMemo(() => {
		const a = formDataToRequestParams(formData);
		const b = formDataToRequestParams(responseFormData);
		return !isEmpty(formData) && !isEmpty(responseFormData) && !isEqual(a, b);
	}, [formData, responseFormData]);

	const isFormSaved = useMemo(() => {
		// assume true if no responseFormData - for initial display
		return responseFormData?.savedParamsHash === responseFormData?.requestParamsHash;
	}, [responseFormData]);

	const isReady = useMemo(() => {
		return !isEmpty(responseFormData);
	}, [responseFormData]);

	const persistFormData = useCallback((formData) => {
		window.localStorage.setItem(STORAGE_KEY, JSON.stringify(formData));
	}, []);

	const submitForm = useCallback(() => {
		persistFormData(formData);
		dispatch(fetchDashboardData(formDataToRequestParams(formData)));
	}, [dispatch, persistFormData, formData]);

	const setFormData = useCallback(
		(formData) => {
			dispatch({ type: SET_FORM_DATA, payload: formData });
		},
		[dispatch],
	);

	// Redux actions take care of syncing form data with response form data in global state,
	// but this hook handles local storage persistence, so we'll handle that here
	useEffect(() => {
		if (responseFormData) {
			persistFormData(responseFormData);
		}
	}, [persistFormData, responseFormData]);

	return {
		initialRequestParams: initialRequestParams.current,
		formData,
		responseFormData,
		isFormDirty,
		isFormSaved,
		isReady,
		setFormData,
		submitForm,
	};
}

export default useFormData;
