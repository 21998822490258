import { sendTwoFA } from "../../services/twofactor.service";
import UserActionTypes from "./user.type";

export const setCurrentUser = (user) => ({
	type: UserActionTypes.SET_CURRENT_USER,
	payload: user,
});

export const setCurrentUserUploadLimits = (companyUploadLimits) => ({
	type: UserActionTypes.SET_COMPANY_UPLOAD_LIMIT,
	payload: companyUploadLimits,
});

export const verify2FACode = (twoFaCodeToTest, navigate) => async (dispatch) => {
	try {
		const res = await sendTwoFA({ "2fa_code": twoFaCodeToTest });
		if (res.code === 200) {
			navigate("/national-insights");
			dispatch({
				type: UserActionTypes.VERIFY_2FA,
				payload: "2FA Successful",
				verified: true,
			});
		}
	} catch (error) {
		console.log("error", error);
	}
};
