// components/Chip/Chip.tsx

import "./Chip.style.scss";

import React from "react";

// import Close from "../Close";

/**
 * @typedef ChipProps
 * @property [dismissible] {boolean}
 * @property [theme] {string}
 * @property [fontColor] {string}
 * @property [backgroundColor] {string}
 * @property [size] {number}
 */

/** @type ChipProps */
const defaultProps = {
	dismissible: false,
};

/**
 * A Chip Component
 * @param props {ChipProps}
 * @return {JSX.Element}
 * @constructor
 */
const Chip = (props) => {
	props = { ...defaultProps, ...props };

	const getStyles = () => {
		let styles = {};

		if (props.backgroundColor) {
			styles["backgroundColor"] = props.backgroundColor;
		}

		if (props.fontColor) {
			styles["color"] = props.fontColor;
		}

		if (props.size) {
			styles["--chipSize"] = `${props.size}px`;
		}

		return styles;
	};

	return (
		<div className={`chip ${props.dismissible ? "dismissible" : ""}`} style={getStyles()} data-testid='Chip'>
			{props.children}
			{/*{props.dismissible && <Close size='sm' />}*/}
		</div>
	);
};

export default Chip;
