import "./App.css";

import * as Sentry from "@sentry/react";
import mapboxgl from "mapbox-gl";
import mixpanel from "mixpanel-browser";
import React, { Suspense, useCallback, useEffect } from "react";
import { connect } from "react-redux";
import { BrowserRouter, Navigate } from "react-router-dom";
import { createStructuredSelector } from "reselect";

import ToastNotification from "./components/toastNotification/ToastNOtification";
import CacheBuster from "./helper/cachebuster";
import { selectToken } from "./redux/token/token.selector";
import { setCurrentUser } from "./redux/user/user.action";
import { selectCurrentUser } from "./redux/user/user.selector";
import Router from "./route/router";
import { getUser } from "./services/authentication.service";

mixpanel.init(import.meta.env.VITE_REACT_APP_MIXPANEL_TOKEN);

mapboxgl.accessToken = import.meta.env.VITE_REACT_APP_MAPBOX_API_KEY
	? import.meta.env.VITE_REACT_APP_MAPBOX_API_KEY
	: "";

// Sentry
Sentry.init({
	dsn: "https://6769406fdac50080e61c28e3892f8286@o4508042659823616.ingest.us.sentry.io/4508042661199872",
	integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
	// Tracing
	tracesSampleRate: 1.0, //  Capture 100% of the transactions
	// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
	tracePropagationTargets: [/^https:\/\/analytics\.bluechipinsights\.com/],
	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

function App(props) {
	const loading = () => (
		<div className='animated fadeIn pt-3 text-center'>
			<div className='sk-spinner sk-spinner-pulse' />
		</div>
	);

	const getCurrentUser = useCallback(() => props.user, [props.user]);
	const getToken = useCallback(() => props.token, [props.token]);

	return (
		// <CacheBuster>
		//   {({ cacheloading, isLatestVersion, refreshCacheAndReload }) => {
		//     if (cacheloading) return null;
		//     if (!cacheloading && !isLatestVersion) {
		//       refreshCacheAndReload();
		//     }

		//     return (
		<BrowserRouter>
			<Suspense fallback={loading()}>
				<Router props={{ user: getCurrentUser(), token: getToken() }} />
				<ToastNotification />
			</Suspense>
		</BrowserRouter>
		// );
		// }}
		// </CacheBuster>
	);
}

const mapStateToProps = createStructuredSelector({
	token: selectToken,
	user: selectCurrentUser,
});

const mapDispatchToProps = (dispatch) => ({
	setcurrentuser: (user) => dispatch(setCurrentUser(user)),
});

const AppWithCacheBuster = connect(
	mapStateToProps,
	mapDispatchToProps,
)((props) => {
	const { setcurrentuser } = props;

	useEffect(() => {
		const getData = async () => {
			if (!!props.token && !props.user) {
				const res = await getUser();
				if (res?.data?.response_type === "success") {
					setcurrentuser(res.data.data);
				} else {
					return <Navigate to={"/login"} replace />;
				}
			}
		};
		getData();
	}, [props.token, props.user, setcurrentuser]);

	return (
		<CacheBuster>
			{({ cacheloading, isLatestVersion, refreshCacheAndReload }) => {
				useEffect(() => {
					if (!cacheloading && !isLatestVersion) {
						refreshCacheAndReload();
					}
				}, [cacheloading, isLatestVersion, refreshCacheAndReload]);

				if (cacheloading) return null;

				return <App {...props} />;
			}}
		</CacheBuster>
	);
});

export default AppWithCacheBuster;
