// InputRadioPill.tsx

import "./InputRadioPill.style.scss";

import React, { useState } from "react";

import { randomString } from "../../helper/string.helpers";

// export interface InputRadioPillProps {
//   id?: string;
//   label?: string;
//   disabled?: boolean;
//   invalid?: boolean;
//   groupName?: string;
//   value?: string | number;
//   selected?: string | number;
//   onValueChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
// }

const defaultProps = {
	label: "",
};

const InputRadioPill = (props) => {
	props = { ...defaultProps, ...props };

	const [id] = useState(props.id || randomString());

	return (
		<div className='input-radio-pill' data-testid='InputRadio'>
			<input
				type='radio'
				id={id}
				className={`${props.invalid ? "is-invalid" : ""} ${props.disabled ? "disabled" : ""}`}
				disabled={props.disabled}
				name={props.groupName}
				value={props.value}
				checked={props.value == props.selected}
				onChange={(e) => !props.disabled && props.onValueChange(e)}
			/>

			<label htmlFor={id}>
				{props.value == props.selected && props.includeActiveCheck && (
					<svg
						style={{ marginRight: "6px" }}
						xmlns='http://www.w3.org/2000/svg'
						width='13'
						height='10'
						viewBox='0 0 13 10'
						fill='none'
					>
						<path
							d='M12.8378 1.71004L11.4278 0.290039L4.83783 6.88004L2.25783 4.31004L0.83783 5.72004L4.83783 9.71004L12.8378 1.71004Z'
							fill='white'
						/>
					</svg>
				)}
				{props.label}
			</label>
		</div>
	);
};

export default InputRadioPill;
