import "./score-insights-layout.styles.scss";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, Outlet, useLocation, useParams } from "react-router-dom";

import Ai from "../../../components/Ai/Ai";
import Button from "../../../components/Button/Button";
import CreateCloneBusinessInsightsDashboard from "../../../components/CreateCloneBiDashboard/CreateCloneBusinessInsightsDashboard";
import Icon from "../../../components/Icon/Icon";
import Loader from "../../../components/loader/loader.component";
import CleanModal from "../../../components/modal/CleanModal";
import { convertMarkdownBoldToHtml } from "../../../helper/aiFormat.helpers";
import { trackEvent } from "../../../hooks/useMixpanel";
import { useSetModalOpen } from "../../../hooks/useSetModalOpen";
import { show } from "../../../redux/ai/aiSlice";
import { clearCurrentDashboard } from "../../../redux/score-insights/scoreInsightsActions";
// Ai
import { askQuestion } from "../../../services/chatGPT.services";
import BlockNav from "../components/BlockNav";
import useFetchCurrentDashboard from "../hooks/useFetchCurrentDashboard";
import useFetchInitialDashboardData from "../hooks/useFetchInitialDashboardData";
import FilterForm from "./filter-form";

function ScoreInsightsLayoutInner() {
	const { dashboardId } = useParams();
	const [sidebarOpen, setSidebarOpen] = useState(true);
	const [openBiModal, setOpenBiModal] = useState(false);
	const location = useLocation();
	const { pathname } = location;
	const collapseSidebar = React.lazy(() => import("../total-market-score/total-market-score"));

	useEffect(() => {
		if (window.location.pathname.includes("decision-matrix")) {
			setSidebarOpen(false);
			collapseSidebar.value = true;
		}
	}, []);

	useEffect(() => {
		setSidebarOpen(!collapseSidebar.value);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [collapseSidebar.value]);

	return (
		<div className={`score-insights-layout ${sidebarOpen ? "sidebar-open" : "sidebar-closed"}`}>
			<CreateCloneBusinessInsightsDashboard openBiModal={openBiModal} setOpenBiModal={setOpenBiModal} />

			<BlockNav />

			<div
				className={`score-insights-layout-body ${
					pathname.includes("decision-matrix") ? "decision-matrix" : null
				}`}
			>
				<Outlet />
			</div>

			<div className={"score-insights-form-column"}>
				<div className={"score-insights-form-column-inner"}>
					<FilterForm />
				</div>
			</div>

			<nav className={"score-insights-navigation-sidebar"}>
				<div className={"flex-grow-1"}></div>

				<div className={"score-insights-link-wrapper"}>
					<NavLink
						className={(state) => {
							return state.isActive ? "score-insights-nav-link active" : "score-insights-nav-link";
						}}
						to={`total-market-score/${dashboardId}`}
						onClick={() => {
							trackEvent("Click", {
								"Location": "Side Navigation",
								"Dashboard": "Score Insights",
								"Item": "Total Market Score",
							});
						}}
					>
						<div className={"flair"}></div>
						<Icon icon={"dashboard-line"} size={"lg"} />
						<div className={"score-insights-link-label"}>Total Market Score</div>
					</NavLink>
				</div>

				<div className={"score-insights-link-wrapper"}>
					<NavLink
						className={(state) => {
							return state.isActive ? "score-insights-nav-link active" : "score-insights-nav-link";
						}}
						to={`labor-supply/${dashboardId}`}
						onClick={() => {
							trackEvent("Click", {
								"Location": "Side Navigation",
								"Dashboard": "Score Insights",
								"Item": "Labor Supply",
							});
						}}
					>
						<div className={"flair"}></div>
						<Icon icon={"equalizer-line"} size={"lg"} />
						<div className={"score-insights-link-label"}>Labor Supply</div>
					</NavLink>
				</div>

				<div className={"score-insights-link-wrapper"}>
					<NavLink
						className={(state) => {
							return state.isActive ? "score-insights-nav-link active" : "score-insights-nav-link";
						}}
						to={`labor-cost/${dashboardId}`}
						onClick={() => {
							trackEvent("Click", {
								"Location": "Side Navigation",
								"Dashboard": "Score Insights",
								"Item": "Labor Cost",
							});
						}}
					>
						<div className={"flair"}></div>
						<Icon icon={"bank-card-2-line"} size={"lg"} />
						<div className={"score-insights-link-label"}>Labor Cost</div>
					</NavLink>
				</div>

				<div className={"score-insights-link-wrapper"}>
					<NavLink
						className={(state) => {
							return state.isActive ? "score-insights-nav-link active" : "score-insights-nav-link";
						}}
						to={`labor-migration/${dashboardId}`}
						onClick={() => {
							trackEvent("Click", {
								"Location": "Side Navigation",
								"Dashboard": "Score Insights",
								"Item": "Labor Migration",
							});
						}}
					>
						<div className={"flair"}></div>
						<Icon icon={"earth-line"} size={"lg"} />
						<div className={"score-insights-link-label"}>Labor Migration</div>
					</NavLink>
				</div>

				<div className={"score-insights-link-wrapper"}>
					<NavLink
						className={(state) => {
							return state.isActive ? "score-insights-nav-link active" : "score-insights-nav-link";
						}}
						to={`labor-sustainability/${dashboardId}`}
						onClick={() => {
							trackEvent("Click", {
								"Location": "Side Navigation",
								"Dashboard": "Score Insights",
								"Item": "Labor Sustainability",
							});
						}}
					>
						<div className={"flair"}></div>
						<Icon icon={"earthquake-fill"} size={"lg"} />
						<div className={"score-insights-link-label"}>Labor Sustainability</div>
					</NavLink>
				</div>

				<div className={"flex-grow-1"}></div>

				{/* <div className={"score-insights-link-wrapper"}>
					<Button
						theme={"link"}
						size={"lg"}
						className={"score-insights-nav-link"}
						roll={"toggle"}
						onClick={() => setOpenBiModal(true)}
					>
						<Icon icon={"function-add-line"} size={"lg"} />
						<div className={"score-insights-link-label"}>
							Create Business Insights Dashboard from this dashboard
						</div>
					</Button>
				</div> */}
				<div className={"score-insights-link-wrapper"}>
					<Button
						theme={"link"}
						size={"lg"}
						className={"score-insights-nav-link"}
						roll={"toggle"}
						onClick={() => setSidebarOpen(!sidebarOpen)}
					>
						<Icon icon={sidebarOpen ? "menu-fold-fill" : "menu-unfold-fill"} size={"lg"} />
						<div className={"score-insights-link-label"}>
							{sidebarOpen ? "Close Filters" : "Open Filters"}
						</div>
					</Button>
				</div>
			</nav>
		</div>
	);
}

/**
 * Wrapping component triggers the initial fetch for the dashboard and the score data.
 * It also shows the loader component when the form is submitted.
 * @returns {JSX.Element}
 * @constructor
 */
function ScoreInsightsLayout() {
	const dispatch = useDispatch();
	const { dashboardId } = useParams();
	const {
		currentDashboard, // basic dashboard info like name, address, etc.
		currentDashboardLoadingState,
		dashboardData, // dashboard score data per form selections
		dashboardDataLoadingState,
	} = useSelector((state) => state.scoreInsightsDashboard);

	useFetchCurrentDashboard(dashboardId);
	useFetchInitialDashboardData(dashboardId);

	const open = useSelector((state) => state.ai.open);
	const [aiPrompt, setAiPrompt] = useState("");
	const [aiLoading, setAiLoading] = useState(false);

	useEffect(() => {
		return () => {
			// If we don't clear the current dashboard, the next time the user navigates to the list to
			// select a different dashboard, they'll see the previous dashboard's data till the new one loads.
			dispatch(clearCurrentDashboard());
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const isLoading = currentDashboardLoadingState === "loading" || dashboardDataLoadingState === "loading";
	const dashboardOk = currentDashboard?._id === dashboardId;
	const dashboardDataOk = dashboardData?.dashboardId === dashboardId && currentDashboard?._id === dashboardId;
	const { publicSettings } = useSelector((state) => state.ai);

	useEffect(() => {
		dispatch(show(true));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (
			publicSettings?.showIcon &&
			publicSettings?.enable_ai === "1" &&
			publicSettings?.score_insights === "1" &&
			!isLoading &&
			dashboardOk
		) {
			const filterArrayForChatGPT = dashboardData.locations.map((item) => {
				return Object.entries(item).reduce((acc, [key, value]) => {
					if (key.endsWith("_weightedScore") || key === "address") {
						acc[key] = value;
					}
					return acc;
				}, {});
			});
			setAiLoading(true);
			askQuestion(filterArrayForChatGPT)
				.then((response) => {
					const convertedText = convertMarkdownBoldToHtml(response.response.choices[0].message.content);
					// setTrigger(true)
					// dispatch(update(true))
					setAiPrompt(convertedText);
					setAiLoading(false);
				})
				.catch(() => {});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoading]);

	const setModalOpen = useSetModalOpen();

	// don't render the page outlet until the `currentDashboard` is ready
	return (
		<>
			{isLoading && <Loader />}

			<div className={`ai-summary ${open ? "open" : "closed"}`}>
				<CleanModal
					key={"BlueChip Ai"}
					title={`BlueChip Score Insights Dashboard Summary`}
					trigger={open}
					setTrigger={setModalOpen}
				>
					<Ai loading={aiLoading}>{aiPrompt}</Ai>
				</CleanModal>
			</div>

			{dashboardOk && <ScoreInsightsLayoutInner>{dashboardDataOk && <Outlet />}</ScoreInsightsLayoutInner>}
		</>
	);
}

export default ScoreInsightsLayout;
