import MicroSiteScoreTypes from "./micro-site-score.type";

const INITIAL_STATE = {
	// Labor-Supply
	laborWeight: 60,
	segmentationWeight: 40,

	// Labor competition
	laborShiftWeighting: 50,

	// population Coverage
	locationData: [],
	location: { name: "4150 Patterson Ave, Perris, CA 92571" },
	locationId: 1,

	// labor cost
	percentileWage: "avg_hourly_earnings",
	percentileWageName: "Avg. Hourly Earnings",
	costWeighting: 100,
	optionPercentileWage: [],

	//
	scoreOption: [],
	selectedItemForScore: { name: "Atlanta" },

	// coverage-zone-demographics
	optionForDemographics: [],
	selectedForDemographics: { name: "Chicago, IL" },

	// coverage-zone-segmentation
	optionForSegmentation: [],
	selectedForSegmentation: { name: "4150 Patterson Ave, Perris, CA 92571" },

	// Labor-SCORE
	occupationForLaborScore: {
		name: "Warehouse Occupations",
	},
	occupationOptionForLaborScore: [],
	driveTimeForLaborScore: { name: "20" },
	driveTimeOptionForLaborScore: [],
	marketForLaborScore: { name: "Atlanta" },
	marketOptionForLaborScore: [],
	laborSupplyForLaborScore: 25,
	laborCostForLaborScore: 25,
	laborSustainabilityForLaborScore: 25,
	laborCompetitionForLaborScore: 25,
};

const MicroSiteScoreReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case MicroSiteScoreTypes.SET_LABOR_WEIGHT:
			return { ...state, laborWeight: action.payload };

		case MicroSiteScoreTypes.SET_SEGMENTATION_WEIGHT:
			return { ...state, segmentationWeight: action.payload };

		case MicroSiteScoreTypes.SET_LABOR_SHIFT_WEIGHTING:
			return { ...state, laborShiftWeighting: action.payload };

		case MicroSiteScoreTypes.SET_LOCATION_DATA:
			return { ...state, locationData: action.payload };

		case MicroSiteScoreTypes.SET_LOCATION_ID:
			return { ...state, locationId: action.payload };

		case MicroSiteScoreTypes.SET_LOCATION:
			return { ...state, location: action.payload };

		case MicroSiteScoreTypes.SET_PERCENTILEWAGE:
			return { ...state, percentileWage: action.payload };

		case MicroSiteScoreTypes.SET_PERCENTILEWAGENAME:
			return { ...state, percentileWageName: action.payload };

		case MicroSiteScoreTypes.SET_COSTWEIGHTING:
			return { ...state, costWeighting: action.payload };

		case MicroSiteScoreTypes.SET_OPTION_PERCENTILEWAGE:
			return { ...state, optionPercentileWage: action.payload };

		case MicroSiteScoreTypes.SET_SCORE_OPTION:
			return { ...state, scoreOption: action.payload };

		case MicroSiteScoreTypes.SET_SELECTED_ITEM_FOR_SCORE:
			return { ...state, selectedItemForScore: action.payload };

		case MicroSiteScoreTypes.SET_OPTION_FOR_DEMOGRAPHICS:
			return { ...state, optionForDemographics: action.payload };

		case MicroSiteScoreTypes.SET_SELECTED_ITEM_FOR_DEMOGRAPHICS:
			return { ...state, selectedForDemographics: action.payload };

		case MicroSiteScoreTypes.SET_OPTION_FOR_SEGMENTATION:
			return { ...state, optionForSegmentation: action.payload };

		case MicroSiteScoreTypes.SET_SELECTED_ITEM_FOR_SEGMENTATION:
			return { ...state, selectedForSegmentation: action.payload };

		case MicroSiteScoreTypes.SET_OCCUPATION_FOR_LABORSCORE:
			return { ...state, occupationForLaborScore: action.payload };

		case MicroSiteScoreTypes.SET_OCCUPATION_OPTION_FOR_LABORSCORE:
			return { ...state, occupationOptionForLaborScore: action.payload };

		case MicroSiteScoreTypes.SET_DRIVETIME_FOR_LABORSCORE:
			return { ...state, driveTimeForLaborScore: action.payload };

		case MicroSiteScoreTypes.SET_DRIVETIME_OPTION_FOR_LABORSCORE:
			return { ...state, driveTimeOptionForLaborScore: action.payload };

		case MicroSiteScoreTypes.SET_MARKET_FOR_LABORSCORE:
			return { ...state, marketForLaborScore: action.payload };

		case MicroSiteScoreTypes.SET_MARKET_OPTION_FOR_LABORSCORE:
			return { ...state, marketOptionForLaborScore: action.payload };

		case MicroSiteScoreTypes.SET_LABORSUPPLY_FOR_LABORSCORE:
			return { ...state, laborSupplyForLaborScore: action.payload };

		case MicroSiteScoreTypes.SET_LABORCOST_FOR_LABORSCORE:
			return { ...state, laborCostForLaborScore: action.payload };

		case MicroSiteScoreTypes.SET_LABORSUSTAINABILITY_FOR_LABORSCORE:
			return { ...state, laborSustainabilityForLaborScore: action.payload };

		case MicroSiteScoreTypes.SET_LABORCOMPETITION_FOR_LABORSCORE:
			return { ...state, laborCompetitionForLaborScore: action.payload };

		default:
			return state;
	}
};

export default MicroSiteScoreReducer;
