import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	data: false,
};

export const ciSlice = createSlice({
	name: "ci",
	initialState,
	reducers: {
		updateCiData: (state, action) => {
			state.data = action.payload;
		},
	},
});

export const { updateCiData } = ciSlice.actions;

export default ciSlice.reducer;
