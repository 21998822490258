// helper/coordinate.helpers.js

/**
 * Validates that the input is a valid Latitude or Longitude value
 * @param {{ lat: number, lon: number }} coordinates - lat and lon values to validate
 * @returns {boolean} - if the input is a valid Latitude and Longitude value
 */
export function isValidLatLon(coordinates) {
	if (!coordinates || !coordinates.lat || !coordinates.lon) {
		// coordinates is undefined
		return false;
	}

	if (typeof coordinates.lat !== "number" || typeof coordinates.lon !== "number") {
		// lat and/or lon are not a number
		return false;
	}

	if (coordinates.lat < -90 || coordinates.lat > 90) {
		// Value is outside the range of valid latitudes
		return false;
	}

	if (coordinates.lon < -180 || coordinates.lon > 180) {
		// Value is outside the range of valid longitudes
		return false;
	}

	// coordinates has both a valid latitude and longitude
	return true;
}

/**
 * Converts a coordinate array [-96.83012, 33.10976] to a coordinate object { lat: 32.64672, lon: -96.91798 }
 * @param {[number, number]} coordinateArray - coordinates in array format
 * @returns {{ lat: number, lon: number }} - coordinates as an object
 */
export function coordinateArrayToObject(coordinateArray) {
	return { lat: coordinateArray[1], lon: coordinateArray[0] };
}

/**
 * Flattens an array of coordinates to one level.
 * @param {Array} arr - The input array of coordinates.
 * @returns {Array} - The flattened array of coordinates.
 */
export function flattenCoordinates(arr) {
	let result = [];
	for (let i = 0; i < arr.length; i++) {
		// If the current element is an array containing other arrays
		if (Array.isArray(arr[i]) && Array.isArray(arr[i][0])) {
			// Recursively call flattenCoordinates() on the current element and concatenate the result to the result array
			result = result.concat(flattenCoordinates(arr[i]));
		} else {
			// Otherwise, push the current element to the result array
			result.push(arr[i]);
		}
	}
	return result;
}
