// components/control-component/new-select-box.component.jsx

import React, { useEffect, useRef, useState } from "react";

import { slugify } from "../../helper/string.helpers";

function SelectBox(props) {
	const [toggle, setToggle] = useState(false);

	const toggleRef = useRef();
	toggleRef.current = toggle;

	const searchRef = useRef();

	useEffect(() => {
		if (props.selectItem) {
			document.getElementsByClassName("select-trigger-wrap")[0].classList.add("hasFocus");
		}
	}, [props.selectItem]);

	useEffect(() => {
		document.body.addEventListener(
			"click",
			(e) => {
				if (
					!e.target.classList.contains("select-item") &&
					!e.target.classList.contains("select-search-input")
				) {
					const classData = document.getElementsByClassName("select-items-wrapper");
					if (classData.length > 0) {
						for (const item of classData) {
							if (item.classList.contains("show")) {
								setToggle(false);
							}
						}
					}
				}
				// input-item
			},
			true,
		);
	}, []);

	const dropdown = (e) => {
		setToggle(!toggle);
	};

	useEffect(() => {
		if (toggle) {
			searchRef.current && searchRef.current.focus();
		}
	}, [toggle]);

	const selectClass = (value) => {
		setToggle(false);
		props.setSelectedItem(value);
		props.setLivingWageInputValue &&
			props.setLivingWageInputValue({
				hourlyWage: 0.0,
				bluechipLivingWage: 0.0,
			});
	};

	const filterFunction = (e) => {
		let filter;
		let li;
		let i;
		filter = e.target.value.toUpperCase();
		const div = document.getElementById(`dropdown-${props?.id ? props?.id : "occupation"}`);
		li = div.getElementsByTagName("li");
		for (i = 0; i < li.length; i++) {
			const txtValue = li[i].textContent || li[i].innerText;
			if (txtValue.toUpperCase().indexOf(filter) > -1) {
				li[i].style.display = "";
			} else {
				li[i].style.display = "none";
			}
		}
	};

	// select-trigger-wrap hasFocus
	return (
		<div className='input-item' key={Math.random()}>
			<div
				className={`${props.selectIcon ? "hasSelectIcon" : ""} custom-select ${toggle ? "show" : ""}`}
				title={props.selectItem ? props.selectItem : null}
			>
				<div
					className={`select-trigger-wrap ${toggle || props.selectItem ? "hasFocus" : ""}`}
					style={{ border: props.isHighlighted ? "1px solid red" : "" }}
					onClick={(e) => dropdown(e)}
				>
					{props.selectIcon && <span className='select-icon'>{props.selectIcon}</span>}
					<span className='select-trigger'>
						{/* {props.selectItem && props.optionsIcon && props.optionsIcon.hasOwnProperty(`${props.selectItem}`)? props.optionsIcon[`${props.selectItem}`] : null} */}
						{props.selectItem ? props.selectItem : null}
					</span>
					<svg width='14' height='8' viewBox='0 0 14 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
						<path
							d='M13 7L7 0.999999L1 7'
							stroke={props.isHighlighted ? "#FF0000" : "#175086"}
							strokeWidth='2'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
					</svg>
				</div>
				<label className='' style={{ color: props.isHighlighted ? "#FF0000" : "" }}>
					{props.lableName}
				</label>
				<div
					className={`select-items-wrapper ${toggle ? "show" : ""}`}
					id={`dropdown-${props?.id ? props?.id : "occupation"}`}
				>
					{props.searchable && (
						<div className='select-search-input-wrap'>
							<input
								ref={searchRef}
								type='text'
								id={`search-${slugify(props.lableName)}`}
								className='select-search-input'
								onKeyUp={(e) => filterFunction(e)}
								placeholder='Search'
								onChange={() => {}}
							/>
						</div>
					)}
					<ul className='select-items-wrap'>
						{props.options &&
							props.options.map((item, index) => (
								<li
									id={
										item[props.liId]
											? `${props.selectIdName}-${item[props.liId]}`
											: `${props.id}-${index}`
									}
									className={`select-item ${props.selectItem === item[props.name] ? "active" : ""}`}
									key={index}
									onClick={(e) => selectClass(item)}
								>
									{/* {props.optionsIcon && props.optionsIcon[`${item}`]} */}
									{item[props.name]}
								</li>
							))}
					</ul>
				</div>
			</div>
		</div>
	);
}

export default SelectBox;
