// components/ProgressBar/ProgressBar.tsx

import "./ProgressBar.style.scss";

import React, { useEffect, useState } from "react";

import {
	BLUE_CYAN_GREEN_GRAD,
	GREEN_YELLOW_RED_GRAD,
	RED_YELLOW_GREEN_GRAD,
	YELLOW_ORANGE_RED_GRAD,
} from "../../constants/Colors.const";
import { getGradientColor } from "../../helper/color.helpers";

/**
 * @typedef {Object} ProgressBarProps
 * @property average {string | number | null}
 * @property showPercent {boolean}
 * @property size {string}
 * @property progressColor {string}
 * @property progress {number}
 * @property progressPercent {boolean}
 * @property theme {"default" | "blue-green-grad"}
 */

/** @type ProgressBarProps */
const defaultProps = {
	progressText: 0,
	progress: 0,
	showPercent: false,
	decorator: "",
	size: "md",
	progressColor: null,
	progressPercent: false,
	average: null,
	theme: "default",
	laborSustainability: "",
	displayMetric: null,
	displayMetricValue: 0,
};

/**
 * A Progress Bar Component
 * @param {ProgressBarProps} props
 * @return {JSX.Element}
 * @constructor
 */
const ProgressBar = (props) => {
	props = { ...defaultProps, ...props };

	const getJustify = () => {
		if (props.showPercent && props.label) {
			return "space-between";
		}
	};

	const [styles, setStyles] = useState({ width: 0 });
	const { colors, setCircleColors } = props;
	const getProgressStyles = () => {
		const newStyles = {
			width: `${props.progress || 0}%`,
			backgroundColor: props.progressColor,
			...(props.laborSustainability && {
				position: "relative",
				left: `${(props.progress / 2) * (props.displayMetric === "positive" ? 1 : -1) || 0}%`,
			}),
		};

		if (props.progress && props.theme === "blue-green-grad") {
			newStyles.backgroundColor = getGradientColor(props.progress, BLUE_CYAN_GREEN_GRAD);
		}

		if (props.progress && props.theme === "yellow-red-grad") {
			newStyles.backgroundColor = getGradientColor(props.progress, YELLOW_ORANGE_RED_GRAD);
		}

		if (props.progress && props.theme === "red-green-grad") {
			newStyles.backgroundColor = getGradientColor(props.progress, RED_YELLOW_GREEN_GRAD);
		}

		if (props.progress && props.theme === "green-red-grad") {
			newStyles.backgroundColor = getGradientColor(props.progress, GREEN_YELLOW_RED_GRAD);
		}

		setStyles(newStyles);
		setCircleColors?.((prevColors) => [...prevColors, newStyles]);
	};

	useEffect(() => {
		getProgressStyles();
	}, [props.progress]);

	return (
		<div
			data-displaymetric={props.displayMetric}
			data-displaymetricvalue={props.displayMetricValue}
			className={`progress-bar ${props.size} theme-${props.theme} ${props.laborSustainability}`}
			data-testid='ProgressBar'
		>
			<div className={`progress-info ${getJustify()}`}>
				{props.label && <span className='label'>{props.label}</span>}
				{props.showPercent && (
					<span className='percent'>
						{props.progressText || props.progress}
						{props.decorator}
					</span>
				)}
			</div>
			<div className={`bar ${props.laborSustainability && "d-flex flex-row-center"}`} data-testid='ProgressBar'>
				{/* <div
          style={{
            position: "absolute",
            margin: "auto",
            left: "0",
            right: "0",
            height: "10px",
            width: "10px",
            background: "pink",
            zIndex: "99999999",
          }}
        ></div> */}
				<div
					className={`progress ${props.progress === 100 ? "success" : ""} ${props.displayMetric}`}
					style={styles}
				></div>
			</div>
			{props.average && (
				<div className={"mean"}>
					<div className={"mean-marker"}></div>
					<div className='mean-pink-marker'></div>
					<div className={"mean-label"}>
						AVG. <span className='pink'>{props.average}</span>
					</div>
				</div>
			)}
		</div>
	);
};

export default ProgressBar;
