// helpers/number.helpers.js

/** compares version numbers with more than one decimal */
export function compareVersionNumbers(version1, version2) {
	const version1Parts = version1.split(".").map(Number);
	const version2Parts = version2.split(".").map(Number);

	for (let i = 0; i < version1Parts.length || i < version2Parts.length; i++) {
		const part1 = version1Parts[i] || 0;
		const part2 = version2Parts[i] || 0;

		if (part1 < part2) {
			return false;
		}
		if (part1 > part2) {
			return true;
		}
	}

	// version numbers are the same
	return true;
}

/**
 * Adds commas to a number for better readability.
 * @param {number} number - The number to format.
 * @returns {string} The formatted number with commas.
 */
export function addCommas(number) {
	// Convert the number to a string
	let str = number.toString();

	// Split the string into an array of characters
	let arr = str.split("");

	// Reverse the array of characters
	arr.reverse();

	// Create a new array to hold the formatted number
	let result = [];

	// Loop through the reversed array of characters, adding commas every three digits
	for (let i = 0; i < arr.length; i++) {
		if (arr[i] !== "-" && i > 0 && i % 3 === 0) {
			result.push(",");
		}
		result.push(arr[i]);
	}

	// Reverse the result array and join it back into a string
	return result.reverse().join("");
}

/**
 * Converts miles to meters.
 * @param {number} miles - The distance in miles.
 * @returns {number} - The distance in meters.
 */
export function convertMilesToMeters(miles) {
	const metersInMile = 1609.344; // 1 mile = 1609.344 meters
	return miles * metersInMile;
}

/**
 * Converts meters to miles.
 * @param {number} meters - The distance in meters.
 * @returns {number} - The distance in miles.
 */
export function convertMetersToMiles(meters) {
	const milesInMeter = 0.000621371; // 1 meter = 0.000621371 miles
	return meters * milesInMeter;
}

/** Formats a number as US currency */
export function formatMoney(amount) {
	if (typeof amount !== "number") {
		try {
			amount = Number(amount);
		} catch {
			return null;
		}
	}

	// eslint-disable-next-line new-cap
	const dollarUS = Intl.NumberFormat("en-US", {
		style: "currency",
		currency: "USD",
	});

	return dollarUS.format(amount);
}

/**
 * Returns a random integer between the provided minimum and maximum values, inclusive.
 * @param {number} min - The minimum value to return.
 * @param {number} max - The maximum value to return.
 * @returns {number} A random integer between the minimum and maximum values.
 */
export function getRandomNumber(min, max) {
	return Math.floor(Math.random() * (max - min + 1)) + min;
}

/**
 * Calculates the ratio for a given score within a range.
 * @param {number} minValue - The lower value of the range.
 * @param {number} maxValue - The upper value of the range.
 * @param {number} score - The score for which to calculate the ratio.
 * @returns {number} The calculated ratio.
 */
export function calculateRatio(score, minValue, maxValue) {
	const ratio = ((score - minValue) / (maxValue - minValue)) * 100;
	return ratio;
}

/**
 * Returns true if the value is numeric. (string, number, etc.)
 * @param n
 * @returns {boolean}
 */
export function isNumeric(n) {
	return !isNaN(parseFloat(n)) && isFinite(n);
}
