import { axiosGet, axiosPost } from "../axios/config";

const backendAPI = import.meta.env.VITE_REACT_APP_API_URL;

export const login = (data) => {
	return axiosPost(backendAPI + "/api/v1/login", data);
};

export const ResetPasswordMail = (data) => {
	return axiosPost(backendAPI + "/api/v1/reset-password-mail", data);
};

export const confirmResetPassword = (data) => {
	return axiosPost(backendAPI + "/api/v1/reset-password", data);
};

/** Returns the current authorized user */
export const getUser = () => {
	return axiosGet(backendAPI + "/api/v1/user");
};
export const getadmin = (data) => {
	return axiosPost(backendAPI + "/api/v1/get-super-admins", data);
};
export const getadminLogo = () => {
	return axiosGet(backendAPI + "/api/v1/get-admin-brandlogo");
};
export const sendIvite = (data) => {
	return axiosPost(backendAPI + "/api/v1/send-invite", data);
};
export const verifyLink = (data) => {
	return axiosPost(backendAPI + "/api/v1/verify-token", data);
};
export const addSignupUser = (data) => {
	return axiosPost(backendAPI + "/api/v1/add-invited-member", data);
};
export const getInvitedUser = (data) => {
	return axiosPost(backendAPI + "/api/v1/get-all-invitations", data);
};
export const getCompanies = (data) => {
	return axiosPost(backendAPI + "/api/v1/companies", data);
};
export const getOrganizations = (data) => {
	return axiosPost(backendAPI + "/api/v2/administration/organizations", data);
};
