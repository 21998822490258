// services/travel-time.service.tsx

import { axiosGet } from "../axios/config";
import { TRAVELTIME_MINUTE_DEFAULTS } from "../constants/travel-time.const";
import { isValidLatLon } from "../helper/coordinate.helpers";

const TravelTimeService = {
	url: "/api/v2/travel-time/time-map",
	resource: "travel-time",

	/**
	 * Performs a GET request to fetch travelTime info for a specific location.
	 * @async
	 * @param {{ lat: number, lon: number }} coordinates - Coordinates to fetch travelTime for.
	 * @param {{ nonce?: boolean, driving?, public_transport?, walking? }} options - Set TRUE to clear cache / force a refresh.
	 * @returns {Promise} - A promise that resolves to the response data.
	 * @throws {Error} - If the API call fails.
	 */
	get: async function (coordinates, options = TRAVELTIME_MINUTE_DEFAULTS) {
		try {
			if (!isValidLatLon(coordinates)) {
				return;
				// throw new Error(`TravelTimeService.get: Invalid lat and lon values.`);
			}

			const { lat, lon } = coordinates;

			let url = `${TravelTimeService.url}?lat=${lat}&lng=${lon}`;

			const response = await axiosGet(url, options);

			if (response.status < 200 || response.status >= 300) {
				throw new Error(`TravelTimeService.get: ${response.status} ${response.statusText}`);
			}

			return response;
		} catch (error) {
			console.error(error);
			throw error;
		}
	},
};

export default TravelTimeService;
