import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { fetchCurrentDashboard } from "../../../redux/score-insights/scoreInsightsActions";

/**
 * Fetches the dashboard for the id in the url and sets it in redux state.
 * @returns {{currentDashboard: *, currentDashboardLoadingState: *}}
 */
const useFetchCurrentDashboard = (dashboardId) => {
	const dispatch = useDispatch();

	useEffect(() => {
		const controller = new AbortController();
		dispatch(fetchCurrentDashboard(dashboardId, controller));
		// abort the fetch on unload
		return () => controller.abort();
	}, [dashboardId, dispatch]);
};

export default useFetchCurrentDashboard;
