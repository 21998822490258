import React from "react";
import { Layer, Source } from "react-map-gl";

const TravelTimeShape = ({ shape }) => {
	return (
		<Source id={`travel-time-${shape.transportation_type}-${shape.minutes}`} type='geojson' data={shape.geometry}>
			<Layer
				type={"fill"}
				paint={{ "fill-color": "#d9585f", "fill-opacity": 0.3, "fill-outline-color": "#331418" }}
			/>
			<Layer type={"line"} paint={{ "line-color": "#40191e", "line-width": 1 }} />
		</Source>
	);
};

export default TravelTimeShape;
